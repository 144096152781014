import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-error-interceptor',
  templateUrl: './error-interceptor.component.html',
  styleUrls: ['./error-interceptor.component.scss']
})
export class ErrorInterceptorComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: { errorType: string; errorMessage: string },
    private readonly translate: TranslateService
  ) { }

  ngOnInit(): void {
  }

}

