import { createAction, props } from '@ngrx/store';
import { VirtualGroup, VirtualGroupDetail } from '../models/virtualGroup.model';

/** Unhandled action */
export const authRequired = createAction('[App/Auth] Auth Required');
/** Unhandled action */

export const loadAllGroups = createAction(
    '[Group List Resolver] Load All Groups'
);

export const loadAllGroupsSuccess = createAction(
    '[Load Groups Effect] All Groups Loaded',
    props<{ virtualGroups: VirtualGroupDetail[] }>()
);

export const createVirtualGroup = createAction(
    '[Create Virtual Group] Group Created',
    props<{ virtualGroupCreate: VirtualGroup }>()
);

export const createVirtualGroupSuccess = createAction(
    '[Virtual Group Create Effect] Create Virtual Group then load the group',
    props<{ virtualGroup: VirtualGroupDetail }>()
);

export const createVirtualGroupFailed = createAction(
    '[Virtual Group Create Failed Effect] Create Virtual Group failed'
);

export const selectedGroup = createAction(
    '[Virtual Group] Selected Virtual Group', props<{ virtualGroup: VirtualGroupDetail }>()
);

export const setIsCreateGroup = createAction(
    '[Virtual Group] Setting isCreateGroup', props<{ createGroup: boolean }>()
);
