/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { changeUserStatus, loadAllUsers, resetUserPassword, selectedUser, setIsCreateUser } from '../../management/store/users.actions';
import { AppState } from '../../quotes/store/reducers';
import { predicates } from '../predicates';
import * as selector from '../store/users.selectors';
import { BehaviorSubject, combineLatest, distinctUntilChanged, filter, fromEvent, map, ReplaySubject, startWith, takeUntil } from 'rxjs';
import { skip, tap } from 'rxjs/operators';
import { User } from '../models/user.model';
import dayjs, { Dayjs } from 'dayjs';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { DrawerService } from '../../core/services/drawer.service';
import { sideNavToggle } from '../../core/store/core.actions';
import { MatMenuTrigger } from '@angular/material/menu';
import { NavigationService } from '@autoflow/angular-ux-components';
import { EDrawContent } from '../../core/components/lay-out/lay-out.component';
@Component({
  selector: 'app-management-dashboard',
  templateUrl: './management-dashboard.component.html',
  styleUrls: ['./management-dashboard.component.scss'],
})

export class ManagementDashboardComponent implements OnInit, OnDestroy {
   @ViewChild(MatMenuTrigger) profileMenuTrigger?: MatMenuTrigger; // will need to make this more specific if more mat menus added
  hasScrolled$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  routerLink = '';
  title = '';
  enabledUsers: User[] = [];
  disabledUsers: User[] = [];
  awaitingUsers: User[] = [];
  private enabledTable: User[] = [];
  private disabledTable: User[] = [];
  private awaitingTable: User[] = [];
  private userMenuOpen = false;
  private onDestroy$: ReplaySubject<boolean> = new ReplaySubject(1);
  siteMenu: string[] = [];
  selectedSite: string;
  searchInput: string;

  constructor(
    private store: Store<AppState>,
    public dialog: MatDialog,
    private drawerService: DrawerService,
    private libNavigationService: NavigationService,
  ) {
    this.selectedSite = '';
    this.searchInput = '';
  }

  ngOnInit(): void {
    this.title = '/ Management';
    this.routerLink = '/quotes/new';
    this.store.dispatch(loadAllUsers());
    this.store.select(selector.selectAllUsers).pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(users => {
      this.getUsersList();
      this.getSiteMenu();
    });
    const content = document.getElementsByClassName('mat-drawer-content')[0];
    fromEvent<any>(content, 'scroll').pipe(
      map(() => content.scrollTop > 0),
      distinctUntilChanged(),
      startWith(content.scrollTop > 0),
      takeUntil(this.onDestroy$)
    ).subscribe(this.hasScrolled$);

    combineLatest([
      this.store.pipe(
        select(selector.selectIsCreateUser),
        map(val => !(val === false))
      ),
      this.libNavigationService.toggleDrawer$
    ]).pipe(
      filter(([creatingUser, drawerOpen]) => creatingUser && !drawerOpen),
      skip(1), // for some reason drawerOpen is false when FIRST opening
      takeUntil(this.onDestroy$)
    ).subscribe(() => {
      // this.store.dispatch(sideNavToggle({ isSideNavOpen: false }));
      this.store.dispatch(selectedUser({ user: null }));
      // this.store.dispatch(setIsCreateUser({ createUser: false }));
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  private getUsersList(): void {
    this.store.pipe(select(selector.selectAllUsers)).subscribe(users => {
      if (users?.length > 0) {
        this.enabledTable = [];
        this.disabledTable = [];
        this.awaitingTable = [];
        users.forEach((user: User) => {
          user.fullName = user?.firstName + ' ' + user?.lastName;
          user.roles = user.roles.filter(role => role.keep);
          if (predicates.isEnabledUser(user)) {
            user.status = 'Active';
            this.enabledTable.push(user);
            this.enabledUsers = this.enabledTable;
          }
          else if (predicates.isDisabledUser(user)) {
            user.status = 'Disabled';
            this.disabledTable.push(user);
            this.disabledUsers = this.disabledTable;
          }
          else if (predicates.isAwaitingUser(user)) {
            user.status = 'Pending';
            this.awaitingTable.push(user);
            this.awaitingUsers = this.awaitingTable;
          }
        });
      };

    });

  }

  private resetSearch(): void {
    this.enabledUsers = this.enabledTable;
    this.awaitingUsers = this.awaitingTable;
    this.disabledUsers = this.disabledTable;
  }

  resetPassword(user: User): void {
    this.store.dispatch(resetUserPassword({ userId: user.id }));
  }

  formatDate(date: Dayjs): string {
    return dayjs(date).format('DD MMM YYYY');
  }

  toggleAdminSidebar(event: MouseEvent, user): void {
    this.store.dispatch(sideNavToggle({ isSideNavOpen: true }));
    this.store.dispatch(setIsCreateUser({ createUser: false }));
    this.store.dispatch(selectedUser({ user }));
    this.userMenuOpen = !this.userMenuOpen;
    event.stopPropagation();
    if(this.profileMenuTrigger) {
      this.profileMenuTrigger.closeMenu();
    }
    this.drawerService.toggleProfile({
      open: true,
      content: EDrawContent.USER_MANAGEMENT,
      hasNav: false,
    });
  };

  changeUserStatus(user): void {
    this.selectedSite = '';
    this.searchInput = '';
    if (user.enabled) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '550px',
        maxWidth: '1024px'
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result.data === 'ok') {
          this.store.dispatch(changeUserStatus({ userId: user.id }));
        }
      });
    } else {
      this.store.dispatch(changeUserStatus({ userId: user.id }));
    }
  }

  createUser(event: MouseEvent) {
    event.stopPropagation();
    this.drawerService.toggleProfile({
      open: true,
      content: EDrawContent.USER_MANAGEMENT,
      hasNav: false,
    });
    this.store.dispatch(setIsCreateUser({ createUser: true }));
  }

  onSiteChange(site: string): void {
    this.resetSearch();
    if (site === 'All' && this.searchInput.length === 0) {
      return;
    }
    else if (site === 'All' && this.searchInput.length > 0) {
      this.searchUserTool(this.searchInput);
    }
    else if (site !== 'All' && site.length > 0 && this.searchInput.length === 0) {
      this.filterSiteData(site);
    }
    else if (site !== 'All' && site.length > 0 && this.searchInput.length > 0) {
      const filterEnabled = this.enabledUsers.filter((user: User) => this.filterSites(user, site));
      const filterAwaiting = this.awaitingUsers.filter((user: User) => this.filterSites(user, site));
      const filterDisabled = this.disabledUsers.filter((user: User) => this.filterSites(user, site));
      const searchEnabledName = filterEnabled.filter((user: User) => this.filterNameEmail(user, this.searchInput));
      const searchAwaitingName = filterAwaiting.filter((user: User) => this.filterNameEmail(user, this.searchInput));
      const searchDisabledName = filterDisabled.filter((user: User) => this.filterNameEmail(user, this.searchInput));
      this.enabledUsers = searchEnabledName;
      this.awaitingUsers = searchAwaitingName;
      this.disabledUsers = searchDisabledName;
    }
    else {
      this.filterSiteData(site);
    }
  }

  filterSiteData(site: string) {
    const filterEnabled = this.enabledUsers.filter((user: User) => this.filterSites(user, site));
    const filterAwaiting = this.awaitingUsers.filter((user: User) => this.filterSites(user, site));
    const filterDisabled = this.disabledUsers.filter((user: User) => this.filterSites(user, site));
    this.enabledUsers = filterEnabled;
    this.awaitingUsers = filterAwaiting;
    this.disabledUsers = filterDisabled;
  }

  searchUserTool(searchInput: string) {
    this.resetSearch();
    if (this.selectedSite === 'All' && this.searchInput.length === 0) {
      return;
    }
    else if ((searchInput.length > 0 && this.selectedSite.length > 0 && this.selectedSite !== 'All')) {
      const filterEnabled = this.enabledUsers.filter((user: User) => this.filterSites(user, this.selectedSite));
      const filterAwaiting = this.awaitingUsers.filter((user: User) => this.filterSites(user, this.selectedSite));
      const filterDisabled = this.disabledUsers.filter((user: User) => this.filterSites(user, this.selectedSite));
      const searchEnabledName = filterEnabled.filter((user: User) => this.filterNameEmail(user, searchInput));
      const searchAwaitingName = filterAwaiting.filter((user: User) => this.filterNameEmail(user, searchInput));
      const searchDisabledName = filterDisabled.filter((user: User) => this.filterNameEmail(user, searchInput));
      this.enabledUsers = searchEnabledName;
      this.awaitingUsers = searchAwaitingName;
      this.disabledUsers = searchDisabledName;
    }
    else if (searchInput.length === 0 && this.selectedSite.length > 0 && this.selectedSite !== 'All') {
      const filterEnabled = this.enabledUsers.filter((user: User) => this.filterSites(user, this.selectedSite));
      const filterAwaiting = this.awaitingUsers.filter((user: User) => this.filterSites(user, this.selectedSite));
      const filterDisabled = this.disabledUsers.filter((user: User) => this.filterSites(user, this.selectedSite));
      this.enabledUsers = filterEnabled;
      this.awaitingUsers = filterAwaiting;
      this.disabledUsers = filterDisabled;
    }
    else {
      const searchEnabledName = this.enabledUsers.filter((user: User) => this.filterNameEmail(user, searchInput));
      const searchAwaitingName = this.awaitingUsers.filter((user: User) => this.filterNameEmail(user, searchInput));
      const searchDisabledName = this.disabledUsers.filter((user: User) => this.filterNameEmail(user, searchInput));
      this.enabledUsers = searchEnabledName;
      this.awaitingUsers = searchAwaitingName;
      this.disabledUsers = searchDisabledName;
    }
  }

  private filterNameEmail(user: User, searchInput: string): User {
    const filterResult = user.fullName.toLowerCase().indexOf(searchInput.toLowerCase()) !== -1;
    const filterResultemail = user.email.toLowerCase().indexOf(searchInput.toLowerCase()) !== -1;
    return (filterResult || filterResultemail) ? user : null;
  }

  private filterSites(user: User, site: string): User {
    const result = user.sites.filter(s => s.name === site);
    return (result.length > 0) ? user : null;
  }


  getSiteMenu() {
    return this.store.pipe(select(selector.selectAllUsers)).subscribe(dataSource => {
      if (dataSource !== undefined && dataSource.length > 0) {
        dataSource.forEach((user: User) => {
          if (dataSource !== undefined && user.sites?.length > 0) {
            user.sites.map(site => { this.siteMenu.push(site.name); });
          };
        });
        return this.siteMenu = [...new Set(this.siteMenu)];
      };
      return this.siteMenu;
    });
  }


}

