import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { filter, fromEvent, ReplaySubject, takeUntil } from 'rxjs';
import { EInputTypes } from './toggleable-input.model';

@Component({
  selector: 'app-toggleable-input',
  templateUrl: './toggleable-input.component.html',
  styleUrls: ['./toggleable-input.component.scss']
})
export class ToggleableInputComponent implements OnInit, OnDestroy {

  @Input() disabled = false; // determines whether buttons should be enabled
  @Input() min?: number | null = null;
  @Input() max?: number | null = null;
  @Input() step?: number | null = null;
  @Input() prefixLabel?: string;
  @Input() suffixLabel?: string;
  @Input() toggleable = true; // determines whether to show buttons
  @Input() type?: EInputTypes = EInputTypes.TEXT; // TODO: number is limited to price values currently
  @Input() value: string | number;
  @Output() emitUpdate?: EventEmitter<string | number> = new EventEmitter<string | number>();

  editable = false;
  internalValue: string | number;

  private onDestroy$ = new ReplaySubject<boolean>(1);

  constructor(
    private ref: ElementRef
  ) {}

  ngOnInit(): void {
    // format number - convert to string on init if type is number
    if (this.type === 'number' || typeof this.value === 'number') {
      this.internalValue = this.value.toString();
      this.padInput();
    } else {
      this.internalValue = this.value;
    }

    // observable - when clicking away call cancel function
    fromEvent<Event>(window, 'click').pipe(
      filter(($event: MouseEvent) => this.editable && !(this.ref.nativeElement as HTMLElement).contains($event.target as HTMLElement)),
      takeUntil(this.onDestroy$)
    ).subscribe(() => this.cancel());
  }

  ngOnDestroy(): void {
      this.onDestroy$.next(true);
      this.onDestroy$.complete();
  }

  cancel(): void {
    this.internalValue = this.value;
    this.toggleEditable(false);
    if (typeof this.value === 'number') {
      this.padInput();
    }
  }

  checkValue(): void {
    // on blur checks - specifically for number values - prevents whitespace, non-numerical characters (with exceptions) etc.
    if (typeof this.value === 'number' || this.type === 'number') {
      let value = 0;
      const parsedValue = parseFloat(this.internalValue as string); // internal value mutated to type string due to user input
      if (isNaN(parsedValue)) {
        value = this.value as number; // default to initial value
      } else {
        value = parsedValue;
        // ensure value does not exceed min / max
        if(this.min) {
          value = Math.max(this.min, value);
        }
        if(this.max) {
          value = Math.min(this.max, value);
        }
      }
      this.internalValue = value;
      this.padInput();
    }
  }

  clear(): void {
    this.internalValue = '';
  }

  confirm(): void {
    this.toggleEditable(false);
    this.checkValue();
    // emit update
    if (this.emitUpdate && this.value !== this.internalValue) {
      this.emitUpdate.emit(this.internalValue);
    }
  }

  toggleEditable(value?: boolean): void {
    if (value) {
      this.editable = value;
    } else {
      this.editable = !this.editable;
    }
  }

  private padInput(): void {
    // TODO: Consider other values in the future where we either might want different no. of decimal points
    // check decimal
    if (typeof this.internalValue === 'number') {
      this.internalValue = this.internalValue.toString();
    }
    if (this.internalValue.indexOf('.') === -1) {
      this.internalValue += '.';
    }
    // check trailing 0s
    const diff = (this.internalValue.length - 1) - (this.internalValue.indexOf('.'));
    if (diff < 2) {
      this.internalValue = this.internalValue.padEnd(this.internalValue.length + (2 - diff), '0');
    }
  }
}
