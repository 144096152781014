<div class="privacy">
    <div class="privacy__breadcrumb" [class.privacy__breadcrumb--has-box-shadow]="hasScrolled$ | async">
        <div class="privacy__content privacy__content--max-width-1250">
            <div class="privacy__header">
                <button [routerLink]="previousUrl" class="privacy__back-button">
                    <ion-icon name="chevron-back-outline"></ion-icon>
                </button>
                <h3>{{previousUrlString}} / Privacy Policy</h3>
            </div>
        </div>
    </div>
    <h1>Privacy Policy</h1>
    <p class="privacy__margin">This privacy notice tells you about the information we collect from you when you use our
        website. In collecting
        this information, we are acting as a data controller and, by law, we are required to provide you with
        information about us, about why and how we use your data, and about the rights you have over your data.</p>
    <div class="privacy__two-column">
        <div class="privacy__stroke-title">WHO ARE WE?</div>
        <div class="privacy__text--large">We are AutoFlow Ltd. Our registered address is Wellington House, The
            Embankment,
            Wellingborough, England, NN8 1LD. You can contact us by post at Sirius House, Alderley Road, Chelford, SK11
            9AP, by email at sales@autoflow.ltd.uk or by telephone on +44 (0)1625 860 545. We are not required to have a
            data protection officer, so any enquiries about our use of your personal data should be addressed to the
            contact details above.</div>
    </div>
    <div class="privacy__two-column">
        <div class="privacy__stroke-title">WHEN YOU USE OUR WEBSITE</div>
        <div class="privacy__text--large">When you use our website to browse our products and services and
            view the information
            we make available, a number of cookies are used by us and by third parties to allow the website to function,
            to collect useful information about visitors and to help to make your user experience better. Some of the
            cookies we use are strictly necessary for our website to function, and we don't ask for your consent to
            place these on your computer. These cookies are shown below.</div>
    </div>
    <div class="privacy__three-column">
        <div class="privacy__three-column-container privacy__three-column-container--no-border">
            <div class="privacy__stroke-title">COOKIE NAME</div>
            <div class="privacy__stroke-title">PURPOSE</div>
            <div class="privacy__stroke-title">FURTHER INFORMATION</div>
        </div>
        <div class="privacy__three-column-container">
            <div>_GA</div>
            <div>Used to Distinguish Users</div>
            <div>These cookies are used to collect information about how visitors use our site so
                that we can maintain and improve it. Information collected includes number of visits to site, Pages that
                are viewed, general geographical location and how the website was reached. The Information Collected is
                Anonymous</div>
            <div>_GAT</div>
            <div>Used to Throttle Requests</div>
            <div>These cookies are used to collect information about how visitors use our site so
                that we can maintain and improve it. Information collected includes number of visits to site, Pages that
                are viewed, general geographical location and how the website was reached. The Information Collected is
                Anonymous</div>
            <div>_GID</div>
            <div>Used to Distinguish Users</div>
            <div>These cookies are used to collect information about how visitors use our site so
                that we can maintain and improve it. Information collected includes number of visits to site, Pages that
                are viewed, general geographical location and how the website was reached. The Information Collected is
                Anonymous</div>
            <div>Cookie_Notice_Accepted</div>
            <div></div>
            <div>This Cookie is set when you approve our Cookie Warning message that appears when
                you visit the website. This cookie is used so that you do not continue to see this message when you
                revisit the site for a period</div>
        </div>
    </div>
    <span class="privacy__text-block privacy__text-block--no-border">As well as the cookies we use, various third
        parties also place them on your computer when the services they
        provide are used. These are shown below.</span>
    <div class="privacy__four-column">
        <div class="privacy__four-column-container privacy__four-column-container--no-border">
            <div class="privacy__stroke-title">COOKIE OWNER</div>
            <div class="privacy__stroke-title">COOKIE NAME</div>
            <div class="privacy__stroke-title">PURPOSE</div>
            <div class="privacy__stroke-title">PURPOSE</div>
        </div>
        <div class="privacy__four-column-container">
            <div>DoubleClick.net</div>
            <div>DSID<br>IDE</div>
            <div>Set by DoubleClick.net who are owned by Google. These cookies are used to record
                adverts seen on YouTube and Twitter.</div>
            <div></div>
            <div>Google.com<br>YouTube.com</div>
            <div>1P_JAR<br>APISID</div>
            <div>Used to record videos seen on YouTube for Google's advertising purposes.</div>
            <div></div>
            <div>Google.com<br>YouTube.com</div>
            <div>CONSENT</div>
            <div>Records whether Google's cookie notice has been accepted.</div>
            <div></div>
            <div>Google.com<br>YouTube.com</div>
            <div>SID<br>HSID</div>
            <div>Used to protect Google account Login and User data.</div>
            <div></div>
            <div>Google.com<br>YouTube.com</div>
            <div>NID<br>SAPISID</div>
            <div>Stores Google account preferences settings.</div>
            <div></div>
            <div>Google.com<br>YouTube.com</div>
            <div>SID</div>
            <div>Contains encrypted Google account sign in details.</div>
            <div></div>
            <div>Google.com<br>YouTube.com</div>
            <div>SIDCC<br>SSID</div>
            <div>Google account security cookies.</div>
            <div></div>
            <div>YouTube.com</div>
            <div>LOGIN_INFO<br>RemoteSID</div>
            <div>Stores Google/YouTube login info.</div>
            <div></div>
            <div>YouTube.com</div>
            <div>PREF<br>YSC</div>
            <div>Stored YouTube preferences info.</div>
            <div></div>
            <div>YouTube.com</div>
            <div>VISITOR_INFO1_LIVE</div>
            <div>Stored YouTube view mode preferences.</div>
            <div>Stored YouTube view mode preferences.</div>
            <div>YouTube.com</div>
            <div>Nextid</div>
            <div>Stores ID of next video to play.</div>
            <div></div>
            <div>YouTube.com</div>
            <div>Requests</div>
            <div>Throttles video requests.</div>
            <div></div>
            <div>YouTube.com</div>
            <div>_GA</div>
            <div>YouTube's Google analytics.</div>
            <div></div>
            <div>dn.syndication.twimg.com<br>Twitter.com</div>
            <div>Lang</div>
            <div>Stores language used by the user on Twitter.</div>
            <div></div>
            <div>dn.syndication.twimg.com<br>Twitter.com</div>
            <div>_GA</div>
            <div>Twitter's Google analytics.</div>
            <div></div>
            <div>dn.syndication.twimg.com<br>Twitter.com</div>
            <div>Ads_pref</div>
            <div>Stores Twitter's adverts preferences settings.</div>
            <div>Advertisements from Twitter are not used on this website.</div>
            <div>dn.syndication.twimg.com<br>Twitter.com</div>
            <div>Auth_Token</div>
            <div>Twitter authorisation token.</div>
            <div></div>
            <div>dn.syndication.twimg.com<br>Twitter.com</div>
            <div>DNT</div>
            <div>Twitter ‘do not track' cookie.</div>
            <div></div>
            <div>dn.syndication.twimg.com<br>Twitter.com</div>
            <div>Guest_ID</div>
            <div>Stores a guest ID for Twitter advertising purposes.</div>
            <div>Advertisements from Twitter are not used on this website</div>
            <div>dn.syndication.twimg.com<br>Twitter.com</div>
            <div>Personalisation_id</div>
            <div>Stores Twitter settings ID.</div>
            <div></div>
            <div>dn.syndication.twimg.com<br>Twitter.com</div>
            <div>Remember_checked_on</div>
            <div>Determine if user logged in during browser session; allow tweets; record number of
                times a page is tweeted.</div>
            <div></div>
            <div>dn.syndication.twimg.com<br>Twitter.com</div>
            <div>Twid</div>
            <div>Stores Twitter ID</div>
            <div></div>
        </div>
    </div>
    <div class="privacy__two-column">
        <div class="privacy__stroke-title">WHEN YOU SUBMIT AN ENQUIRY VIA OUR WEBSITE</div>
        <div class="privacy__text--large">
            <p>When you submit an enquiry via our website, we ask you for your
                name, contact telephone number and email address.</p>
            <p>We use this information to respond to your query,
                including providing you with any requested information about our products and services. We may also
                email
                you several times after your enquiry in order to follow up on your interest and ensure that we have
                answered
                your it to your satisfaction. We will do this based on our legitimate interest in providing accurate
                information prior to a sale.</p>
            <p>Your enquiry is stored and processed as an email which is hosted by Microsoft
                within the European Economic Area (EEA). It is also logged on a CRM system installed within our local
                network, and backed up to a datacentre, both within England, UK.</p>
            <p>We do not use the information you provide
                to make any automated decisions that might affect you.</p>
            <p>We keep enquiry emails for five years, in line with
                our data retention policy for operational data after which we delete them. CRM records are kept for five
                years after the last contact with you.</p>
        </div>
    </div>
    <div class="privacy__two-column">
        <div class="privacy__stroke-title">WHEN YOU REQUEST TRAINING VIDEOS OR MATERIALS</div>
        <div class="privacy__text--large">
            <p>When you request access to one of our training videos or other
                similar materials, such as a user manual, we ask for your name, company name (which is optional) and
                your
                email address.</p>
            <p>We use this information to email you a link to view or download the requested service. We may
                also email you after your request in order to follow up on your interest in our products and services.
                We
                will do this based on our legitimate interest in marketing to prospects for our products and services.
            </p>
            <p>We do
                not use the information you provide to make any automated decisions that might affect you.</p>
            <p>If the personal
                data provided to facilitate this is in addition to the information we hold as a result of our ongoing
                commercial relationship, it will be stored for one month to enable any necessary follow up, after which
                it
                is deleted.</p>
        </div>
    </div>
    <div class="privacy__two-column">
        <div class="privacy__stroke-title">YOUR RIGHTS AS A DATA SUBJECT</div>
        <div class="privacy__text--large">
            <p>By law, you can ask us what information we hold about you, and you
                can ask us to correct it if it is inaccurate. If we have asked for your consent to process your personal
                data, you may withdraw that consent at any time.</p>
            <p>If we are processing your personal data for reasons of
                consent or to fulfil a contract, you can ask us to give you a copy of the information in a
                machine-readable
                format so that you can transfer it to another provider.</p>
            <p>If we are processing your personal data for reasons
                of consent or legitimate interest, you can request that your data be erased.</p>
            <p>You have the right to ask us to
                stop using your information for a period of time if you believe we are not doing so lawfully.</p>
            <p>Finally, in
                some circumstances you can ask us not to reach decisions affecting you using automated processing or
                profiling.</p>
            <p>To submit a request regarding your personal data by email, post or telephone, please use the
                contact information provided above in the Who Are We section of this policy.</p>
        </div>
    </div>
    <div class="privacy__two-column">
        <div class="privacy__stroke-title">YOUR RIGHTS AS A DATA SUBJECT</div>
        <div class="privacy__text--large">
            <p>If you have a complaint about our use of your information, we would
                prefer you to contact us directly in the first instance so that we can address your complaint. However,
                you
                can also contact the Information Commissioner's Office via their website at www.ico.org.uk/concerns or
                write
                to them at:</p>
            <address>
                Information Commissioner's Office<br>
                Wycliffe House<br>
                Water Lane<br>
                Wilmslow<br>
                Cheshire<br>
                SK9 5AF<br>
            </address>
        </div>
    </div>
    <div class="privacy__two-column">
        <div class="privacy__stroke-title">UPDATES TO THIS PRIVACY POLICY</div>
        <div class="privacy__text--large">
            <p>We regularly review and, if appropriate, update this privacy policy
                from time to time, and as our services and use of personal data evolves. If we want to make use of your
                personal data in a way that we haven't previously identified, we will contact you to provide information
                about this and, if necessary, to ask for your consent.</p>
            <p>We will update the version number and date of this
                document each time it is changed.</p>
        </div>
    </div>
</div>