import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';

@Injectable()
export class CustomPaginatorIntl extends MatPaginatorIntl {
    constructor() {
        super();
    }

    getRangeLabel: (page: number, pageSize: number, length: number) => string =
      (page: number, pageSize: number, length: number) => (page + 1) + ' of ' + (Math.max(1, Math.ceil(length / pageSize))
    );
}
