/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { createReducer, on } from '@ngrx/store';
import Keycloak from 'keycloak-js';
import { User } from '../../management/models/user.model';
// eslint-disable-next-line max-len
import {
  setGroupUser,
  setKeyCloakInstance,
  setLoggedInUser,
  setUserProfile,
  setUsersList,
  sideNavToggle,
} from './core.actions';

export interface RootState {
  sideNavOpen: boolean;
  token: string;
  userProfile: Keycloak.KeycloakProfile;
  usersList: Array<User>;
  isGroupUser: boolean;
  loggedInUser: User;
  keyCloakInstance: any;
}

const initialState: RootState = {
  sideNavOpen: false,
  usersList: [],
  userProfile: {},
  token: null,
  isGroupUser: false,
  loggedInUser: null,
  keyCloakInstance: null,
};

const _appReducer = createReducer(initialState,
  on(sideNavToggle, (state: RootState, {isSideNavOpen}) => ({ ...state, sideNavOpen: isSideNavOpen })),
  on(setUserProfile, (state: RootState, { userProfile }) => ({ ...state, userProfile })),
  on(setGroupUser, (state: RootState) => ({ ...state, isGroupUser: !state.isGroupUser })),
  on(setUsersList, (state: RootState, { usersList }) => ({ ...state, usersList })),
  on(setLoggedInUser, (state: RootState, { loggedInUser }) =>
  ({ ...state, loggedInUser })),
  on(setKeyCloakInstance, (state: RootState, { keyCloakInstance }) => ({ ...state, keyCloakInstance })),
);

export function appReducer(state, action) {
  return _appReducer(state, action);
}
