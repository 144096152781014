import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UsersState } from './users.reducers';
import * as fromUsers from './users.reducers';

export const selectUsersState = createFeatureSelector<UsersState>('users');

export const selectAllUsers = createSelector(
  selectUsersState,
  fromUsers.selectAll
);

export const selectedUser = createSelector(
  selectUsersState,
  state => state.selectedUser
);

export const selectUserById = (id: string) => createSelector(
  selectAllUsers,
  users => users.find(user => user.id === id)
);

export const selectIsCreateUser = createSelector(
  selectUsersState,
  state => state.isCreateUser);

