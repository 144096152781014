import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { INotification } from './notification-cards/notification-cards.model';
@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  public readonly dismissNotificatons$: Subject<string[]> = new Subject();
  public readonly viewNotification$: Subject<string> = new Subject();
  public readonly notifications$ = new BehaviorSubject<INotification[]>([]);

  constructor() { }

  dismissNotification(ids: string[]) {
    this.dismissNotificatons$.next(ids);
  }

  viewNotification(id: string) {
    this.viewNotification$.next(id);
  }

  updateNotifications(notifications: INotification[]) {
    this.notifications$.next(notifications);
  }

}
