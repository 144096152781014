import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { KeycloakService } from 'keycloak-angular';
import { UrlService } from './url.service';
import { HttpClient } from '@angular/common/http';
import dayjs from 'dayjs';
import { Notification, NotificationTypes } from '../../notifications/notification.model';

const stringify = (data: any) => JSON.stringify(data);

export interface SocketResponse {
  eventName: string;
  groups?: string[];
  payload: Notification[] | string[];
}

interface ClientTokenResponse {
  baseUrl: string;
  joinGroups: string[];
  relayGroup: string;
  sessionId: string;
  token: string;
  url: string;
}

@Injectable({
  providedIn: 'root'
})
export class WebsocketsService {
  static notificationsFetched: EventEmitter<number> = new EventEmitter<number>();
  static socketMessageReceived: Subject<SocketResponse> = new Subject<SocketResponse>();

  public readonly connected$ = new BehaviorSubject<boolean>(false);

  private _connected = false;
  private accessToken: ClientTokenResponse;
  private hubName = '';
  private receivedTags: any = {};
  private socket: WebSocket;
  private messageAcked = true;

  constructor(
    private readonly http: HttpClient,
    private readonly keycloak: KeycloakService,
    private readonly urlService: UrlService
  ) {
    this.initialiseSocket();
  }

  get connected(): boolean {
    return this._connected;
  }

  emit(eventName: string, data = null): void {
    if (!data) {
      //? IF IT FAILS TO CONNECT TO WEB SHOULD WE STILL SHOW QUOTES / ORDERS ETC.?
      return;
    }

    data.client = this.accessToken.sessionId;
    data.eventName = `${eventName}_${this.hubName}`;
    data.groups = data.groups || this.accessToken.joinGroups;
    this.socket.send(
      stringify({
        data,
        type: 'sendToGroup',
        group: this.accessToken.relayGroup
      })
    );
  }

  getHubName(): string {
    return this.hubName;
  }

  // TODO: Implementation of "waitUntilResolved" from ABP-1779
  initialiseSocket(): void {
    if (!this.keycloak.getKeycloakInstance().authenticated) {
      return;
    }

    const authToken = `${this.keycloak.getKeycloakInstance().idToken}`;
    this.getSocketAccess(authToken).subscribe((accessToken: ClientTokenResponse) => {
      if (!accessToken) {
        return;
      }

      // Close current socket before opening another
      this.socket?.close();

      const timer = setTimeout(() => {
        clearTimeout(timer);
        console.log('access token is', accessToken);
        this.accessToken = accessToken;

        this.socket = new WebSocket(accessToken.url, 'json.webpubsub.azure.v1');
        this.hubName = accessToken.relayGroup.slice(6).toUpperCase();
        this._connected = true;
        this.connected$.next(true);
        this.onClientConnected();
      }, 300);
    });
  }

  // needed for filtering messages between environments
  matchesEnv(eventName: string): boolean {
    const suffix = eventName.match(/_(\w{2,})$/).at(0);
    return eventName.includes(suffix);
  }

  onClientConnected(): void {
    console.log('Client socket connecting...');
    this.socket.onopen = () => {
      this.accessToken.joinGroups.forEach((group) => {
        this.socket.send(
          stringify({
            data: `Hello, group ${group}...from client (ID) ${this.accessToken.sessionId} at ${dayjs()}`,
            type: 'joinGroup',
            group
          })
        );
      });
    };

    this.socket.onmessage = (event) => {
      let message = JSON.parse(event.data);
      if (message.event === 'connected') {
        this.accessToken.sessionId = message.connectionId;
        console.log(`Client socket ${this.accessToken.sessionId} is connected`);
        this.http.get(this.urlService.getUrl('GET_NOTIFICATIONS')).subscribe(() => {
          WebsocketsService.socketMessageReceived.next({
            eventName: `${NotificationTypes.NOTIFICATIONS_FETCHED}_${this.hubName}`,
            payload: []
          });
        });
      } else if (message.data) {
        const minIntervalMillis = 5000;
        message = message.data;
        if (
          // Event was sent from a different env
          !message.eventName.endsWith(this.hubName) ||
          // Same event as previous
          this.receivedTags[message.tag]
        ) {
          // Ignore if this is a duplicate message
          // or if message is not directed at this socket
          console.log('Message ignored:', message.eventName);
          return;
        }

        this.receivedTags[message.tag] = true;

        // Unset flag after short delay
        ((tag: string) => {
          const timer = setTimeout(() => {
            clearTimeout(timer);
            this.receivedTags[tag] = false;
          }, minIntervalMillis);
        })(message.tag);

        this.messageAcked = true;
        WebsocketsService.socketMessageReceived.next(message);
      }
    };

    this.socket.onclose = (data) => {
      this._connected = false;
      this.connected$.next(false);
      console.log(`Client ${this.accessToken.sessionId}'s connection was closed`, data);
    };
  }

  startTimeout(): void {
    if (!this.messageAcked) {
      return;
    }

    this.messageAcked = false;
    const delayInMilliseconds = 3000;
    const timer = setTimeout(() => {
      clearTimeout(timer);
      if (!this.messageAcked) {
        this.initialiseSocket();
      }
    }, delayInMilliseconds);
  }

  private getSocketAccess(token: string): Observable<ClientTokenResponse> {
    return this.http.post(this.urlService.getUrl('SOCKET_HANDSHAKE'), {
      key: token
    }) as Observable<ClientTokenResponse>;
  }
}
