import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SessionExpiryDialogComponent } from './core/components/session-expiry-dialog/session-expiry-dialog.component';
import { KeycloakService } from 'keycloak-angular';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import { UrlService } from './shared/services/url.service';
import * as appInfo from '../../version';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import packageJSON from '../../package.json';
import { QuotesService } from './quotes/services/quotes.service';
import { EnvironmentConfigurationService } from './shared/services/environment-configuration.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  appVersion = packageJSON.version;
  currentUrl: string = null;
  idleDialog: MatDialogRef<SessionExpiryDialogComponent>;
  previousUrl: string = null;
  title = 'Body Parts';

  private readonly userIdleSettings = {
    countdown: null,
    idleTime: null
  };

  private idleTimeInSeconds = 15 * 60;
  private countdownToSessionExpiryInSeconds = 2 * 60;

  constructor(
    private router: Router,
    private urlService: UrlService,
    private readonly translate: TranslateService,
    private readonly dialog: MatDialog,
    private readonly idle: Idle,
    private readonly keycloakService: KeycloakService,
    private readonly quotesService: QuotesService,
    private readonly configurationService: EnvironmentConfigurationService
  ) {
    idle.setIdle(this.idleTimeInSeconds);
    idle.setTimeout(this.countdownToSessionExpiryInSeconds);
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.userIdleSettings.idleTime = this.idleTimeInSeconds;
    this.userIdleSettings.countdown = this.countdownToSessionExpiryInSeconds;
  }

  ngOnInit() {
    window.onbeforeunload = null;
    this.configurationService.setConfig();
    this._setupTranslations();
    this._handleLocalStorageVersioning();
    this._sessionExpiryDialog();
    this._initializeUser();
    this._handleUserIdle();
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.previousUrl = this.currentUrl;
      this.currentUrl = event.url;
      this.urlService.setPreviousUrl(this.previousUrl);
    });
    this.quotesService.appVersionReceived$.next(`V${this.appVersion}`); //? This probably doesn't need to be here now after lib migr
  }

  private async _initializeUser() {
    if (await this.keycloakService.isLoggedIn()) {
      await this._setTokenCookie();
    } else {
      console.error('User is not logged in.');
      try {
        this.keycloakService.isTokenExpired();
      } catch (err) {
        // Route to login screen when token expires
        // This is required for the error page
        this.router.navigateByUrl('/');
      }
    }
  }

  /** TODO: Handle minor and major version change (START VERSIONING CORRECTLY) */
  private _handleLocalStorageVersioning() {
    const storageVersionKey = 'storage_version';
    const storageVersion = localStorage.getItem(storageVersionKey);
    if (!storageVersion || storageVersion !== appInfo.version) {
      localStorage.clear();
      localStorage.setItem(storageVersionKey, appInfo.version);
    }
  }

  private _setupTranslations() {
    this.translate.setDefaultLang('en');
  }

  private _handleUserIdle() {
    this.idle.watch();
    this.userIdleSettings.countdown = null;
  }

  private async _setTokenCookie() {
    window.document.cookie = `token=${await this.keycloakService.getToken()}`;
  }

  private _sessionExpiryDialog() {
    // Act when screen becomes idle
    this.idle.onIdleStart.subscribe(() => {
      if (this.idleDialog) {
        window.onbeforeunload = null;
        return;
      }

      this.idleDialog = this.dialog.open(SessionExpiryDialogComponent, {
        disableClose: true,
        closeOnNavigation: false,
        hasBackdrop: true,
        data: this.userIdleSettings
      });

      this.idleDialog.afterClosed().subscribe(async (refreshSession: boolean) => {
        if (refreshSession) {
          this.keycloakService.updateToken(this.idle.getIdle());
          this._setTokenCookie();
          this.idle.setTimeout(this.idle.getTimeout());
          this.idleDialog = null;
        }
      });
    });

    // Act when user becomes active
    this.idle.onIdleEnd.subscribe(() => (this.userIdleSettings.countdown = null));

    // Act during timeout countdown
    this.idle.onTimeoutWarning.subscribe((secondsRemaining) => (this.userIdleSettings.countdown = secondsRemaining));

    // Act when user has timed out
    this.idle.onTimeout.subscribe(() => {
      window.onbeforeunload = null; // prevents the "discard changes" popup appearing
      const keycloakInstance: Keycloak.KeycloakInstance = this.keycloakService.getKeycloakInstance();
      const idToken: string = keycloakInstance.idToken;
      const keycloakLoginUrl: string = this.configurationService.getConfig('keycloakUrl');
      const baseUrl: string = this.configurationService.getConfig('baseUrl');
      const realm: string = this.configurationService.getConfig('keycloakRealm');
      const common = `realms/${realm}/protocol/openid-connect`;
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const post_redirect_url = `&post_logout_redirect_uri=${encodeURIComponent(baseUrl)}`;
      const redirectUrl = `${keycloakLoginUrl}/${common}/logout?id_token_hint=${idToken}${post_redirect_url}`;
      window.location.href = redirectUrl;
    });
  }
}
