import dayjs from 'dayjs';
import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { NotificationsService } from '../notifications.service';
import { EScheme } from '../../../parts/models/parts-list.model';
import { EStatus } from '../../../parts/components/parts/status-badge/status-badge.model';
import { EStateType, ECloseButtonAlignment, INotification } from './notification-cards.model';
import { assignEnum, checkValues } from './notification-card.funcs';

@Component({
  selector: 'app-notification-cards',
  templateUrl: './notification-cards.component.html',
  styleUrls: ['./notification-cards.component.scss']
})
export class NotificationCardsComponent implements OnInit, OnChanges {
  @Input() scheme?: EScheme;
  @Input() closeBtnPosition?= ECloseButtonAlignment.LEFT;
  @Input() showScheme?: boolean = true;
  @Input() notification?: INotification;
  @Input() status?: EStatus;
  @Input() ageing?: EStateType;

  constructor(public readonly notificationService: NotificationsService) {
  }

  /**
   * gets the default EStatus
   */
  get newStatus(): EStatus {
    return EStatus.NEW;
  }

  /**
   * gets the default EScheme
   */
  get newScheme(): EScheme {
    return EScheme.GEN;
  }

  /**
   * gets the title from notification object
   */
  get title(): string {
    return this.notification === undefined ? 'No title' : this.notification.message.title;
  }


  /**
   * gets the body text from notification object
   */
  get bodyText(): string {
    return this.notification === undefined ? 'No body text' : this.notification.message.text;
  }

  get customStatus(): EStatus {
    return EStatus.REGISTRATION;
  }

  /**
   * gets the createdAt Date from the notification
   */
  get date(): string {
    const test = this.notification?.createdAt;
    const now = dayjs();
    return this.notification === undefined ? dayjs('' + now + '').format('MMM D') : dayjs(test).format('MMM D');
  }

  /**
   * gets the name of the user that viewed the notification
   */
  get viewedBy(): string | undefined {
    return this.notification === undefined ? undefined : this.notification?.viewedBy;
  }

  /**
   * gets the abbreviated name of the user that viewed the notification
   */
  get viewedByAbbr(): string | undefined {
    return this.notification === undefined ? '' : this.notification.viewedByAbbr;
  }

  dismiss(event: MouseEvent): boolean {
    event.stopPropagation();
    const id = this.notification?._id;
    if (id === undefined) {
      return false;
    } else {
      this.notificationService.dismissNotification([id]);
    }
    return false;
  }

  ngOnInit(): any {
    if (this.notification !== undefined) {
      const _status = this.notification?.status;
      const _ageing = this.notification?.ageing;
      const scheme = this.notification?.assessedAs;

      if (checkValues(this.notification.status, this.status)) {
        this.status = assignEnum(EStatus, _status);
      }

      if (checkValues(this.notification.ageing, this.ageing)) {
        this.ageing = assignEnum(EStateType, _ageing);
      }

      if (checkValues(this.notification.assessedAs, this.scheme)) {
        this.scheme = assignEnum(EScheme, scheme);
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {

    const statusChange = changes['status'];
    if (checkValues(statusChange, this.notification?.status)) {
      if (this.notification?.status) {
        this.notification.status = this.status?.toString() ?? '';
      }
    }

    const ageingChange = changes['ageing'];
    if (checkValues(ageingChange, this.notification?.ageing)) {
      if (this.notification?.ageing) {
        this.notification.ageing = this.ageing?.toString() ?? '';
      }
    }

    const schemeChanges = changes['scheme'];
    if (checkValues(schemeChanges, this.notification?.assessedAs)) {
      if (this.notification?.assessedAs) {
        this.notification.assessedAs = this.scheme?.toString() ?? '';
      }
    }

  }

  /**
   * @returns boolean
   */
  checkAlignment(closeBtnPosition: string): boolean {
    switch (closeBtnPosition) {
      case 'left':
        return this.closeBtnPosition === ECloseButtonAlignment.LEFT;
      case 'right':
        return this.closeBtnPosition === ECloseButtonAlignment.RIGHT;
      default:
        return this.closeBtnPosition === ECloseButtonAlignment.RIGHT;
    }
  }

  /**
   * used for conional rendering on the frontend
   * @returns boolean
   */
  compareAgeing(ageing: string): boolean {

    switch (ageing) {
      case 'WEEKONE':
        return this.ageing === EStateType.WEEKONE;
      case 'WEEKTWO':
        return this.ageing === EStateType.WEEKTWO;
      case 'ANCIENT':
        return this.ageing === EStateType.ANCIENT;
      default:
        return this.ageing === EStateType.WEEKONE;
    }
  }

  cardViewed(id: string) {
    this.notificationService.viewNotification(id);
  }
}
