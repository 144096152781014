import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import { BehaviorSubject, map } from 'rxjs';
import { UrlService } from '../core/services/url.service';
import { WebsocketsService } from '../core/services/websockets.service';
import { predicates } from '../shared/predicates';
import { Notification } from './notification.model';
import { NotificationsService as LibNotificationService } from './lib/notifications.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  notifications = null;
  updatedNotifications$ = new BehaviorSubject<Notification[]>(null);

  constructor(
    private readonly http: HttpClient,
    private readonly urlService: UrlService,
    private readonly websocketsService: WebsocketsService,
    private readonly libNotificationService: LibNotificationService
  ) {
    this.libNotificationService.dismissNotificatons$.subscribe((ids) => this.clearNotifications(ids));

    this.libNotificationService.viewNotification$.subscribe((id: string) => {
      if (this.notifications.some((x: Notification) => x._id === id && !x.viewedBy)) {
        this.markNotificationViewed(id);
      }
    });
  }

  addNotification(notification: any): void {
    this.initialiseNotifications([notification]);
  }

  /**
   * Clears one or more notifications
   */
  clearNotifications(notificationIDs?: string[]): void {
    const current = [...this.notifications];
    const updatedArray = current.filter((x) => !notificationIDs.includes(x._id));
    const payload: any = {
      centreCodes: Array.from(
        new Set(
          this.getLoadedNotifications()
            .filter((n) => notificationIDs.includes(n._id))
            .map((n) => n.centreCode)
        )
      )
    };
    this.websocketsService.startTimeout();
    const post = this.http.post(
      this.urlService.getUrl('CLEAR_NOTIFICATIONS'),
      Object.assign(
        {
          willClear: true,
          data: notificationIDs
        },
        payload
      )
    );
    post.subscribe(() => {
      this.notifications = updatedArray;
      this.updatedNotifications$.next(this.notifications);
    });
  }

  getLoadedNotifications(): any[] {
    if (!this.notifications) {
      return [];
    }
    return this.notifications;
  }

  initialiseNotifications(notifications?: any[]): Notification[] {
    if (notifications) {
      const sortByDateCreated = (array: Notification[]) => array.sort((a: any, b: any) => b.createdAt - a.createdAt);

      const getUrl = (values: any) => {
        if (values.message) {
          return values.message.toLowerCase().includes('order')
            ? `/orders/${values.order}`
            : `/quotes/${values.quote}`;
        }
      };

      const getAbr = (values: Notification) => {
        let initials = '';
        if (values.viewedBy) {
          values.viewedBy.split(' ').forEach((x) => (initials = initials.concat(x.charAt(0))));
        }
        return initials;
      };

      const updated = notifications.map((values) => ({
        ...values,
        createdAt: dayjs(values.createdAt), // convert dates to dayJS dates
        archivedAt: dayjs(),
        clearedBy: null,
        clearedAt: dayjs(),
        viewedByAbbr: getAbr(values),
        url: getUrl(values),
        message: {
          title: values.message,
          text: values.repairerName
        }
      }));
      if (!this.notifications) {
        this.notifications = sortByDateCreated([...updated]);
        this.updatedNotifications$.next(this.notifications);
        return this.notifications;
        // if new notifications recieved, merge with previously updated ones
      } else {
        const newNotifications = sortByDateCreated([...this.notifications, ...updated]);
        this.updatedNotifications$.next(newNotifications);
        return (this.notifications = newNotifications);
      }
    }
  }

  /**
   * Mark a notification as viewed
   */
  markNotificationViewed(notificationID: string): void {
    this.websocketsService.startTimeout();
    const viewed = this.http.post(this.urlService.getUrl('MARK_NOTIFICATION_VIEWED'), {
      markViewed: true,
      data: [notificationID]
    });
    viewed.subscribe(() => {
      // needs something in the subscribe
      console.log('viewed notification');
    });
  }

  getNotifications(): void {
    this.http
      .get(this.urlService.getUrl('GET_NOTIFICATIONS'))
      .pipe(map((data: Notification[]) => data.filter(predicates.isDateLessThan30DaysAgo))) // TODO: THIS SHOULD BE FILTERED AT THE SERVER
      .subscribe((data: Notification[]) => {
        this.notifications = [];
        this.updatedNotifications$.next(this.notifications);
        this.initialiseNotifications(data);
      });
  }

  resetNotifications(): void {
    this.notifications = [];
    this.updatedNotifications$.next(this.notifications);
  }
}
