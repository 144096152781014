import { Component, OnDestroy, OnInit } from '@angular/core';
import { NotificationsService } from '../../notifications.service';
import { INotification } from '../../notification-cards/notification-cards.model';
import { ReplaySubject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-notifications-filter-list-header',
  templateUrl: './notifications-filter-list-header.component.html',
  styleUrls: ['./notifications-filter-list-header.component.scss']
})
export class NotificationsFilterListHeaderComponent implements OnInit, OnDestroy {
  notifications: INotification[] = [];
  private onDestroy$ = new ReplaySubject<boolean>(1);

  constructor(private readonly notificationService: NotificationsService) { }

  ngOnInit(): void {
    this.notificationService.notifications$.pipe(
      takeUntil(this.onDestroy$))
      .subscribe(val => this.notifications = val);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  getNotificationsCount(): number {
    return (this.notifications?.filter((x: INotification) => !x.viewedBy) || []).length;
  }

}
