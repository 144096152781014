<div class="dash__page-header" [class.dash__page-header--has-box-shadow]="hasScrolled$ | async">
    <div class="dash__content"
        [class.dash__content--max-width-1250]="routerLink.length > 0"
        [class.dash__content--max-width-1600]="routerLink.length === 0"
    >
        <div class="dash__header"
        [class.dash__header--hidden]="routerLink.length === 0 && hasScrolled$ | async"
            [class.dash__header--decreased-margin]="routerLink.length > 0"
        >
            <button appBackButton *ngIf="routerLink.length > 0" 
                class="dash__back-button" 
                [class.dash__back-button--small]="(hasScrolled$ | async)"
            >
                <ion-icon name="chevron-back-outline"></ion-icon>
            </button>
            <h3>{{title}}</h3>
        </div>
    </div>
</div>
<div class="dash">
    <div class="dash__search">
        <!-- *** Search tool bar -->
        <mat-form-field title="search" class="mat-mdc-form-field__modern mat-mdc-form-field__search mat-mdc-form-field__primary"
            appearance="outline">
            <input matInput [(ngModel)]="searchInput" (keyup)="searchVirtualGroup(searchInput)" placeholder="Search for group name" type="text">
            <ion-icon matSuffix name="search-circle"></ion-icon>
        </mat-form-field>
        <!-- * End Search tool bar -->

        <!-- *Search Site on dropdown Menu -->
        <mat-form-field title="dropdown" class="mat-form-field__modern mat-form-field__dropdown mat-form-field__primary"
            appearance="outline">
            <mat-select [(ngModel)]="selectedGroup" (selectionChange)="onGroupChange(selectedGroup)" placeholder="Groups">
                <mat-option [value]="'All'">All Groups</mat-option>
                <mat-option *ngFor="let group of groupMenu" [value]="group">{{group}}</mat-option>
            </mat-select>
        </mat-form-field>
        <!-- *End Search Site on dropdown Menu -->

        <button mat-flat-button class="mat-mdc-unelevated-button__duodenary mat-mdc-unelevated-button__duodenary--inactive" [routerLink]="'/management'">Users</button>
        <button mat-flat-button class="mat-mdc-unelevated-button__duodenary">Groups</button>
        <button mat-flat-button class="mat-mdc-unelevated-button__primary" (click)="toggleGroupAdminSidebar($event)">+ Create new group</button>
    </div>
    <div *ngIf="enabledGroups.length>0" class="dash__active-groups">
        <div class="dash__titles dash__titles--green">
            <p>Active groups</p>
            <p>Current active groups, use the <span>actions</span> menu on the right to manage groups.</p>
        </div>
        <div class="dash__list-container dash__list-container--green">
            <div class="dash__group-header">
                <div class="dash__current-groups-header">Current groups</div>
                <div>Active sites</div>
                <div>Active users</div>
            </div>
            <div class="dash__group-row" *ngFor="let enabledGroup of enabledGroups">
                <div>{{enabledGroup.name}}</div>
                <div><span class="dash__count">{{enabledGroup.siteCount}}</span></div>
                <div><span class="dash__count">{{enabledGroup.userCount}}</span></div>
            </div>
        </div>
    </div>
    <div *ngIf="awaitingGroups.length>0" class="dash__await-activation">
        <div class="dash__titles dash__titles--orange">
            <p>Waiting for active sites</p>
            <p>These groups have no <span>active</span> sites, please setup sites against the <span>group</span>. </p>
        </div>
        <div class="dash__list-container dash__list-container--orange">
            <div class="dash__group-header">
                <div class="dash__current-groups-header">Current groups</div>
                <div>Active sites</div>
                <div>Active users</div>
            </div>
            <div class="dash__group-row" *ngFor="let awaitingGroup of awaitingGroups">
                <div>{{awaitingGroup.name}}</div>
                <div><span class="dash__count">{{awaitingGroup.siteCount}}</span></div>
                <div><span class="dash__count">{{awaitingGroup.userCount}}</span></div>
            </div>
        </div>
    </div>
</div>