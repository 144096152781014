import { Injectable } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

@Injectable()
export class NotificationSidebarService {

  private notificationSidebar: MatSidenav;

  setSidenav(notificationSidebar: MatSidenav) {
    this.notificationSidebar = notificationSidebar;
  }

  open() {
    return this.notificationSidebar.open();
  }

  close() {
    return this.notificationSidebar.close();
  }
}
