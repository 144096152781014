<footer class="footer">
    <div class="footer__main">
        <img src="assets/images/AutoFlowLogo07192C.svg" alt="AutoFlow Ltd">
        <p>&copy;{{anio}} AutoFlow Ltd.</p>
        <p><a routerLink="/privacy">Privacy</a></p>
    </div>
    <div class="footer__support">
        <h3>Support</h3>
        <a href="https://share-eu1.hsforms.com/1YLWAPlTeS4q3BxpWYaElVwg4alw" target="__blank">Need support</a>
    </div>
    <div class="footer__contact">
        <h3>Get in touch</h3>
        <a href="https://www.autoflow.ltd.uk/contact" target="__blank">Contact AutoFlow</a>
    </div>
</footer>