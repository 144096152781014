/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CTLAPart } from '../models/parts-list.model';
import { TlaService } from './tla.service';
import { RoundingService } from './rounding.service';
import { ERoundingScheme } from '../models/parts-list.model';
import Big from 'big.js';
import { UserService } from '../../user.service';

const insurerTLAMargin = 0.8;
const tllDeductable = 0.02; // percentage deductable from TLL

export interface IPreCalcPart {
  _id: string;
  uniquePartId: string;
  discountMaximum: number;
  discount: number;
  discountAmount?: number;
  net: number;
  partId?: number;
  quantity: number;
  rrp: number;
  surcharge?: number;
  unit: number;
  tlaRecommendedICOP?: number | null;
  tlaRecommendedDiscount?: number | null;
  tlaRecommendedPrice?: number | null;
  tlaDiscount?: number | null; // TODO: can we do tlaDiscount?: number
  tlaPrice?: number | null; // TODO: can we do tlaPrice?: number
}

export interface ICalculatedPart extends IPreCalcPart {
  maxAddDiscount: number;
  tlaDiscAmount: number;
  tlaDisplayPrice?: number;
  icop: number;
}

export interface IAssement {
  bottomLineDiscount: number;
  preAccidentValue: number;
  totalLossPercentage: number;
  totalRepairCost: number;
  workProviderDiscount: number;
  totalNonPartCost: number;
}

@Injectable({
  providedIn: 'root'
})
export class CalculationsService {
  assessment = {};
  originalParts: any[] = [];
  preparedParts: any[] = [];
  tlaSummary: any = {};
  tlaSummaryNoSupport: any = {}; // summary based on 0% TLA support
  tlaOptimumValue = 0;
  optimumStartingPos$ = new BehaviorSubject<number>(0);
  private _tlaSupportValue$ = new BehaviorSubject<number>(0);
  tlaSupportValue$ = this._tlaSupportValue$.asObservable();

  constructor(
    private readonly roundingService: RoundingService,
    private readonly tlaService: TlaService,
    private userService: UserService
  ) {}

  prepPartsForCalculation(tlaSupport: number, parts: any[]): any {
    this.originalParts = parts;
    this.preparedParts = parts.map((part: IPreCalcPart) => ({
      _id: part._id,
      uniquePartId: part.uniquePartId,
      discount: Number(part.discount),
      net: Number(part.net),
      quantity: Number(part.quantity),
      rrp: Number(part.rrp),
      unit: Number(part.unit),
      discountMaximum: Number(part.discountMaximum) ? part.discountMaximum : 0.45
    }));

    const calculatedTLA = this.calculateTLA(tlaSupport, this.preparedParts);
    //Leave this console statement to ensure all TLA figures calculated correctly for each part
    return calculatedTLA;
  }

  maxAdditionalDiscount(maxAllowable: number, currentDiscount: number): number {
    // TODO: maxAllowable may not need to be multiplied by 100 on DEV/Arcus
    const calculatedMaxDiscount = Big(Big(maxAllowable).mul(100)).minus(currentDiscount).toNumber();
    return Math.min(100, Math.max(0, calculatedMaxDiscount));
  }

  resetOptimum(): void {
    this.tlaOptimumValue = 0;
    this.optimumStartingPos$ = new BehaviorSubject<number>(0);
  }

  tlaDiscountPercent(maxAdditionalDiscount: number, currentSliderValue: number): number {
    const tlaSupport = Big(currentSliderValue || 0)
      .div(100)
      .toNumber();
    const additionalDiscountPercent = Big(maxAdditionalDiscount).mul(tlaSupport).toNumber(); // ensure value is never less than 0
    return Math.min(100, Math.max(0, additionalDiscountPercent));
  }

  totalDiscountAmount(
    rrp: number,
    preAppliedDiscountPercent: number,
    maxAdditionalDiscountPercent: number,
    sliderValue: number
  ): number {
    let totalDiscount = Big(maxAdditionalDiscountPercent).div(100).mul(sliderValue);
    totalDiscount = totalDiscount.div(100);
    totalDiscount = Big(rrp)
      .mul(Big(Big(preAppliedDiscountPercent).div(100).add(totalDiscount)))
      .toNumber();
    return Math.max(0, totalDiscount);
  }

  tlaPrice(rrp: number, tlaDiscountAmount: number, quantity = 1): number {
    // ! TLA PRICE IS NOT ROUNDED AS IT'S AKIN TO UNIT PRICE
    return Math.max(0, Big(rrp).minus(tlaDiscountAmount).mul(quantity).div(quantity).toNumber());
  }

  partICOPAmount(rrp: number, tlaAdditionalDiscountAmount: number): number {
    const assessment = this.assessment as IAssement;
    const wpDiscount = Big(1)
      .minus(assessment.workProviderDiscount ?? 0)
      .toNumber();
    let icopAmount = Big(rrp).mul(wpDiscount).toNumber();
    const discountMarginMutiplied = Big(tlaAdditionalDiscountAmount).mul(insurerTLAMargin).toNumber();
    icopAmount = Big(icopAmount).minus(discountMarginMutiplied).toNumber();
    return Math.max(0, icopAmount);
  }

  otherCosts(): number {
    const assessment = this.assessment as IAssement;
    return Number(assessment.totalNonPartCost);
  }

  sumValues(valuesArray: number[]): number {
    return valuesArray.reduce((acc: number, curr: number) => Big(acc).add(curr).toNumber(), 0);
  }

  updateTlaSupport(tlaSupportValue: number): void {
    this._tlaSupportValue$.next(tlaSupportValue);
  }

  calculateNetValue(rrp: number, quantity: number, preAppliedDiscountPercent: number): number {
    let net = Big(rrp).mul(quantity);
    let discountAsDecimal = Big(preAppliedDiscountPercent || 0).div(100);
    discountAsDecimal = Big(1).minus(discountAsDecimal).toNumber();
    net = net.mul(discountAsDecimal).toNumber();
    return +this.roundingService.roundValue(
      net,
      this.userService.isSubscribed() ? ERoundingScheme.ROUND_UP : ERoundingScheme.AUTO
    ) as number;
  }

  calculateSliderStartPos(sumTotalRepairIcop: number): number {
    const assessment = this.assessment as IAssement;

    const { preAccidentValue } = assessment;

    let totalLossLimit = Big(preAccidentValue).mul(assessment.totalLossPercentage);
    // TLL - 2%
    const multiplier = Big(1).minus(tllDeductable);
    totalLossLimit = totalLossLimit.mul(multiplier).toNumber();

    let targetPrice = Big(sumTotalRepairIcop).minus(totalLossLimit);
    targetPrice = Big(targetPrice).mul(100).toNumber();

    const partCalcs = this.preparedParts
      .map((part) => {
        let maxDiscount = Big(
          this.maxAdditionalDiscount(Big(part.discountMaximum || 0).toNumber(), Big(part.discount).toNumber())
        );
        maxDiscount = maxDiscount.div(100).toNumber();
        let value = Big(part.rrp).mul(part.quantity);
        value = value.mul(maxDiscount).toNumber();
        return Math.max(0, value);
      })
      .reduce((acc: number, curr: number) => Big(acc).add(curr).toNumber());

    const calculatedSliderPos = Big(targetPrice)
      .div(partCalcs)
      .div(insurerTLAMargin)
      .div(Big(1).minus(assessment.bottomLineDiscount))
      .toNumber();
    const sliderPos = partCalcs === 0 ? 0 : Math.min(100, Math.max(0, Math.ceil(calculatedSliderPos)));
    this.tlaOptimumValue = sliderPos;
    this.optimumStartingPos$.next(sliderPos);
    return sliderPos;
  }

  //TODO: needs data model
  tlaSummaryCalc(tlaPartsList: ICalculatedPart[], calculatingOptimum = false): void {
    const assessment = this.assessment as IAssement;
    const tlaPriceValuesIncQuantity: number[] = tlaPartsList.map(
      (part) =>
        +this.roundingService.roundValue(
          Big(part.quantity).mul(part.tlaPrice).toNumber(),
          this.userService.isSubscribed() ? ERoundingScheme.ROUND_UP : ERoundingScheme.AUTO
        ) ?? 0
    );
    const rrpValues: number[] = tlaPartsList.map((part) => Big(part.quantity).mul(part.rrp).toNumber());
    const tradePriceValues: number[] = tlaPartsList.map(
      (part) =>
        +this.roundingService.roundValue(
          Big(part.net).toNumber(),
          this.userService.isSubscribed() ? ERoundingScheme.ROUND_UP : ERoundingScheme.AUTO
        )
    );
    const partIcopValues: number[] = tlaPartsList.map((part) => Big(part.icop).mul(part.quantity).toNumber());
    const totalTLAPrice = this.sumValues(
      tlaPartsList.map(
        (part) =>
          +this.roundingService.roundValue(
            part.tlaDisplayPrice,
            this.userService.isSubscribed() ? ERoundingScheme.ROUND_UP : ERoundingScheme.AUTO
          )
      )
    );
    const sumOfICOPValues: number = this.sumValues(partIcopValues);
    const sumOfRRPValues: number = this.sumValues(rrpValues);
    const sumOfTlaValuesIncQuantity: number = this.sumValues(tlaPriceValuesIncQuantity);
    const sumOfTradePriceValues: number = this.sumValues(tradePriceValues);
    const additionalSupportGiven: number = Big(sumOfTradePriceValues).minus(totalTLAPrice).toNumber();
    const wpDiscountDeducted = Big(1).minus(assessment.workProviderDiscount);
    const blDiscountDeducted = Big(1).minus(assessment.bottomLineDiscount);
    let valuesWithMargin = Big(sumOfTradePriceValues).minus(sumOfTlaValuesIncQuantity);
    valuesWithMargin = valuesWithMargin.mul(insurerTLAMargin);
    const partsIcop = Big(sumOfRRPValues).mul(wpDiscountDeducted).minus(valuesWithMargin).toNumber();
    // TODO: CALCULATE LABOUR AND MATERIALS USING "BIG.JS"
    const labourAndMaterials = Big(assessment.totalRepairCost).minus(sumOfRRPValues).toNumber();
    const sumTotalRepairIcop = Big(partsIcop).add(labourAndMaterials).mul(blDiscountDeducted).toNumber();
    const tlaSupportPercent = Big(additionalSupportGiven).div(sumOfRRPValues).mul(100).toNumber();
    const ICOPDisplay = Big(sumOfRRPValues)
      .mul(wpDiscountDeducted)
      .mul(blDiscountDeducted)
      .minus(valuesWithMargin)
      .toNumber();
    const estimatedRepairerMargin = Big(ICOPDisplay).minus(sumOfTlaValuesIncQuantity).toNumber();
    const summary = {
      totalTLAPrice: sumOfTlaValuesIncQuantity,
      totalRRP: sumOfRRPValues,
      totalTradePrice: sumOfTradePriceValues,
      totalpartsIcop: sumOfICOPValues,
      totalRepairCost: assessment.totalRepairCost,
      tradeAndLabour: sumOfTradePriceValues + labourAndMaterials,
      totalTLACost: sumOfTlaValuesIncQuantity + labourAndMaterials,
      icopCalc: partsIcop,
      totalRepairICOP: sumTotalRepairIcop,
      additionalSupportGiven,
      tlaSupportPercent,
      estimatedRepairerMargin,
      ICOPDisplay
    };
    if (calculatingOptimum) {
      this.tlaSummaryNoSupport = summary;
    } else {
      this.tlaSummary = summary;
    }
    this.tlaService.additionalDiscount$.next(additionalSupportGiven);
    this.tlaService.ICOPafterTLASupport$.next(sumTotalRepairIcop);
  }

  private calculateTLA(tlaSupport: number, partsList: IPreCalcPart[]): Record<string, any>[] {
    const currentSliderValue = tlaSupport; // 0 - 100 TLA Support (%)
    if (partsList.length > 0) {
      const calculatedTLAPartsList = partsList.map((part: IPreCalcPart, index: number): ICalculatedPart => {
        /*
          Note: rounding values at this stage causes the final value(s) to be incorrect
        */
        // being returned as whole number i.e.: 10% = 10
        const maxAdditionalDiscountPercent = this.maxAdditionalDiscount(part.discountMaximum || 0.45, part.discount);
        // being returned as float i.e.: 10% = 0.10
        const tlaDiscountPercent = this.tlaDiscountPercent(maxAdditionalDiscountPercent, currentSliderValue);
        const tlaRecommendedDiscountPercent = this.tlaDiscountPercent(
          maxAdditionalDiscountPercent,
          this.tlaOptimumValue
        );
        const totalDiscountAmount = this.totalDiscountAmount(
          part.rrp,
          part.discount,
          maxAdditionalDiscountPercent,
          currentSliderValue
        );
        const totalDiscountAmountRecommended = this.totalDiscountAmount(
          part.rrp,
          part.discount,
          maxAdditionalDiscountPercent,
          this.tlaOptimumValue
        );
        const tlaRecommendedPrice = this.tlaPrice(part.rrp, totalDiscountAmountRecommended, part.quantity);
        const tlaPrice = this.tlaPrice(part.rrp, totalDiscountAmount, part.quantity); // prevent tla price exceeding net (see ABP-1712)
        const tlaFromTradeDiscountAmount = Big(tlaPrice).minus(part.unit).toNumber();
        const partICOP = this.partICOPAmount(part.rrp, tlaFromTradeDiscountAmount); // this might need to be rounded
        const tlaRecommendedFromTradeDiscountAmount = Big(tlaRecommendedPrice).minus(part.unit).toNumber();
        const partRecommendedICOP = this.partICOPAmount(part.rrp, tlaRecommendedFromTradeDiscountAmount); // this might need to be rounded
        return {
          ...part,
          tlaDiscount: Number(tlaDiscountPercent),
          maxAddDiscount: Number(maxAdditionalDiscountPercent),
          tlaPrice: Number(tlaPrice),
          tlaDiscAmount: Number(tlaDiscountPercent),
          //! TLA PRICE IS ROUNDED AKIN TO NET - CAN NOT EXCEED NET PRICE - WILL ALWAYS ROUND UP AS ASSUMED SUBSCRIBED
          tlaDisplayPrice: Math.min(
            +this.roundingService.roundValue(
              part.net,
              this.userService.isSubscribed() ? ERoundingScheme.ROUND_UP : ERoundingScheme.AUTO
            ),
            +this.roundingService.roundValue(
              Big(part.quantity).mul(tlaPrice).toNumber(),
              this.userService.isSubscribed() ? ERoundingScheme.ROUND_UP : ERoundingScheme.AUTO
            )
          ),
          icop: Number(partICOP),
          tlaRecommendedICOP: Number(partRecommendedICOP),
          tlaRecommendedDiscount: Number(tlaRecommendedDiscountPercent),
          tlaRecommendedPrice: Number(tlaRecommendedPrice)
        };
      });
      let mergedParts: any[] = [];
      mergedParts = this.originalParts
        .map((t1) => ({ ...t1, ...calculatedTLAPartsList.find((t2) => t2.uniquePartId === t1.uniquePartId) }))
        .map(
          (part) =>
            ({
              ...part,
              discountMaximum: Big(part.discountMaximum).toNumber(),
              discount: part.discount,
              net: part.net,
              rrp: part.rrp,
              unit: part.unit,
              tlaPrice: part.tlaPrice,
              tlaDisplayPrice: part.tlaDisplayPrice,
              icop: part.icop,
              tlaDiscount: part.tlaDiscount,
              tlaDiscAmount: part.tlaDiscAmount
            } as CTLAPart)
        );
      return mergedParts;
    }
    return [];
  }
}
