<div class="dash__page-header" 
[class.dash__page-header--default]="(hasScrolled$ | async) === false"
[class.dash__page-header--scrolled]="(hasScrolled$ | async) === true"
[class.dash__page-header--has-box-shadow]="hasScrolled$ | async"
>
  <div class="dash__content" [class.dash__content--max-width-1250]="routerLink.length"
    [class.dash__content--max-width-1600]="!routerLink.length">
    <div class="dash__header" [class.dash__header--hidden]="!routerLink.length && hasScrolled$ | async"
      [class.dash__header--decreased-margin]="routerLink.length">
      <button appBackButton *ngIf="routerLink.length" class="dash__back-button"
        [class.dash__back-button--small]="(hasScrolled$ | async)">
        <ion-icon name="chevron-back-outline"></ion-icon>
      </button>
      <h3>{{title}}</h3>
    </div>
  </div>
</div>

<div class="dash">
  <div class="dash__search">
    <!-- *** Search tool bar -->
    <mat-form-field title="search" class="mat-form-field__modern mat-form-field__search mat-form-field__primary"
      appearance="outline">
      <input matInput [(ngModel)]="searchInput" (keyup)="searchUserTool(searchInput)" placeholder="Search for users, email" type="text">
      <ion-icon matSuffix name="search-circle"></ion-icon>
    </mat-form-field>
    <!-- * End Search tool bar -->

    <!-- *Search Site on dropdown Menu -->
    <mat-form-field title="dropdown" class="mat-form-field__modern mat-form-field__dropdown mat-form-field__primary"
      appearance="outline">
      <mat-select [(ngModel)]="selectedSite" (selectionChange)="onSiteChange(selectedSite)" placeholder="Sites">
        <mat-option [value]="'All'">All Sites</mat-option>
        <mat-option *ngFor="let site of siteMenu" [value]="site">{{site}}</mat-option>
      </mat-select>
    </mat-form-field>
    <!-- *End Search Site on dropdown Menu -->

    <button mat-flat-button class="mat-mdc-unelevated-button__duodenary">Users</button>
    <button mat-flat-button class="mat-mdc-unelevated-button__duodenary mat-mdc-unelevated-button__duodenary--inactive"
      [routerLink]="'/management-groups'">Groups</button>
    <button mat-flat-button class="mat-mdc-unelevated-button__primary" (click)="createUser($event)">+ Create new user</button>
  </div>

  <div *ngIf="enabledUsers.length" class="dash__active-users">
    <div class="dash__titles dash__titles--green">
      <p>Active Users</p>
      <p>Current active users, use the <span>actions</span> <ion-icon name="ellipsis-vertical"></ion-icon> menu on the right to manage users.</p>
    </div>
    <div class="dash__list-container dash__list-container--green">
      <div class="dash__user-header">
        <div class="dash__current-users-header">Current users</div>
        <div>User email address</div>
        <div class="dash__role-header">Role</div>
        <div>Joined</div>
        <div>User site access</div>
        <div class="dash__status-header">Status</div>
      </div>
      <div class="dash__user-row" *ngFor="let enabledUser of enabledUsers">
        <div>
          <span class="dash__user-roundel">
            {{enabledUser.firstName?.charAt(0)}}{{enabledUser.lastName?.charAt(0)}}
          </span>
          {{enabledUser.firstName}} {{enabledUser.lastName}}
        </div>
        <div>
          {{enabledUser.email}}
        </div>
        <div>
          <span *ngIf="enabledUser.roles?.length">
            <span *ngFor="let role of enabledUser.roles; let i = index;" class="dash__role">
              {{role.name}}
              <span *ngIf="i < enabledUser.roles.length - 1">&nbsp;</span>
            </span>
          </span>
        </div>
        <div>
          {{formatDate(enabledUser.createdAt)}}
        </div>
        <div class="dash__access-row">
          <span *ngIf="enabledUser.sites?.length">
            <span *ngFor="let site of enabledUser.sites; let i = index;">
              {{site.name}}
              <span *ngIf="i < enabledUser.sites.length - 1">,</span>
            </span>
          </span>
        </div>
        <div class="dash__status-row dash__status-row--green">
          <span>
            {{enabledUser.status}}
          </span>
          <ion-icon name="ellipsis-vertical" [matMenuTriggerFor]="profileMenu" [matMenuTriggerData]="{element: enabledUser}"></ion-icon>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="awaitingUsers.length" class="dash__await-activation">
    <div class="dash__titles dash__titles--orange">
      <p>Waiting For User Activation</p>
      <p>User has not <span>activated</span> their account, resend <span>activation email</span> if the user has
        not yet received one
      </p>
    </div>
    <div class="dash__list-container dash__list-container--orange">
      <div class="dash__user-header">
        <div class="dash__current-users-header">Current users</div>
        <div>User email address</div>
        <div class="dash__role-header">Role</div>
        <div>Joined</div>
        <div>User site access</div>
        <div class="dash__status-header">Status</div>
      </div>
      <div class="dash__user-row" *ngFor="let awaitingUser of awaitingUsers">
        <div>
          <span class="dash__user-roundel">
            {{awaitingUser.firstName?.charAt(0)}}{{awaitingUser.lastName?.charAt(0)}}
          </span>
          {{awaitingUser.firstName}} {{awaitingUser.lastName}}
        </div>
        <div>
          <span class="dash__email">
            {{awaitingUser.email}}<br />
            <a>Invited - Not yet responded, send a reminder?</a>
          </span>
        </div>
        <div>
          <span *ngIf="awaitingUser.roles?.length">
            <span *ngFor="let role of awaitingUser.roles; let i = index;" class="dash__role">
              {{role.name}}
              <span *ngIf="i < awaitingUser.roles.length - 1">&nbsp;</span>
            </span>
          </span>
        </div>
        <div>
          {{formatDate(awaitingUser.createdAt)}}
        </div>
        <div class="dash__access-row">
          <span *ngIf="awaitingUser.sites?.length">
            <span *ngFor="let site of awaitingUser.sites; let i = index;">
              {{site.name}}
              <span *ngIf="i < awaitingUser.sites.length - 1">,</span>
            </span>
          </span>
        </div>
        <div class="dash__status-row dash__status-row--orange"><span>{{awaitingUser.status}}</span>
          <ion-icon name="ellipsis-vertical" [matMenuTriggerFor]="profileMenu" [matMenuTriggerData]="{element: awaitingUser}"></ion-icon>
        </div>
      </div>
    </div>
  </div>

  <mat-divider></mat-divider>
  <div *ngIf="disabledUsers.length" class="dash__disabled-users">
    <div class="dash__titles dash__titles--pink">
      <p>Disabled Users</p>
      <p>Disabling a user will <span>pause</span> their account until you are ready to enable. Disabled accounts
        will be deleted after a period of time.
      </p>
    </div>
    <div class="dash__list-container dash__list-container--pink">
      <div class="dash__user-header">
        <div class="dash__current-users-header">Current users</div>
        <div>User email address</div>
        <div class="dash__role-header">Role</div>
        <div>Joined</div>
        <div>User site access</div>
        <div class="dash__status-header">Status</div>
      </div>
      <div class="dash__user-row" *ngFor="let disabledUser of disabledUsers">
        <div>
          <span class="dash__user-roundel">
            {{disabledUser.firstName?.charAt(0)}}{{disabledUser.lastName?.charAt(0)}}
          </span>
          {{disabledUser.firstName}} {{disabledUser.lastName}}
        </div>
        <div>
          <span class="dash__email">
            {{disabledUser.email}}
          </span>
        </div>
        <div>
          <span *ngIf="disabledUser.roles?.length">
            <span *ngFor="let role of disabledUser.roles; let i = index;" class="dash__role">
              {{role.name}}
              <span *ngIf="i < disabledUser.roles.length - 1">&nbsp;</span>
            </span>
          </span>
        </div>
        <div>{{formatDate(disabledUser.createdAt)}}</div>
        <div class="dash__access-row">
          <span *ngIf="disabledUser.sites?.length">
            <span *ngFor="let site of disabledUser.sites; let i = index;">
              {{site.name}}
              <span *ngIf="i < disabledUser.sites.length - 1">,</span>
            </span>
          </span>
        </div>
        <div class="dash__status-row dash__status-row--pink">
          <span>
            {{disabledUser.status}}
          </span>
          <ion-icon name="ellipsis-vertical" [matMenuTriggerFor]="profileMenu"
            [matMenuTriggerData]="{element: disabledUser}"></ion-icon>
        </div>
      </div>
    </div>
  </div>
</div>
<mat-menu #profileMenu class="mat-form-field__menu">
  <ng-template matMenuContent let-aliasMenuItems="element">
    <button mat-menu-item (click)="toggleAdminSidebar($event, aliasMenuItems)">
      <ion-icon name="pencil"></ion-icon> Profile
    </button>
    <button mat-menu-item (click)="changeUserStatus(aliasMenuItems)">
      <ion-icon name="remove-circle"></ion-icon>{{aliasMenuItems.enabled?'Disable User':'Enable User'}}
    </button>
    <button mat-menu-item (click)="resetPassword(aliasMenuItems)" *ngIf="aliasMenuItems.enabled">
      <ion-icon name="refresh" class="dash__overlay-icon"></ion-icon>
      <ion-icon name="lock-closed" class="dash__underlay-icon"></ion-icon> Reset password
    </button>
  </ng-template>
</mat-menu>