import { Injectable } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

@Injectable({
  providedIn: 'root'
})
export class GroupAdminSidebarService {
  private groupAdminSidebar: MatSidenav;
  constructor() { }

  setSidenav(groupAdminSidebar: MatSidenav) {
    this.groupAdminSidebar = groupAdminSidebar;
  }

  open() {
    return this.groupAdminSidebar.open();
  }

  close() {
    return this.groupAdminSidebar.close();
  }

  toggle(): void {
    this.groupAdminSidebar.toggle();
  }
}


