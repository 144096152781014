<div class="toggleable-input" [class.toggleable-input--editable]="editable">
    <span class="toggleable-input__prefix" matPrefix *ngIf="prefixLabel">{{prefixLabel}}</span>
    <input 
        mat-input 
        class="toggleable-input__input"
        [class.toggleable-input__input--align-right]="prefixLabel"
        [(ngModel)]="internalValue"
        (blur)="checkValue()"
        [readonly]="!editable"
        [min]="min"
        [max]="max"
        [type]="editable ? type : 'text'"
        [step]="step"
    />
    <button
        class="toggleable-input__edit-btn mat-flat-button mat-mdc-unelevated-button__plain"
        *ngIf="toggleable"
        mat-button
        [matTooltip]="editable ? 'Clear field' : 'Edit field'"
        [disabled]="disabled"
        (click)="editable ? clear() : toggleEditable(true)"
    >
        <ion-icon 
            [name]="editable ? 'close-circle' : 'pencil'"
            [color]="editable ? 'medium' : 'dark'"
        ></ion-icon>
    </button>
    <span class="toggleable-input__suffix" matSuffix *ngIf="suffixLabel">{{suffixLabel}}</span>
    <!-- widget -->
    <div *ngIf="editable" class="toggleable-input__widget">
        <button
            class="toggleable-input__close-btn"
            mat-flat-button
            matTooltip="Cancel"
            (click)="cancel()"
        >
            <ion-icon 
                [name]="'close-circle'"
            ></ion-icon>
        </button>
        <button
            class="toggleable-input__confirm-btn"
            mat-flat-button
            matTooltip="Confirm"
            (click)="confirm()"
        >
            <ion-icon 
                [name]="'checkmark-circle'"
                [color]="'primary'"
            ></ion-icon>
        </button>
    </div>
</div>