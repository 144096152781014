import { KeycloakService } from 'keycloak-angular';
import { environment } from '../../../environments/environment';

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function keycloakInitializer(keycloak: KeycloakService): () => Promise<void> {
  return async (): Promise<void> => {
    const env = await environment();
    const keycloakConfig = { clientId: env.keycloakClientId, realm: env.keycloakRealm, url: env.keycloakUrl };
    await keycloak.init({
      config: keycloakConfig,
      initOptions: {
        onLoad: 'check-sso',
        checkLoginIframe: false
      },
      enableBearerInterceptor: true,
      bearerPrefix: 'Bearer',
      bearerExcludedUrls: []
    });
  };
}
