import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, of } from 'rxjs';
import { UtilService } from './util.service';

export interface EnvironmentConfig {
  apiUrl: string;
  baseUrl: string;
  keycloakClientId: string;
  keycloakRealm: string;
  keycloakUrl: string;
  ngrxLogging?: boolean;
  production: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class EnvironmentConfigurationService {
  private static config: EnvironmentConfig;
  private static fetched = false;
  private static fetching = false;
  private static instance = null;

  constructor(private httpService: HttpClient, private utilService: UtilService) {
    EnvironmentConfigurationService.instance = this;
  }

  static getInstance(): EnvironmentConfigurationService {
    return EnvironmentConfigurationService.instance;
  }

  async configExists(): Promise<boolean> {
    return this.utilService.waitUntilResolved(() => EnvironmentConfigurationService.config);
  }

  getConfig(prop: string): string {
    return EnvironmentConfigurationService.config[prop] || '';
  }

  setConfig(): void {
    if (EnvironmentConfigurationService.fetching || EnvironmentConfigurationService.fetched) {
      return;
    }

    EnvironmentConfigurationService.fetching = true;
    this.httpService
      .get('assets/config/config.json')
      .pipe(
        catchError((error: any) => {
          console.log('Encountered error fetching environment configuration', error);
          return of(null);
        })
      )
      .subscribe((envString: string) => {
        EnvironmentConfigurationService.config = this.utilService.decodeBase64String(envString);
        EnvironmentConfigurationService.fetching = false;
        EnvironmentConfigurationService.fetched = true;
      });
  }
}
