import dayjs from 'dayjs';
import { Part } from '../../parts/models/part.model';
import { Quote } from '../../quotes/models/quote.model';

export class Order {
  _id: string;
  centreCode: string;
  customerOrderReferenceNumber?: string;
  orderedAt?: dayjs.Dayjs;
  orderInstruction?: string;
  orderNumber?: string;
  parts: Part[];
  partsDelayed?: boolean;
  partsNotOrdered?: Part[];
  quote: Quote;
  status: string;
  updatedAt: dayjs.Dayjs;
  part?: Part;
}

export enum OrderStatus {
  NEW = 'NEW',
  CANCELLED = 'CANCELLED',
  SHIPPED = 'SHIPPED',
  AWAITING_FULFILMENT = 'AWAITING FULFILMENT',
  FULFILLED = 'FULFILLED',
  ALL = 'ALL',
  DELAYED = 'DELAYED'
}

export enum OrderInstruction {
  QUOTE = 'Quote Only',
  QUOTE_ORDER = 'Quote and Order',
  QUOTE_AMEND_ORDER = 'Quote Amend then Order'
}
