import { Dayjs } from 'dayjs';
import { Address } from './address.model';
import { Part } from '../../parts/models/part.model';
import { Provider } from './provider.model';
import { Repairer } from './repairer.model';
import { Vehicle } from './vehicle.model';

/**
 * Quote model
 *
 * @export
 * @class Quote
 *
 */
export class Quote {
  _id: string;
  address: Address;
  assessment?: IAssessment;
  centreCode: string;
  convertedDate?: Dayjs;
  convertedBy?: string;
  createdAt: Dayjs;
  createdBy: string;
  createdDate: Dayjs;
  locked?: boolean;
  parts: Part[] = [];
  quoteAction?: QuoteAction = QuoteAction.OTHER;
  quotedBy: string;
  quotedDate: Dayjs;
  quoteNumber: string;
  quoteRequestId?: string; // Used to be called Estimate
  quoteScheme?: QuoteScheme = QuoteScheme.GENERAL;
  quoteStatus?: QuoteStatus = QuoteStatus.NEW;
  recreatedAt?: Dayjs;
  removedParts?: Part[] = [];
  repairer?: Repairer;
  submittedAt?: Dayjs;
  submittedBy?: string;
  tlaSupportValue?: number;
  updatedAt?: Dayjs;
  updatedBy?: string;
  vehicle: Vehicle;
  workProvider: Provider;
}

export interface IAssessment {
  bottomLineDiscount: number;
  preAccidentValue: number;
  totalLossPercentage: number;
  totalNonPartCost: number;
  workProviderDiscount: number;
}

export enum QuoteScheme {
  APP = 'APP',
  TLA = 'TLA',
  GENERAL = 'GEN',
  TL = 'TL'
}

export enum QuoteAction {
  ACCEPT = 'ACCEPT',
  REJECT = 'REJECT',
  OTHER = 'OTHER'
}

export enum QuoteStatus {
  ALL = 'ALL',
  NEW = 'NEW',
  ADDITIONAL = 'ADDITIONAL',
  IN_PROGRESS = 'IN PROGRESS',
  SUBMITTED = 'SUBMITTED',
  REJECTED = 'REJECTED'
}
