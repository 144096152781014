import { Component, Input } from '@angular/core';
import { EScheme } from '../../../parts/models/parts-list.model';

@Component({
  selector: 'app-order-type-badge',
  templateUrl: './order-type-badge.component.html',
  styleUrls: ['./order-type-badge.component.scss']
})
export class OrderTypeBadgeComponent {
  @Input() scheme: EScheme = EScheme.TLA;

  constructor() { }

}
