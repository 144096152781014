import { Component, Output, Input, EventEmitter } from '@angular/core';
@Component({
  selector: 'app-management-sidebar',
  templateUrl: './management-sidebar.component.html',
  styleUrls: ['./management-sidebar.component.scss']
})
export class ManagementSidebarComponent {
  @Output() contentType: EventEmitter<string> = new EventEmitter();
  @Input() hasNav = true;

  constructor() {}

  navigateTo(event: MouseEvent, contentType: string) {
    event.stopPropagation();
    this.contentType.emit(contentType);
  }
}
