import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RootState } from './core.reducers';

export const selectRoot = createFeatureSelector<RootState>('root');

export const selectSideNavOpen = createSelector(selectRoot, (rootState: RootState) => rootState.sideNavOpen);

export const selectUserProfile = createSelector(selectRoot, (rootState: RootState) => rootState.userProfile);

export const selectIsGroupUser = createSelector(selectRoot, (rootState: RootState) => rootState.isGroupUser);

export const selectUsersList =  createSelector(selectRoot, (rootState: RootState) => rootState.usersList);


export const selectLoggedInUser = createSelector(selectRoot, (rootState: RootState) => rootState.loggedInUser);

export const selectKeyCloakInstance = createSelector(selectRoot, (rootState: RootState) => rootState.keyCloakInstance);
