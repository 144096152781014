import { createAction, props } from '@ngrx/store';
import { User } from '../models/user.model';

/** Unhandled action */
export const authRequired = createAction('[App/Auth] Auth Required');
/** Unhandled action */

export const loadAllUsers = createAction(
    '[User List Resolver] Load All Users'
);

export const loadAllUsersSuccess = createAction(
    '[Load Users Effect] All Users Loaded',
    props<{ users: User[] }>()
);

export const loadUser = createAction(
    '[User Details] Load A User', props<{ id: string }>()
);

export const loadUserSuccess = createAction(
    '[Load A User Effect] A User Loaded',
    props<{ user: User }>()
);

export const updateUser = createAction(
    '[Edit User] User Updated',
    props<{ userId: string; userUpdate: User }>()
);

export const updateUserSuccess = createAction(
    '[User Update Effect] Update user then load the user',
    props<{ user: User }>()
);

export const createUser = createAction(
    '[Create User] User Created',
    props<{ userCreate: User }>()
);

export const createUserSuccess = createAction(
    '[User Create Effect] Create user then load the user',
    props<{ user: User }>()
);

export const createUserFailed = createAction(
    '[User Create Failed Effect] Create user failed'
);

export const resetUserPassword = createAction(
    '[Reset User Password] Reset Password Email',
    props<{ userId: string }>()
);

export const resetUserPasswordSuccess = createAction(
    '[Reset User Password Effect] Reset Password Email',
    props<{ user?: User }>()
);

export const changeUserStatus = createAction(
    '[Change User Status] User Updated',
    props<{ userId: string }>()
);

export const changeUserStatusSuccess = createAction(
    '[Change User Status Effect] Change user status then load the user',
    props<{ user: User }>()
);

export const selectedUser = createAction(
    '[User] Selected User', props<{ user: User }>()
);

export const setIsCreateUser = createAction(
    '[User] Setting isCreateUser', props<{ createUser: boolean }>()
);
