import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import dayjs from 'dayjs';
import { NotificationsService } from '../notifications.service';
import relativeTime from 'dayjs/plugin/relativeTime';
import isBetween from 'dayjs/plugin/isBetween';
import { EStateType, INotification } from '../notification-cards/notification-cards.model';
import { ReplaySubject, takeUntil } from 'rxjs';

dayjs.extend(relativeTime);
dayjs.extend(isBetween);

export interface IListDates {
  title: string | boolean;
  filterDate: dayjs.Dayjs;
  month: string;
  data: INotification[];
  open?: boolean;
};

@Component({
  selector: 'app-notification-filter-list',
  templateUrl: './notification-filter-list.component.html',
  styleUrls: ['./notification-filter-list.component.scss']
})
export class NotificationFilterListComponent implements OnInit, OnDestroy, OnChanges {

  @Input() openDrawer = true;
  @Input() lockDrawer = true;
  @Input() notifications: INotification[] = [];
  @Input() notificationDays: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 14, 21, 28];

  currentDate = dayjs();
  listDates: IListDates[] = [];
  viewed = false;

  private onDestroy$ = new ReplaySubject<boolean>(1);

  constructor(private readonly notificationService: NotificationsService) { }

  updateListDates() {
    this.listDates = [];
    let data: INotification[] = [];
    if((this.notifications || []).length > 0) {
      this.notificationDays.map((val: number) => {
        const title = this.currentDate.subtract(val, 'day').from(this.currentDate);
        const filterDate = this.currentDate.subtract(val, 'day');

        data = this.notifications.filter(notification => {
          const createdDate = notification.createdAt.format('YYYY-MM-DD');
          return val <= 7 ? createdDate === filterDate.format('YYYY-MM-DD')
            : createdDate >= filterDate.format('YYYY-MM-DD')
              && createdDate < filterDate.add(7, 'day').format('YYYY-MM-DD');
        });

        const setAgeing = (age: number): string | any => {
          let ageing: string;
          if (age <= 7) {
            ageing = EStateType.WEEKONE;
          }
          if (age > 7 && age <= 14) {
            ageing = EStateType.WEEKTWO;
          }
          if (val >= 21) {
            ageing = EStateType.ANCIENT;
          }

          data.forEach((d: INotification) => {
            d.ageing = ageing;
          });
        };

        setAgeing(val);

        const formatTitle = (date: number): string => {
          switch (date) {
            case 0:
              return 'Today';
            case 1:
              return 'Yesterday';
            case 14:
              return '2 week ago';
            case 21:
              return '3 weeks ago';
            case 28:
              return '1 month ago';
            default:
              return title as unknown as string;
          }
        };

        const getMonth = (month: number) => this.currentDate.subtract(month, 'days').format('MMMM');
        const setOpen = (notification: INotification[], date: number): boolean =>
          notification.some(item => item && notification.length > 0) && date < 7;

        this.listDates.push({
          title: formatTitle(val),
          filterDate,
          month: getMonth(val),
          data,
          open: setOpen(data, val),
        });
      });
    }
  }

  dismissGroup(event: MouseEvent, i: number): void {
    event.stopPropagation();
    const group = this.listDates[i].data.map(d => d._id);
    this.notificationService.dismissNotification(group);
  }

  ngOnInit() {
    this.notificationService.notifications$.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.updateListDates();
    });

    this.notificationService.dismissNotificatons$.pipe(takeUntil(this.onDestroy$)).subscribe(
      ids => {
        this.listDates = []; // resets listDates to empty array
        const newNotifications: INotification[] | INotification = this.notifications.filter(obj => ids.includes(obj._id) === false);
        this.notifications = newNotifications;
        this.notificationService.updateNotifications(this.notifications);
        this.updateListDates();
      }
    );
  }

  ngOnChanges(changes: SimpleChanges) {

    const notificationChanged = changes['notifications'];
    if (notificationChanged) {
      this.notificationService.updateNotifications(notificationChanged.currentValue);
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

}
