import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, filter, fromEvent, map, ReplaySubject, startWith, takeUntil } from 'rxjs';
import { GroupAdminSidebarService } from '../../core/services/group-admin-sidebar.service';
import { loadAllGroups } from '../groupsStore/groups.actions';
import { AppState } from '../../quotes/store/reducers';
import { Store, select } from '@ngrx/store';
import * as groupSelector from '../groupsStore/groups.selectors';
import * as coreSelector from '../../core/store/core.selectors';
import { VirtualGroupDetail } from '../models/virtualGroup.model';
import { DrawerService } from '../../core/services/drawer.service';
import { EDrawContent } from '../../core/components/lay-out/lay-out.component';
@Component({
  selector: 'app-management-group-dashboard',
  templateUrl: './management-group-dashboard.component.html',
  styleUrls: ['./management-group-dashboard.component.scss']
})
export class ManagementGroupDashboardComponent implements OnInit, OnDestroy {
  public hasScrolled$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public routerLink = '';
  public title = '';
  enabledGroups: VirtualGroupDetail[] = [];
  awaitingGroups: VirtualGroupDetail[] = [];
  groupMenu: string[] = [];
  selectedGroup: string;
  searchInput: string;
  private enabledTable: VirtualGroupDetail[] = [];
  private awaitingTable: VirtualGroupDetail[] = [];
  private onDestroy$: ReplaySubject<boolean> = new ReplaySubject(1);


  constructor(
    private store: Store<AppState>,
    private groupAdminSidebar: GroupAdminSidebarService,
    private drawerService: DrawerService,
  ) {
    this.selectedGroup = '';
    this.searchInput = '';
  }

  ngOnInit(): void {
    this.title = '/ Management-groups';
    this.routerLink = '/quotes/new';

    const content = document.getElementsByClassName('mat-drawer-content')[0];
    fromEvent<any>(content, 'scroll').pipe(
      map(() => content.scrollTop > 0),
      distinctUntilChanged(),
      startWith(content.scrollTop > 0),
      takeUntil(this.onDestroy$)
    ).subscribe(this.hasScrolled$);

    this.store.pipe(
      select(groupSelector.selectedGroup),
      filter(data => !!data)
    ).subscribe((virtualGroupDetail: VirtualGroupDetail) => {
      if (virtualGroupDetail) {
        if (!this.groupMenu.includes(virtualGroupDetail.name)) {
          this.groupMenu.push(virtualGroupDetail.name);
        }
      }
    });

    this.store.pipe(select(coreSelector.selectLoggedInUser)).subscribe(loggedInUser => {
      if (loggedInUser) {
        loggedInUser.virtualGroups?.map(vg => this.groupMenu.push(vg.name));
      }
    });

    this.store.dispatch(loadAllGroups());
    this.getGroupsList();
  }
  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  toggleGroupAdminSidebar(event: MouseEvent): void {
    event.stopPropagation();
    this.drawerService.toggleGroup({open: true, content: EDrawContent.GROUP_MANAGEMENT});
  }

  searchVirtualGroup(searchInput: string) {
    this.searchGroups(this.selectedGroup, searchInput);
  }

  onGroupChange(selectedVG: string): void {
    this.searchGroups(selectedVG, this.searchInput);
  }


  private getGroupsList(): void {
    this.store.pipe(select(groupSelector.selectAllGroups)).subscribe(virtualGroupsDetail => {
      if (virtualGroupsDetail?.length > 0) {
        this.enabledTable = [];
        this.awaitingTable = [];
        virtualGroupsDetail.forEach((vg: VirtualGroupDetail) => {
          if (vg.siteCount > 0) {
          this.enabledTable.push(vg);
          this.enabledGroups = this.enabledTable;
          } else {
            this.awaitingTable.push(vg);
            this.awaitingGroups = this.awaitingTable;
          }
        });
      };
    });
  }

  private searchGroups(selectedVG: string, searchInput: string) {
    this.resetSearch();
    if (selectedVG !== 'All') {
      this.enabledGroups = this.filterGroups(this.enabledGroups, selectedVG);
      this.awaitingGroups = this.filterGroups(this.awaitingGroups, selectedVG);
    }
    if (searchInput.length !== 0) {
      this.enabledGroups = this.filterGroups(this.enabledGroups, searchInput);
      this.awaitingGroups = this.filterGroups(this.awaitingGroups, searchInput);
    }
  }

  private filterGroups(groups: VirtualGroupDetail[], query: string): VirtualGroupDetail[] {
    return groups.filter(vg => vg.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }

  private resetSearch(): void {
    this.enabledGroups = this.enabledTable;
    this.awaitingGroups = this.awaitingTable;
  }
}
