import { APP_BASE_HREF } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorMessageComponent } from './core/components/error-message/error-message.component';
import { LayOutComponent } from './core/components/lay-out/lay-out.component';
import { NotFoundPageComponent } from './core/components/not-found-page/not-found-page.component';
import { PrivacyComponent } from './core/components/privacy/privacy.component';
import { UnderMaintenancePageComponent } from './core/components/under-maintenance-page/under-maintenance-page.component';
import { CustomKeycloakAuthGuard } from './core/services/custom-keyclock-auth.service';
import { CustomPreloadStrategy } from './core/strategies/custom-preload.strategy';
import { ManagementGroupDashboardComponent } from './management/management-group-dashboard/management-group-dashboard.component';

const msieMatch = navigator.userAgent.search(/(?:MSIE|Trident\/.*; rv:)/);

let route = 'quotes/new';

if (msieMatch !== -1) {
  route = '';
}

const routes: Routes = [
  {
    path: '',
    component: LayOutComponent,
    children: [
      { path: '', redirectTo: route, pathMatch: 'full' },
      {
        path: 'quotes',
        loadChildren: () => import('./quotes/quotes.module').then((m) => m.QuotesModule),
        canActivate: [CustomKeycloakAuthGuard]
      },
      {
        path: 'orders',
        loadChildren: () => import('./orders/orders.module').then((m) => m.OrdersModule),
        canActivate: [CustomKeycloakAuthGuard]
      },
      {
        path: 'cheatsheet',
        loadChildren: () => import('./cheatsheet/cheatsheet.module').then((m) => m.CheatsheetModule),
        canActivate: [CustomKeycloakAuthGuard]
      },
      {
        path: 'management',
        loadChildren: () => import('./management/management.module').then((m) => m.ManagementModule),
        canActivate: [CustomKeycloakAuthGuard]
      },
      {
        path: 'management-groups',
        component: ManagementGroupDashboardComponent,
        canActivate: [CustomKeycloakAuthGuard]
      },
      { path: 'privacy', component: PrivacyComponent }
    ]
  },
  { path: 'under-maintenance', component: UnderMaintenancePageComponent },
  { path: '404', component: NotFoundPageComponent },
  { path: '403', component: ErrorMessageComponent },
  { path: '500', component: ErrorMessageComponent },
  { path: '503', component: ErrorMessageComponent },
  { path: '504', component: ErrorMessageComponent },
  { path: '**', component: NotFoundPageComponent }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      preloadingStrategy: CustomPreloadStrategy
    })
  ],
  exports: [RouterModule],
  providers: [CustomKeycloakAuthGuard, CustomPreloadStrategy, { provide: APP_BASE_HREF, useValue: '/' }]
})
export class AppRoutingModule {}
