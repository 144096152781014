<form [formGroup]="groupForm">
    <div class="group-form">
        <p class="group-form__title">Let's create a new group and add site to the group</p>
        <div class="group-form__formInput">
            <div class="group-form__formInput__label">
                <label for="group-name">Group name -
                    <span class="group-form__formInput__label__required">(required)</span>
                </label>
                <ion-icon class="group-form__formInput__label__icon" name="information-circle"></ion-icon>
            </div>
            <mat-form-field title="Group-name"
                class="mat-form-field__modern mat-form-field__standard mat-form-field__standard--error mat-form-field__primary"
                appearance="outline">
                <input #groupName name="groupName" formControlName="groupName" (blur)="checkGroupNameExists()" matInput
                type="text" maxlength="50">
                <mat-error class="has-text-danger" *ngIf="groupExists && form.groupName.dirty">This group already exists</mat-error>
                <mat-error class="has-text-danger" *ngIf="form.groupName.invalid && form.groupName.dirty && !groupExists">This group name is invalid</mat-error>
            </mat-form-field>
        </div>

        <div class="group-form__formInput">
            <div class="group-form__formInput__label">
                <label for="group-email">Group email address -
                    <span class="group-form__formInput__label__required">(required)</span>
                </label>
                <ion-icon class="group-form__formInput__label__icon" name="information-circle"></ion-icon>
            </div>
            <mat-form-field title="Group-email"
                class="mat-form-field__modern mat-form-field__standard mat-form-field__standard--error mat-form-field__primary"
                appearance="outline">
                <input #groupEmail name="groupEmail" formControlName="groupEmail" matInput type="email" maxlength="50">
                <mat-error class="has-text-danger" *ngIf="form.groupEmail.invalid && form.groupEmail.dirty">This group email is invalid</mat-error>
            </mat-form-field>
        </div>

        <div class="group-form__textBlock">
            <div class="group-form__textBlock__titles">
                <span>
                    Enable this email address to be used for group notifications
                </span>
            </div>
            <div class="group-form__textBlock__text">
                <span>All sites associated to this group will receive
                    <span class="group-form__textBlock__text__emphasis">group emails.</span> And we will use the
                    <span class="group-form__textBlock__text__emphasis">email address</span> provided above.
                </span>
            </div>
        </div>

        <div class="group-form__email">
            <div class="group-form__email__text">
                <span>Enable<span class="group_form_email__text__emphasis"> Group Email</span> for all sites
                    associated</span>
            </div>
            <div class="group-form__email__toggle">
                <mat-slide-toggle ngDefaultControl name="enableGroupEmail" formControlName="enableGroupEmail" class="group-form__email__toggle__slide" [checked]="groupForm.controls.enableGroupEmail.value"
                    (change)="toggleEnableGroupEmail($event)">
                    {{groupForm.controls.enableGroupEmail.value===true?'Yes':'No'}}
                </mat-slide-toggle>
            </div>
        </div>

        <div class="group-form__learn_more">
            <div class="group-form__learn_more__text">Want to know more about Group-Emails</div>
            <div class="group-form__learn_more__link">
                <div class="group-form__learn_more__link__text">Learn more</div>
            </div>
        </div>

        <mat-divider role="separator" color="primary" class="mat-divider mat-divider__basicblack mat-divider-horizontal"
            aria-orientation="horizontal"></mat-divider>

        <div class="group-form__add-cta">
            <div class="group-form__add-cta__label">Now add a site to the group or you can do this later!</div>
            <button class="group-form__add-cta__button" *ngIf="!showSearch"  [ngClass]="{
                'group-form__add-cta__showSearch': disableSites === true}" (click)="openSitesDiv($event)">
                <div class="group-form__add-cta__button__text"  [ngClass]="{
                    'group-form__add-cta__showSearch': disableSites === true}">Add an existing site to the group</div>
                <div class="group-form__add-cta__button__icon">
                    <ion-icon class="group-form__add-cta__button__icon__style" name="close"></ion-icon>
                </div>
            </button>
            <mat-form-field class="mat-form-field__search_groups" appearance="outline" *ngIf="showSearch">
                <ion-icon name="Search"></ion-icon>
                <input matInput placeholder="Search for sites" (keyup)="searchSiteTool($event)">
            </mat-form-field>
            <div class="group-form__site-list" *ngIf="showSearch">
                <div class="group-form__chips group-form__chips--add" [ngClass]="{
                    'group-form__add-site': site.keep}" formArrayName="sites"
                    *ngFor="let site of sites">
                    <div>{{site.name}}<span>{{site.centreCode}}</span></div>
                    <ion-icon *ngIf="!site.hasOwnProperty('keep') || !site.keep"
                        class="group-form__chips__icon" name="add" (click)="siteSelectionChange($event,site,false)"></ion-icon>
                    <ion-icon *ngIf="site.hasOwnProperty('keep') && site.keep"
                        (click)="siteSelectionChange($event,site,true)" name="close-circle"></ion-icon>
                </div>
            </div>
        </div>
        <div class="group-form__confirmation">
            <p class="group-form__title">Once the group is created and sites added to the new group you will be able to
                add
                users against the group and sites</p>
            <p>Please confirm to create group</p>
            <button mat-flat-button class="mat-mdc-unelevated-button__primary" [disabled]="groupForm.invalid" (click)="createGroup()">Confirm to create
                group</button>
        </div>
    </div>
</form>