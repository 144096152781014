import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.scss']
})
export class ErrorMessageComponent implements OnInit {
  header = '';
  firstLine = '';
  errorType = '';

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.errorType = params.errorType;
    });
    switch (this.errorType) {
      case '500':
        this.header = 'Oops, this is unexpected…';
        this.firstLine = 'Internal server error, please contact the administrator.';
        break;
      case '403':
        this.header = 'You\'re not permitted to see this.';
        this.firstLine = 'The page you are trying to access has restricted access.';
        break;
      case '503':
        this.header = 'Oops, service unavailable…';
        this.firstLine = 'The server is currently unavailable, please come back later.';
        break;
      case '504':
        this.header = 'Oops, service unavailable…';
        this.firstLine = 'The server is currently unavailable, please come back later.';
        break;
      default:
        this.header = 'Oops, this is unexpected…';
        this.firstLine = 'Internal server error, please contact the administrator.';
        break;
    }
  }
}
