import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IError } from '../models/errors.model';

@Injectable({
  providedIn: 'root'
})
export class ErrorsService {
  public readonly errors: IError[] = [];
  public readonly errors$ = new BehaviorSubject<IError[]>([]);

  constructor() {}

  appendError(error: IError): void {
    // check if exists first
    const foundErrorById = this.getError(error._id); // returns entry based on _id, NOT exact error
    if (foundErrorById) {
      // check if field already exists
      const entries = Object.entries(error.errors);
      entries.forEach(([field, values]) => {
        values.forEach((v) => {
          if (foundErrorById.errors[field] && foundErrorById.errors[field].includes(v)) {
            // if already includes error
            return;
          } else {
            // check if exists
            if (foundErrorById.errors[field]) {
              foundErrorById.errors[field].push(v);
            } else {
              foundErrorById.errors[field] = [v];
            }
            this.errors$.next(this.errors);
          }
        });
      });
    } else {
      this.errors.push(error);
      this.errors$.next(this.errors);
    }
  }

  createError(_id: string, field: string, msgs: string | string[], removed = false): IError {
    const error: IError = {
      _id,
      errors: {},
      removed
    };
    error.errors[field] = Array.isArray(msgs) ? msgs : [msgs];
    return error;
  }

  removeError(error: IError): void {
    // check if exists first
    const foundErrorById = this.getError(error._id); // returns entry based on _id, NOT exact error
    if (foundErrorById) {
      // check if field already exists
      const entries = Object.entries(error.errors);
      entries.forEach(([field, values]) => {
        values.forEach((v) => {
          if (foundErrorById.errors[field]) {
            const index = foundErrorById.errors[field].indexOf(v);
            if (index > -1) {
              foundErrorById.errors[field].splice(index, 1);
              // prune empty object properties
              if (foundErrorById.errors[field].length === 0) {
                delete foundErrorById.errors[field];
              }
              this.errors$.next(this.errors);
            }
          }
        });
      });

      // remove from errors if no errors
      if (Object.keys(foundErrorById.errors).length === 0) {
        const index = this.errors.indexOf(foundErrorById);
        if (index > -1) {
          this.errors.splice(index, 1);
          this.errors$.next(this.errors);
        }
      }
    }
  }

  getError(_id: string): IError | undefined {
    return this.errors.find((e) => e._id === _id); // _id in this instance is "uniquePartId"
  }

  // set removed from true to false & vice-versa
  toggleRemoved(error: IError, removed?: boolean): void {
    // check if exists first
    const foundErrorById = this.getError(error._id); // returns entry based on _id, NOT exact error
    if (foundErrorById) {
      foundErrorById.removed = removed ? removed : !foundErrorById.removed;
      this.errors$.next(this.errors);
    }
  }
}
