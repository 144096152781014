<ion-icon
  #pencilIcon
  *ngIf="!showingForm"
  name="pencil"
  class="icon-dialog-top"
  matTooltip="Edit this form"
  (click)="editing = true; checkDisplayForm()"
></ion-icon>


<div class="item-delay" [ngClass]="{ editing: showingForm }">
  <div *ngIf="!showingForm" class="delay-info">
    <div class="item-delay__text">
      <span>{{ data.quantity > 1 ? 'Items Delayed (' + data.quantity + ')' : 'Item Delayed' }}</span>
    </div>
    <span>Date and reason for the delay</span>
    <div class="item-delay__text top-margin direction-row">
      <ion-icon name="calendar-clear"></ion-icon>
      <span class="left-margin">{{ (data.delayedUntil | date:'EEEE, MMMM d, y') || 'No ETA'}}</span>
    </div>
    <div class="item-delay__text top-margin">
      <span>Reported by</span>
      <span>{{ data.delayedBy || data.updatedBy }}</span>
    </div>
    <div class="item-delay__text">
      <span>Reason for the delay</span>
      <span>{{ data.delayReason }}</span>
    </div>
    <div class="item-delay__button">
      <button mat-stroked-button class="mat-stroked-button__secondary" (click)="onCancel()">Close</button>
      <button *ngIf="false" mat-flat-button class="mat-mdc-unelevated-button__secondary" [disabled]="!data.delayedAt">Backorder history</button>
    </div>
  </div>

  <form *ngIf="showingForm" [formGroup]="itemDelayForm">
    <div class="item-delay__body">
      <div class="item-delay__text">
        <span>Item Delayed</span>
        <span>We need to capture an ETA date</span>
      </div>
      <div class="item-delay__text">
        <span>Do we have an ETA for this item?</span>
        <div class="item-delay__radio" class="radio-custom" >
              <lib-radio-button
              *ngFor="let value of radioButtonValues"
              [label]="value"
              [isChecked]="value === 'Yes' ? yesSelected : noSelected"
              (checked)="setValue(value)"
              [hasBorder]="true"
          ></lib-radio-button>
        </div>
      </div>
      <div class="item-delay__text">
        <span>Please set an ETA date below</span>
        <div class="item-delay__datepicker-container">
          <mat-form-field class="mat-mdc-form-field__datepicker mat-form-field__primary" appearance="outline">
              <input matInput [matDatepicker]="picker" formControlName="delayedUntil" [min]="minDate" placeholder="Select dates" required>
              <mat-datepicker-toggle class="datePicker-custom" matSuffix [for]="picker" ngDefaultControl>
                <ion-icon name="chevron-down-outline" matDatepickerToggleIcon></ion-icon>
              </mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="item-delay__text">
        <span>Reason for the delay</span>
        <mat-form-field  class="mat-form-field__textarea mat-form-field__primary" appearance="outline">
          <textarea matInput rows="5" formControlName="delayReason" placeholder="Please type reason here for delaying the item"></textarea>
        </mat-form-field>
      </div>
      <div class="item-delay__button">
        <button mat-stroked-button class="mat-stroked-button__secondary" (click)="onCancel()">Cancel</button>
        <button mat-flat-button class="mat-mdc-unelevated-button__secondary" (click)="confirm()" [disabled]="itemDelayForm.invalid">Confirm ETA & Delay</button>
      </div>
    </div>
  </form>
</div>