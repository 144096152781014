export class VirtualGroup {
  _id?: string;
  centreCodes: Array<string>;
  checked?: boolean;
  email?: string;
  emailPreferences?: Array<string>;
  enableGroupEmail: boolean;
  name: string;
  visible: boolean;

  constructor() {
    this.name = null;
    this.email = null;
    this.centreCodes = [];
    this.emailPreferences = [];
    this.enableGroupEmail = false;
    this.visible = false;
  }
}

export class VirtualGroupDetail {
    _id?: string;
    name: string;
    email?: string;
    centreCodes: Array<string>;
    emailPreferences?: Array<string>;
    enableGroupEmail: boolean;
    visible: boolean;
    createdBy: string;
    updatedBy: string;
    userCount: number;
    siteCount: number;

    constructor(virtualGroupDetail?: Partial<VirtualGroupDetail>) {
        this.name = virtualGroupDetail?.name || null;
        this.email = virtualGroupDetail?.email || null;
        this.centreCodes = virtualGroupDetail?.centreCodes || [];
        this.emailPreferences = virtualGroupDetail?.emailPreferences || [];
        this.enableGroupEmail = virtualGroupDetail?.enableGroupEmail || false;
        this.visible = virtualGroupDetail?.visible || false;
        this.createdBy = virtualGroupDetail?.createdBy || null;
        this.updatedBy = virtualGroupDetail?.updatedBy || null;
        this.userCount = virtualGroupDetail?.userCount || 0;
        this.siteCount = virtualGroupDetail?.siteCount || 0;
    }
}
