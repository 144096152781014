import { routerReducer } from '@ngrx/router-store';
import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from '../../../../environments/environment';
import { EnvironmentConfig } from '../../../shared/services/environment-configuration.service';

let env = null;
environment().then((theEnv: EnvironmentConfig) => (env = theEnv));

export interface AppState {}

export const reducers: ActionReducerMap<AppState> = {
  router: routerReducer
};

export function logger(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    if (env.ngrxLogging) {
      console.log('state before:', state);
      console.log('action:', action);
    }

    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<AppState>[] = !env?.production ? [logger] : [];
