
//global func to test values
/**
 * @param _enum {any}
 * @param enumValue {string}
 */
export const checkEnumMatch = (_enum: any, enumValue: string): boolean => Object.values(_enum).includes(enumValue);


/**
 * @param _enum {any}
 * @param enumValue {string}
 */
export const assignEnum = (_enum: any, enumValue: string): any => {
    for (const key in _enum) {
        if (_enum[key] === enumValue) {
            return key;
        }
    }
};

/**
 * @param value1 {any}
 * @param value2 {any}
 */
export const checkValues = (value1: any, value2: any): boolean => {
    if (value1 !== undefined && value2 === undefined) {
        return true;
    } else if (value1 && value2 !== undefined) {
        return true;
    } else {
        return false;
    }
};
