/* eslint-disable @typescript-eslint/naming-convention */

import { Component, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { NotificationsService } from '../../../notifications/notifications.service';
import { UserService } from '../../../user.service';
import { sideNavToggle } from '../../store/core.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../../../quotes/store/reducers';
import { OrderSidebarService } from '../../services/order-sidebar.service';
import dayjs, { Dayjs } from 'dayjs';
import { KeycloakProfile, KeycloakTokenParsed } from 'keycloak-js';
import { Router } from '@angular/router';
import { DrawerService } from '../../services/drawer.service';
import { NavigationService } from '@autoflow/angular-ux-components';
import { KeycloakService } from 'keycloak-angular';
import { setIsCreateUser } from '../../../management/store/users.actions';
import { BehaviorSubject } from 'rxjs';
import { QuotesService } from '../../../quotes/services/quotes.service';
import { EnvironmentConfigurationService } from '../../../shared/services/environment-configuration.service';

export enum EDrawContent {
  USER_PROFILE = 'USER_PROFILE',
  NOTIFICATIONS = 'NOTIFICATIONS',
  USER_MANAGEMENT = 'USER_MANAGEMENT',
  GROUP_MANAGEMENT = 'GROUP_MANAGEMENT',
  ORDERS = 'ORDERS'
}

@Component({
  selector: 'app-lay-out',
  templateUrl: './lay-out.component.html',
  styleUrls: ['./lay-out.component.scss']
})
export class LayOutComponent implements OnInit, OnDestroy, OnChanges {
  savedUrlPath = '';
  openDrawer = false;
  hasNav = true;
  navigation = [];
  centreCodes: KeycloakTokenParsed[];
  centreNames: string[];
  currentUser: string;
  userProfile: KeycloakProfile;
  isAdminUser = false;
  currentDate: Dayjs = dayjs();
  notificationCount: number;
  notificationDays = [0, 1, 2, 3, 4, 5, 6, 7, 14, 21, 28];
  notifications = [];
  data: any;
  drawContent: EDrawContent = EDrawContent.USER_PROFILE;
  // passed into component-lib header
  navigationPaths = [
    { displayText: 'Quotes', page: '/quotes', active: false },
    { displayText: 'Orders', page: '/orders', active: false }
  ];

  footerLinks = [
    {
      title: 'Need Support',
      url: 'https://share-eu1.hsforms.com/1YLWAPlTeS4q3BxpWYaElVwg4alw'
    },
    {
      title: 'Contact Us at Autoflow',
      url: 'https://www.autoflow.ltd.uk/contact'
    }
  ];

  policies = [
    {
      title: 'Terms of Service',
      url: 'https://autoflow.ltd.uk/'
    },
    {
      title: 'Privacy Policy',
      url: 'https://autoflow.ltd.uk/en-gb/privacy-policy'
    }
  ];

  private isAdmin$ = new BehaviorSubject<boolean>(false);

  constructor(
    public readonly quoteService: QuotesService,
    private libNavigationService: NavigationService,
    private notificationsService: NotificationsService,
    private orderSidebarService: OrderSidebarService,
    private userService: UserService,
    private router: Router,
    private store: Store<AppState>,
    private drawerService: DrawerService,
    private readonly keycloakService: KeycloakService,
    private configurationService: EnvironmentConfigurationService
  ) {
    this.drawerService.openDrawer.subscribe((val) => {
      if (!val && this.drawContent === EDrawContent.USER_MANAGEMENT) {
        this.store.dispatch(setIsCreateUser({ createUser: false }));
      }
      this.openDrawer = val;
    });
    // order split part button event
    this.orderSidebarService.openAppSideBar$.subscribe((val: boolean) => {
      this.data = this.orderSidebarService.getValue();
      this.openDrawer = val;
      this.drawContent = EDrawContent.ORDERS;
      this.drawerService.toggle(val);
    });

    this.drawerService.openGroupManagement$.subscribe((val) => {
      this.drawContent = val.content;
      this.drawerService.toggle(val.open);
    });

    this.drawerService.openUserManagement$.subscribe((val) => {
      this.drawContent = val.content;
      this.drawerService.toggle(val.open);
      this.hasNav = val.hasNav ?? true;
    });

    this.userService.loadUserProfile().then(() => {
      this.currentUser = this.userService.getCurrentUser();
      this.userProfile = this.userService.getUserProfile();
      this.isAdminUser = this.userService.isAdminUser();
      this.centreNames = this.userService.getCentreNames();
    });

    this.notificationsService.updatedNotifications$?.subscribe((val) => {
      this.notifications = val;
      this.notificationCount = val?.filter((x) => !x.viewedBy).length;
    });

    this.libNavigationService.userLogout$.subscribe((val) => val && this.logout());
    this.isAdmin$.next(this.isAdminUser);

    this.router.events.subscribe(() => {
      /* savedURL should match page in navigationPaths object
      for correct buttons highlighting behvaiours */
      const current = this.router.url.split('/')[1];
      this.savedUrlPath = '/'.concat(current);
    });
  }

  ngOnInit(): void {
    this.navigation = this.getNavigationMenuData();
  }

  ngOnDestroy(): void {
    this.drawerService.openDrawer.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.centreNames.length) {
      this.navigation = this.getNavigationMenuData();
    }
  }

  groupSidebarClosed() {
    this.drawerService.toggle(false);
  }

  drawState(e: any): void {
    // track state of component lib drawer
    if (!e) {
      this.store.dispatch(sideNavToggle({ isSideNavOpen: false }));
      this.store.dispatch(setIsCreateUser({ createUser: false }));
    }
    this.openDrawer = e;
  }

  handleNotificationClickEvent(event: any) {
    this.drawContent = EDrawContent.NOTIFICATIONS;
    this.drawerService.toggle(true);
  }

  handleUserProfileClickEvent(event: any) {
    this.drawContent = EDrawContent.USER_PROFILE;
    this.drawerService.toggle(true);
    this.hasNav = true;
  }

  handleDrawLock() {
    if (this.drawContent === EDrawContent.NOTIFICATIONS) {
      return true;
    }
    return false;
  }

  handleUserManagement() {
    this.drawContent = EDrawContent.USER_MANAGEMENT;
  }

  handleGroupManagement() {
    this.router.navigate(['/management']);
    this.drawerService.toggle(false);
  }

  logout(): void {
    const keycloakInstance: Keycloak.KeycloakInstance = this.keycloakService.getKeycloakInstance();
    const idToken: string = keycloakInstance.idToken;
    const keycloakLoginUrl: string = this.configurationService.getConfig('keycloakUrl');
    const baseUrl: string = this.configurationService.getConfig('baseUrl');
    const realm: string = this.configurationService.getConfig('keycloakRealm');
    const common = `realms/${realm}/protocol/openid-connect`;
    const post_redirect_url = `&post_logout_redirect_uri=${encodeURIComponent(baseUrl)}`;
    const redirectUrl = `${keycloakLoginUrl}/${common}/logout?id_token_hint=${idToken}${post_redirect_url}`;
    window.location.href = redirectUrl;
  }

  getNavigationMenuData(): Record<string, any>[] {
    return [
      {
        title: 'Profile Details',
        iconName: 'UserIcon',
        iconClass: {
          width: '18px',
          height: '18px',
          overflow: 'hidden'
        },
        navItems: [
          {
            title: 'My profile',
            description: 'View your profile details & settings',
            action: () => this.handleUserManagement(),
            active: false,
            disabled: false
          }
        ]
      },
      {
        title: 'Associated groups & sites',
        // empty group forces seperator
        navItems: []
      },
      {
        title: 'Settings',
        adminNavItems: [
          {
            title: 'Group, Site & User management',
            description: 'Manage all details here',
            action: () => this.handleGroupManagement(),
            active: false,
            disabled: false
          }
        ]
      }
    ];
  }

  handleContentType = () => (this.drawContent = EDrawContent.USER_PROFILE);

  fullName(): string {
    return `${this.userProfile?.firstName} ${this.userProfile?.lastName || ''}`;
  }
}
