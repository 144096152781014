import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { MatBadgeModule } from '@angular/material/badge';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { NotificationsListComponent } from './notifications-list/notifications-list.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { NotificationCardsComponent } from './lib/notification-cards/notification-cards.component';
import {
  NotificationsFilterListHeaderComponent
} from './lib/notification-filter-list/notifications-filter-list-header/notifications-filter-list-header.component';
import { NotificationFilterListComponent } from './lib/notification-filter-list/notification-filter-list.component';
import { NotificationsListLibComponent } from './lib/notifications-list/notifications-list.component';
import { OrderTypeBadgeComponent } from './lib/order-type-badge/order-type-badge.component';

@NgModule({
  declarations: [
    NotificationsListComponent,
    // lib
    NotificationCardsComponent,
    NotificationsFilterListHeaderComponent,
    NotificationFilterListComponent,
    NotificationsListLibComponent,
    OrderTypeBadgeComponent,

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
   SharedModule,
   MatBadgeModule,
   MatMenuModule,
   MatSidenavModule,
   MatSlideToggleModule,
   MatTooltipModule,
   MatDialogModule
  ],
  exports: [
    NotificationsListComponent,
    // lib
    NotificationCardsComponent,
    NotificationsFilterListHeaderComponent,
    NotificationFilterListComponent,
    NotificationsListLibComponent,
    OrderTypeBadgeComponent,
  ]
})
export class NotificationsModule { }
