<ol class="container-style" *ngIf="loaded && results.length">
  <li (click)="goToPage('1')" [class.disabled]="currentPage === 1">
    <span class="text-style">
      First
    </span>
  </li>
  <li (click)="goToPage(currentPage - 1 + '')" [class.disabled]="currentPage === 1">
    <span class="text-style">
      Previous
    </span>
  </li>
  <li *ngFor="let page of pagesInCounter; trackBy: trackByFn"
    class="paginator-number"
    [class.active]="page === currentPage + ''"
    (click)="goToPage(page)"
  >
    {{ page }}
  </li>
  <li
    [class.disabled]="currentPage === lastPage"
    (click)="goToPage(currentPage + 1 + '')"
  >
    <span class="text-style">
      Next
    </span>
  </li>
  <li
    [class.disabled]="currentPage === lastPage"
    (click)="goToPage(lastPage + '')"
  >
    <span class="text-style">
      Last
    </span>
  </li>
  <li class="readonly">
    {{firstResult}}-{{lastResult}} of {{resultTotal}}
  </li>
  <li>
    <label class="go-to-style" for="targetPage">Go to:</label>
    <input
      type="text"
      placeholder="e.g. 102"
      #pageNumber
      name="targetPage"
      (keyup.enter)="goToPage(pageNumber.value) && (pageNumber.value = '');"
    />
  </li>
  <li>
    <label class="label-style" for="paging">Rows per page: </label>
    <select class="dropbox-style" (change)="onResultCountChange($event)">
      <option *ngFor="let item of maxPageResultsSelector; let i = index" value="{{i}}" [selected]="i === 1">
        {{ item.resultCount }}
      </option>
    </select>
  </li>
</ol>
