import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { PagedResult } from '../components/paginator/paging.consts';

@Injectable({
  providedIn: 'root'
})
export class PaginationService {
  resultChanged$ = new Subject<PagedResult>();
  resultLoaded$ = new Subject<boolean>();

  constructor() {}

  onResultChange(results: PagedResult): void {
    this.resultChanged$.next(results);
  }
}
