import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-group-management-sidebar',
  templateUrl: './group-management-sidebar.component.html',
  styleUrls: ['./group-management-sidebar.component.scss']
})
export class GroupManagementSidebarComponent {
  @Output() contentType: EventEmitter<string> = new EventEmitter();
  constructor() {}

  navigateTo(event: MouseEvent, contentType: string) {
    event.stopPropagation();
    this.contentType.emit(contentType);
  }
}
