import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ManagementDashboardComponent } from './management-dashboard/management-dashboard.component';
import { ManagementGroupDashboardComponent } from './management-group-dashboard/management-group-dashboard.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'management' },
  {
    path: 'management',
    component: ManagementDashboardComponent,
  },
  {
    path: 'management-groups',
    component: ManagementGroupDashboardComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ManagementRoutingModule { }
