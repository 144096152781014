import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  DEFAULT_CURRENCY_CODE,
  ErrorHandler,
  LOCALE_ID,
  NgModule
} from '@angular/core';
import { AdminSidebarService } from './services/adminSidebar.service';
import { AppRoutingModule } from '../app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { ComponentLibModule } from '@autoflow/angular-ux-components';
import { CustomPaginatorIntl } from './providers/custom-paginator';
import { CustomSnackbarComponent } from './components/custom-snackbar/custom-snackbar.component';
import { DEFAULT_CURRENCY_DISPLAY } from './core.constants';
import { ErrorInterceptorComponent } from './components/error-interceptor/error-interceptor.component';
import { ErrorMessageComponent } from './components/error-message/error-message.component';
import { FooterComponent } from './components/footer/footer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GlobalErrorHandlerService } from './services/global-error-handler.service';
import { GroupAdminSidebarService } from './services/group-admin-sidebar.service';
import { HeaderComponent } from './components/header/header.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { IonicModule } from '@ionic/angular';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { keycloakInitializer } from './providers/keycloack-initializer';
import { LayOutComponent } from './components/lay-out/lay-out.component';
import { ManagementModule } from '../management/management.module';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatOptionModule } from '@angular/material/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { NotFoundPageComponent } from './components/not-found-page/not-found-page.component';
import { NotificationSidebarService } from './services/notificationSidebar.service';
import { NotificationsModule } from '../notifications/notifications.module';
import { Optional, SkipSelf } from '@angular/core';
import { OrderSidebarService } from './services/order-sidebar.service';
import { OrdersService } from '../orders/services/orders.service';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { ResizableModule } from 'angular-resizable-element';
import { SessionExpiryDialogComponent } from '../core/components/session-expiry-dialog/session-expiry-dialog.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { UnderMaintenancePageComponent } from './components/under-maintenance-page/under-maintenance-page.component';

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions, @typescript-eslint/naming-convention
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
const CORE_MODULES = [
  AppRoutingModule,
  BrowserAnimationsModule,
  BrowserModule,
  CdkTableModule,
  ComponentLibModule,
  FormsModule,
  HttpClientModule,
  IonicModule,
  KeycloakAngularModule,
  ManagementModule,
  MatBadgeModule,
  MatButtonModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatFormFieldModule,
  MatInputModule,
  MatMenuModule,
  MatOptionModule,
  MatSelectModule,
  MatSidenavModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  NotificationsModule,
  ReactiveFormsModule,
  ResizableModule
];
const DECLARATIONS = [
  CustomSnackbarComponent,
  ErrorInterceptorComponent,
  ErrorMessageComponent,
  FooterComponent,
  HeaderComponent,
  NotFoundPageComponent,
  PrivacyComponent,
  SessionExpiryDialogComponent,
  UnderMaintenancePageComponent
];
const CORE_DECLARATIONS = [LayOutComponent, SidebarComponent];
@NgModule({
  declarations: [...DECLARATIONS, CORE_DECLARATIONS, ErrorMessageComponent],
  exports: [...CORE_MODULES, CORE_DECLARATIONS],
  imports: [
    ...CORE_MODULES,
    CommonModule,
    TranslateModule.forRoot({
      useDefaultLang: true,
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandlerService
    },
    {
      provide: LOCALE_ID,
      useValue: 'en-UK'
    },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'GBP' },
    { provide: DEFAULT_CURRENCY_DISPLAY, useValue: 'symbol' },
    {
      provide: APP_INITIALIZER,
      useFactory: keycloakInitializer,
      multi: true,
      deps: [KeycloakService]
    },
    {
      provide: MatPaginatorIntl,
      useClass: CustomPaginatorIntl
    },
    NotificationSidebarService,
    AdminSidebarService,
    OrderSidebarService,
    GroupAdminSidebarService,
    OrdersService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [CustomSnackbarComponent]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule has already been loaded. You should only import Core modules in the AppModule only.'
      );
    }
  }
}
