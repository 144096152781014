import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {

  constructor(private readonly dialogRef: MatDialogRef<ConfirmDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: string) { }

  onClickCancel() {
    this.dialogRef.close({data:'cancel'}); //close just the pop up but keep unsaved form?
  }

  onClickConfirm() {
    window.onbeforeunload = null;
    this.dialogRef.close({data:'ok'});
  }
}
