import { createAction, props } from '@ngrx/store';
import { User } from '../../management/models/user.model';

export const sideNavToggle = createAction('[App] Toggle side navigation menu', props<{ isSideNavOpen: boolean }>());

/** Unhandled action */
export const authRequired = createAction('[App/Auth] Auth Required');
/** Unhandled action */
export const setAuthToken = createAction('[App/Auth] AuthenticationToken', props<{ token: string }>());
export const setUserProfile = createAction('[App/Auth] Save user profile to store', props<{ userProfile: Keycloak.KeycloakProfile }>());

export const setGroupUser = createAction('[App] Will set if user is a group user');

export const setUsersList = createAction('[App] Will set users list', props<{ usersList: Array<User> }>());

export const setLoggedInUser = createAction('[App] Will set Loggedin User', props<{ loggedInUser: User }>());

export const setKeyCloakInstance = createAction('[App/Auth] Save KeyCloak Instance to store', props<{ keyCloakInstance: any }>());


