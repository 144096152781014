import { Injectable } from '@angular/core';
import { EnvironmentConfigurationService } from '../../shared/services/environment-configuration.service';

export const createUrl = <T>(url) => ({ url } as T & { url?: string });

export class ApiUrls {
  CHANGE_USER_STATUS = createUrl<{ ':id': string }>('/users/:id/status');
  CHECK_EMAIL_AVAILABILITY = createUrl('/users/check-email');
  CHECK_GROUPNAME_AVAIL = createUrl('/groups/check-virtualGroup');
  CLEAR_NOTIFICATIONS = createUrl('/notifications/update');
  CREATE_USER = createUrl('/users');
  CREATE_VIRTUALGROUP = createUrl('/groups/createVirtualGroup');
  GET_NOTIFICATIONS = createUrl('/notifications');
  GET_PART_DISCOUNTS = createUrl('/parts/discount');
  GET_SAS_TOKEN = createUrl<{ ':imageName': string }>('/files/blob/token/:imageName');
  GET_SITES = createUrl<{ ':id': string }>('/users/:id/groups');
  MARK_NOTIFICATION_VIEWED = createUrl('/notifications/update');
  MARK_PART_DELIVERY_STATUS = createUrl<{ ':orderId': string }>('/parts/:orderId/status');
  ORDER_BY_ID = createUrl<{ ':id': string }>('/orders/:id');
  ORDERS = createUrl('/orders?');
  QUOTE_BY_ID = createUrl<{ ':id': string }>('/quotes/:id');
  QUOTES = createUrl('/quotes?');
  RESET_PASSWORD = createUrl<{ ':id': string }>('/users/:id/reset-password');
  SAVE_QUOTE = createUrl<{ ':quoteId': string }>('/quotes/:quoteId');
  SOCKET_HANDSHAKE = createUrl('/handshake');
  UPDATE_LOCK = createUrl<{ ':estimateId': string }>('/quotes/:estimateId/lock');
  UPDATE_USER = createUrl('/users');
  USER_BY_ID = createUrl<{ ':id': string }>('/users/:id');
  USER_SITES = createUrl<{ ':id': string }>('/users/:id/sites');
  USERS_LIST = createUrl('/users');
  VEHICLE_CONFIRM = createUrl<{ ':id': string }>('/vehicles/:id/confirm');
  VEHICLE_UPDATE = createUrl<{ ':id': string }>('/vehicles/:id');
  VIRTUALGROUP_LIST = createUrl('/groups/groups');
}

@Injectable({
  providedIn: 'root'
})
export class UrlService {
  actions = new ApiUrls();

  constructor(private configurationService: EnvironmentConfigurationService) {}
  /**
   * Returns full url endpoint path (with api url)
   * also can inject values from inject object in to url string
   * TODO: support for optional params in url
   *
   * @param url
   * @param inject values from this object will be injected in to url string
   * @returns
   * @memberof UrlService
   */
  getUrl<T extends keyof ApiUrls>(endpoint: T, inject?: ApiUrls[T]): string {
    let url = `${this.configurationService.getConfig('apiUrl')}${this.actions[endpoint].url}`;
    if (inject) {
      Object.keys(inject).forEach((key) => {
        url = url.replace(key, inject[key]);
      });
    }
    return url;
  }
}
