import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Observable, timer } from 'rxjs';
import { scan, takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-session-expiry-dialog',
  templateUrl: './session-expiry-dialog.component.html',
  styleUrls: ['./session-expiry-dialog.component.scss']
})
export class SessionExpiryDialogComponent implements OnInit {
  timer$: Observable<number>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: { countdown: number; idleTime: number },
    private readonly translate: TranslateService
  ) {}

  ngOnInit() {
    this.timer$ = timer(0, 1000).pipe(scan(acc => --acc, this.data.countdown), takeWhile(v => v >= 0));
  }
}
