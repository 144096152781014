import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, mergeMap } from 'rxjs/operators';
import { User } from '../models/user.model';
import { SaveSnackbarComponent } from '../save-snackbar/save-snackbar.component';
import { ManagementService } from '../services/management.service';
import * as UserActions from './users.actions';
import * as CoreActions from '../../core/store/core.actions';
import { AdminSidebarService } from '../../core/services/adminSidebar.service';
import { KeycloakService } from 'keycloak-angular';
import { Store } from '@ngrx/store';
import { AppState } from '../../quotes/store/reducers';
import { selectUserProfile } from '../../core/store/core.selectors';
import { Observable, of } from 'rxjs';
import { selectAllUsers } from './users.selectors';

@Injectable()
export class UsersEffects {

  durationInSeconds = 5;
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  loadAllUsers$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(UserActions.loadAllUsers),
        concatMap(action =>
          this.managementService.getUsers()),
        map((users) => UserActions.loadAllUsersSuccess({ users })),
      )
  );

  updateUser$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(UserActions.updateUser),
        concatMap(action =>
          this.managementService.updateUser(action.userUpdate)),
        map((user: User) => {
          this.snackBar.openFromComponent(SaveSnackbarComponent, {
            duration: this.durationInSeconds * 1000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            data: 'userUpdate'
          });
          if (user.id === this.keycloakService.getKeycloakInstance().subject) {
            this.store.select(selectUserProfile).subscribe((userProfile) => {
              userProfile.firstName = user.firstName;
              userProfile.lastName = user.lastName;
              userProfile.email = user.email;
              this.store.dispatch(CoreActions.setUserProfile({ userProfile }));
              this.store.dispatch(UserActions.selectedUser({ user }));
            });
            // this.adminSidebar.close();
           return  UserActions.updateUserSuccess({ user});
          }
          else{
          // this.adminSidebar.close();
          return UserActions.updateUserSuccess({ user });
          }
        }))
  );

  createUser$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(UserActions.createUser),
        concatMap(action =>
          this.managementService.createUser(action.userCreate)),
        map((user: User) => {
          if(user) {
          this.snackBar.openFromComponent(SaveSnackbarComponent, {
            duration: this.durationInSeconds * 1000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            data: 'userCreate'
          });
          // this.adminSidebar.close();
          return UserActions.createUserSuccess({ user });
        } else {
          this.snackBar.openFromComponent(SaveSnackbarComponent, {
            duration: this.durationInSeconds * 1000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            data: 'userCreateError'
          });
        }
        })
        ,catchError(err => {
          this.snackBar.openFromComponent(SaveSnackbarComponent, {
            duration: this.durationInSeconds * 1000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            data: 'userCreateError'
          });
          return of(console.log('Error occurred while creating user at effect. Error : ', err)) as Observable<any>;
        })
        )
  );

  resetUserPassword$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(UserActions.resetUserPassword),
        mergeMap(action =>
          this.managementService.resetUserPassword(
            action.userId)),
        map(user => {
          this.snackBar.openFromComponent(SaveSnackbarComponent, {
            duration: this.durationInSeconds * 1000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            data: 'reset'
          });
          return UserActions.resetUserPasswordSuccess({ user });
        }))
  );

  changeUserStatus$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(UserActions.changeUserStatus),
        mergeMap(action =>
          this.managementService.changeUserStatus(
            action.userId)),
        map(user => {
          this.snackBar.openFromComponent(SaveSnackbarComponent, {
            duration: this.durationInSeconds * 1000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            data: 'changeUserStatus'
          });
          return UserActions.changeUserStatusSuccess({ user });
        }))
  );


  constructor(
    private readonly managementService: ManagementService,
    private readonly actions$: Actions,
    private readonly snackBar: MatSnackBar,
    private readonly adminSidebar: AdminSidebarService,
    private readonly keycloakService: KeycloakService,
    private readonly store: Store<AppState>
  ) { }
}
