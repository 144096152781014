// for the age of the notification
import * as dayjs from 'dayjs';
export enum EStateType {
    WEEKONE = 'WEEKONE',
    WEEKTWO = 'WEEKTWO',
    ANCIENT = 'ANCIENT',
};


// for the close button add styles to the component
export enum ECloseButtonAlignment {
    LEFT,
    RIGHT,
}

export interface INotificationMessage {
    title: string;
    text: string;
};

export interface INotification {
    _id: string;
    ageing: string;
    archivedAt: dayjs.Dayjs;
    assessedAs: string;
    centreCode: string;
    clearedAt: dayjs.Dayjs;
    clearedBy: string;
    createdAt: dayjs.Dayjs;
    message: INotificationMessage;
    order: string;
    quote: string;
    raisedBy: string;
    repairerName: string;
    registration: string;
    status: string;
    type: string;
    viewedBy?: string;
    viewedByAbbr?: string;
    url?: string;
};


//link
export enum EType {
    ORDER = 'order',
    QUOTE = 'quote',
};


