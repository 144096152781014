import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map } from 'rxjs/operators';
import { SaveSnackbarComponent } from '../save-snackbar/save-snackbar.component';
import { ManagementService } from '../services/management.service';
import * as GroupActions from './groups.actions';
import { KeycloakService } from 'keycloak-angular';
import { Store } from '@ngrx/store';
import { AppState } from '../../quotes/store/reducers';
import { Observable, of } from 'rxjs';
import { VirtualGroup, VirtualGroupDetail } from '../models/virtualGroup.model';
import { GroupAdminSidebarService } from '../../core/services/group-admin-sidebar.service';

@Injectable()
export class GroupsEffects {

  durationInSeconds = 5;
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  loadAllGroups$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(GroupActions.loadAllGroups),
        concatMap(action =>
          this.managementService.getVirtualGroups()),
        map((virtualGroups) => GroupActions.loadAllGroupsSuccess({ virtualGroups })),
      )
  );

  createVirtualGroup$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(GroupActions.createVirtualGroup),
        concatMap(action =>
          this.managementService.createVirtualGroup(action.virtualGroupCreate)),
        map((vgGroup: VirtualGroup) => {
          if (vgGroup) {
            this.snackBar.openFromComponent(SaveSnackbarComponent, {
              duration: this.durationInSeconds * 1000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
              data: 'virtualGroupCreate'
            });
            const virtualGroup: VirtualGroupDetail = (vgGroup as any);
            virtualGroup.userCount = 1;
            virtualGroup.siteCount = virtualGroup.centreCodes.length;
            this.store.dispatch(GroupActions.selectedGroup({ virtualGroup }));
            return GroupActions.createVirtualGroupSuccess({ virtualGroup });
        } else {
          this.snackBar.openFromComponent(SaveSnackbarComponent, {
            duration: this.durationInSeconds * 1000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            data: 'virtualGroupCreateError'
          });
        }
        })
        ,catchError(err => {
          this.snackBar.openFromComponent(SaveSnackbarComponent, {
            duration: this.durationInSeconds * 1000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            data: 'virtualGroupCreateError'
          });
          return of(console.log('Error occurred while creating Virtual Group at effect. Error : ', err)) as Observable<any>;
        })
        )
  );

  constructor(
    private readonly managementService: ManagementService,
    private readonly actions$: Actions,
    private readonly snackBar: MatSnackBar,
    private readonly adminSidebar: GroupAdminSidebarService,
    private readonly keycloakService: KeycloakService,
    private readonly store: Store<AppState>
  ) { }
}
