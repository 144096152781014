import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NotificationSidebarService } from '../../core/services/notificationSidebar.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { predicates } from '../../shared/predicates';
import { SocketResponse, WebsocketsService } from '../../core/services/websockets.service';
import { Notification, NotificationTypes } from '../notification.model';
import { NotificationsService } from '../notifications.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-notifications-list',
  template: '',
  animations: [
    trigger('InsertionRemovalTrigger', [
      transition(':enter', [style({ opacity: 0 }), animate('.5s', style({ opacity: 1 }))]),
      transition(':leave', [animate('.5s', style({ opacity: 0 }))])
    ])
  ],
  styleUrls: ['./notifications-list.component.scss']
})
export class NotificationsListComponent implements OnInit {
  @Output() itemCountChanged: EventEmitter<number> = new EventEmitter<number>();

  filteredNotificationCount = 0;
  headings = [];
  mouseOvered: string;
  notificationCount = 0;
  notifications: any = {};

  private websocketsService = WebsocketsService;

  constructor(
    private notificationSidebar: NotificationSidebarService,
    private notificationsService: NotificationsService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.handleNotifications();
  }

  private addNotification(notification: Notification): void {
    this.notificationsService.addNotification(notification);
  }

  private handleNotifications(): void {
    this.websocketsService.socketMessageReceived
      .pipe(predicates.isSameSocketEvent(NotificationTypes.ADDITIONAL_PARTS_RECEIVED), predicates.matchesEnv())
      .subscribe((data: SocketResponse) => this.addNotification(data.payload as any));

    this.websocketsService.socketMessageReceived
      .pipe(predicates.isSameSocketEvent(NotificationTypes.NOTIFICATION_VIEWED), predicates.matchesEnv())
      .subscribe((data: SocketResponse) => {
        this.notificationsService.getNotifications();
      });

    this.websocketsService.socketMessageReceived
      .pipe(predicates.isSameSocketEvent(NotificationTypes.NOTIFICATIONS_CLEARED), predicates.matchesEnv())
      .subscribe((data: SocketResponse) => {
        this.notificationsService.getNotifications();
      });

    this.websocketsService.socketMessageReceived
      .pipe(predicates.isSameSocketEvent(NotificationTypes.NOTIFICATIONS_FETCHED), predicates.matchesEnv())
      .subscribe(() => {
        this.notificationsService.getNotifications();
      });

    this.websocketsService.socketMessageReceived
      .pipe(predicates.isSameSocketEvent(NotificationTypes.ORDER_RECEIVED), predicates.matchesEnv())
      .subscribe((data: SocketResponse) => this.addNotification(data.payload as any));

    this.websocketsService.socketMessageReceived
      .pipe(predicates.isSameSocketEvent(NotificationTypes.ORDER_CANCELLED), predicates.matchesEnv())
      .subscribe((data: SocketResponse) => this.addNotification(data.payload as any));

    this.websocketsService.socketMessageReceived
      .pipe(predicates.isSameSocketEvent(NotificationTypes.ORDER_FULFILLED), predicates.matchesEnv())
      .subscribe((data: SocketResponse) => this.addNotification(data.payload as any));

    this.websocketsService.socketMessageReceived
      .pipe(predicates.isSameSocketEvent(NotificationTypes.ORDER_STATUS_UPDATED), predicates.matchesEnv())
      .subscribe((data: SocketResponse) => this.addNotification(data.payload as any));

    this.websocketsService.socketMessageReceived
      .pipe(predicates.isSameSocketEvent(NotificationTypes.QUOTE_RECEIVED), predicates.matchesEnv())
      .subscribe((data: SocketResponse) => this.addNotification(data.payload as any));

    this.websocketsService.socketMessageReceived
      .pipe(predicates.isSameSocketEvent(NotificationTypes.SINGLE_NOTIFICATION_CLEARED), predicates.matchesEnv())
      .subscribe((data: SocketResponse) => {
        this.notificationsService.getNotifications();
      });
  }
}
