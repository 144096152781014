
<div class="container">
  <span>
    <ion-icon
      class="icon magnifier"
      name="search-outline"
      (click)="searchField.select()"
    ></ion-icon>
  </span>
  <span>
    <input
      #searchField
      class="input-field"
      [placeholder]="placeholderText"
      (blur)="activateSearch()"
      (focus)="focused = true;"
      (keyup.enter)="searchTermChanged.emit(searchField.value)"
    />
  </span>
  <span>
    <ion-icon
      *ngIf="searchField.value.length"
      class="icon close"
      name="close-circle"
      (click)="clearSearch();"
    ></ion-icon>
  </span>
  <span>
    <button
      type="button"
      [ngClass]="{
        'search-button': true,
        focused
      }"
      (click)="activateSearch()"
    >
      Search
    </button>
  </span>
</div>
