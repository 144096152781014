import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { fromEvent, ReplaySubject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { PartStatus } from '../../../../app/parts/models/part.model';
import { OrdersService } from '../../services/orders.service';

@Component({
  selector: 'app-item-delayed-dialog',
  templateUrl: './item-delayed-dialog.component.html',
  styleUrls: ['./item-delayed-dialog.component.scss']
})
export class ItemDelayedDialogComponent implements OnInit, OnDestroy {
  @ViewChild('pencilIcon', { static: false }) pencilIcon: ElementRef;

  editing = false;
  itemDelayForm: UntypedFormGroup;
  minDate = new Date();
  showingForm = false;
  radioButtonValues: string[] = ['Yes', 'No'];
  yesSelected = true;
  noSelected = false;
  private onDestroy$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    public itemDelayDialogRef: MatDialogRef<ItemDelayedDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private ordersService: OrdersService
  ) {
    this.itemDelayForm = this.formBuilder.group({
      etaAvailable: ['', [Validators.required]],
      delayReason: ['', [Validators.required]],
      delayedUntil: ['', [Validators.required]]
    });
  }

  ngOnInit(): void {
    fromEvent<KeyboardEvent>(window, 'keyup')
      .pipe(
        filter((ev: KeyboardEvent) => ev.key === 'Enter'),
        filter(() => !document.activeElement || document.activeElement.tagName.toLowerCase() !== 'button'),
        filter(() => !this.itemDelayForm.invalid),
        takeUntil(this.onDestroy$)
      )
      .subscribe(this.confirm.bind(this));

    this.checkDisplayForm();

    if (this.data.delayedAt) {
      this.itemDelayForm.reset({
        etaAvailable: !!this.data.delayedUntil ? '1' : '2',
        delayReason: this.data.delayReason,
        delayedUntil: this.data.delayedUntil || ''
      });
    } else {
      this.itemDelayForm.reset({
        etaAvailable: '1',
        delayReason: '',
        delayedUntil: ''
      });
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  confirm(): void {
    this.itemDelayDialogRef.close({
      etaAvailable: this.itemDelayForm.getRawValue().delayedUntil === '2' ? false : true,
      delayReason: this.itemDelayForm.getRawValue().delayReason,
      delayedUntil: this.itemDelayForm.getRawValue().delayedUntil
    });
  }

  checkDisplayForm(): void {
    const partDelayed = this.data.status === PartStatus.DELAYED;
    this.showingForm = !(partDelayed && !this.editing && this.data.delayReason);

    if (this.showingForm) {
      this.setValue(!partDelayed || this.data.delayedUntil ? 'Yes' : 'No');
    } else {
      // Remove ion icon tooltip which interferes with matTooltip display
      setTimeout(() => this.removeSVGTooltip());
    }
  }

  onCancel(): void {
    this.itemDelayDialogRef.close();
  }

  removeSVGTooltip(event?: MouseEvent): void {
    this.ordersService.removeSVGTooltip(event || this.pencilIcon.nativeElement);
  }

  setValue(value: string): void {
    this.yesSelected = value === 'Yes';
    this.noSelected = !this.yesSelected;
    if (value === 'Yes') {
      this.itemDelayForm.patchValue({
        etaAvailable: '1'
      });
      this.itemDelayForm.get('delayedUntil').reset({
        value: this.data.delayedAt ? this.data.delayedUntil : '',
        disabled: false
      });
      this.itemDelayForm.get('delayedUntil').setValidators([Validators.required]);
    } else {
      this.itemDelayForm.patchValue({
        etaAvailable: '2'
      });
      this.itemDelayForm.get('delayedUntil').reset({ value: '', disabled: true });
      this.itemDelayForm.get('delayedUntil').clearValidators();
      this.itemDelayForm.get('delayedUntil').setErrors(null);
    }
    this.itemDelayForm.get('delayedUntil').updateValueAndValidity();
  }
}
