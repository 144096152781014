import { Dayjs } from 'dayjs';
import { Role } from './role.model';
import { Site } from './site.model';
import { EmailType } from './emailType.model';
import { VirtualGroup } from './virtualGroup.model';

export enum UserStatus {
  AWAITING = 'AWAITING',
  DISABLED = 'DISABLED',
  ENABLED = 'ENABLED',
}
export class User {
  assignableRoles?: Role[];
  awaiting: boolean;
  createdAt?: Dayjs;
  email: string;
  emailTypes: EmailType[];
  enabled: boolean;
  firstName: string;
  fullName?: string;
  id: string;
  lastName: string;
  resetPassword: boolean;
  roles: Role[];
  sites: Site[];
  siting?: [];
  status?: string;
  virtualGroups: VirtualGroup[];

  constructor(user?: Partial<User>) {
    this.assignableRoles = user.assignableRoles || [];
    this.createdAt = user.createdAt;
    this.email = user.email;
    this.emailTypes = [];
    this.enabled = false;
    this.firstName = user.firstName;
    this.id = user.id;
    this.lastName = user.lastName;
    this.resetPassword = false;
    this.roles = user.roles || [];
    this.sites = user.sites || [];
    this.virtualGroups = user.virtualGroups || [];
  }
}
