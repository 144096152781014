<div class="custom-snackbar" [ngClass]="'custom-snackbar--' + data.snackType" fxLayout="row">
    <div>
        <ion-icon class="custom-snackbar__icon"[name]="icon"></ion-icon>
    </div>
    <div>
      <span class="custom-snackbar__message">{{data.message}}</span>
    </div>
    <div class="custom-snackbar__action" (click)="close()" *ngIf="data.action" [ngSwitch]="data.action === 'Close'">
        <span *ngSwitchCase="false">{{data.action}}</span>
        <ion-icon style="font-size: 1.25rem" *ngSwitchCase="true" name="close"></ion-icon>
    </div>
  </div>