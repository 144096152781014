<div class="customer-details-sticky" data-cy="customer-details">
    <div class="customer-details-section">
        <div class="customer-details">
            <h2>Customer details</h2>
            <div class="customer-details__semibold">{{quote?.repairer?.repairerName}}</div>
            <address>
                {{quote?.repairer?.address?.buildingNumber + ' ' +
                quote?.repairer?.address?.buildingName + ' ' +
                quote?.repairer?.address?.street}}<br>
                {{quote?.repairer?.address?.postcode}}<br>
                <span class="customer-details__small-title">email:</span>&nbsp;<span class="customer-details__semibold">{{quote?.repairer?.email}}</span> <br>
                <span class="customer-details__small-title">phone:</span>&nbsp;<a class="customer-details__blue-text" href="tel:{{quote?.repairer?.contactNo}}">
                    {{quote?.repairer?.contactNo}}
                </a><br>
            </address>
        </div>
        <mat-divider></mat-divider>
        <div class="customer-details__reset">
            <h2>Vehicle information</h2>
            <div class="customer-details__info-item">
                <span>VIN number</span>
                <span class="customer-details__blue-text">{{quote?.vehicle?.vin}}</span>
            </div>
            <mat-divider class="customer-details__sub-divider"></mat-divider>
            <div class="customer-details__info-item">
                <span>Registration number</span>
                <span class="customer-details__blue-text">{{quote?.vehicle?.registration}}</span>
            </div>
            <mat-divider class="customer-details__sub-divider"></mat-divider>
            <div class="customer-details__info-item">
                <span>Make</span>
                <span class="customer-details__blue-text">{{quote?.vehicle?.make}}</span>
            </div>
            <mat-divider class="customer-details__sub-divider"></mat-divider>
            <div class="customer-details__info-item">
                <span>Model</span>
                <span class="customer-details__blue-text">{{quote?.vehicle?.model}}</span>
            </div>
            <mat-divider class="customer-details__sub-divider"></mat-divider>
            <div class="customer-details__info-item">
                <span>Colour</span>
                <span class="customer-details__blue-text">{{quote?.vehicle?.colour}}</span>
            </div>
        </div>
        <mat-divider></mat-divider>
        <div class="customer-details__reset" data-cy="customer-details-reset">
            <h2>Quote information</h2>
            <div class="customer-details__info-item">
                <span>Date created</span>
                <span class="customer-details__blue-text"data-cy="date-created">{{formatDate(quoteCreationDate)}}</span>
            </div>
            <mat-divider class="customer-details__sub-divider" *ngIf="formatDate(quoteCreationDate || quote.quotedDate)"></mat-divider>
            <div class="customer-details__info-item" *ngIf="formatDate(quoteCreationDate || quote.quotedDate)">
                <span>Modified on</span>
                <span class="customer-details__blue-text" data-cy="date-modified">{{formatDate(quoteCreationDate || quote.quotedDate)}}</span>
            </div>
        </div>
        <div class="customer-details__reset" *ngIf="hideButtons" data-cy="customer-details-converted-to-order">
            <h2>Converted to order information</h2>
            <div class="customer-details__info-item">
                <span>Date converted</span>
                <span class="customer-details__blue-text" data-cy="date-converted">{{formatDate(order?.orderedAt)}}</span>
            </div>
        </div>
        <mat-divider *ngIf="order?.customerOrderReferenceNumber"></mat-divider>
        <div class="customer-details__reset" *ngIf="order?.customerOrderReferenceNumber">
            <h2>Quote summary details</h2>
            <div class="customer-details__info-item customer-details__info-item--padding">
                <span>Quote net total:</span>
                <span class="customer-details__blue-text">{{quoteTotal | currency:'GBP' }}</span>
            </div>
            <div class="customer-details__info-item customer-details__info-item--padding">
                <span>Surcharge total:</span>
                <span class="customer-details__blue-text">{{quoteSurcharge | currency:'GBP' }}</span>
            </div>
            <div class="customer-details__info-item">
                <span>Quoted items:</span>
                <span class="customer-details__blue-text customer-details__quoted-items-count">{{quotedItemCount}}</span>
            </div>
        </div>
        <mat-divider *ngIf="order?.customerOrderReferenceNumber"></mat-divider>
        <div class="customer-details__reset" *ngIf="order?.customerOrderReferenceNumber">
            <h2>Order summary details</h2>
            <div class="customer-details__info-item customer-details__info-item--padding">
                <span>Order net total:</span>
                <span class="customer-details__blue-text">{{orderTotal | currency:'GBP' }}</span>
            </div>
            <div class="customer-details__info-item customer-details__info-item--padding">
                <span>Surcharge total:</span>
                <span class="customer-details__blue-text">{{orderSurcharge | currency:'GBP' }}</span>
            </div>
            <div class="customer-details__info-item">
                <span>Ordered items:</span>
                <span class="customer-details__blue-text">{{orderedItemCount}}</span>
            </div>
        </div>
        <mat-divider></mat-divider>
        <div class="customer-details__reset" *ngIf="hideButtons" data-cy="customer-details">
            <h2>Order number</h2>
            <div class="customer-details__info customer-details__info--blue-label" *ngIf="hideButtons" data-cy="order-number">{{ order?.orderNumber || 'TBC'}}</div>
        </div>
        <div class="customer-details__save" *ngIf="!hideButtons" data-cy="customer-details-save">
            <h2>Save quote</h2>
            <p>You can save amendments you have made to this quote
               if you need to complete it later.</p>
            <button mat-flat-button type="button" color="primary" (click)="saveStarted.emit()" [disabled]="btnSaveDisabled" data-cy="save-quote">Save
                Quote</button>
        </div>
    </div>
    <div class="customer-details-section" *ngIf="hideButtons">
        <div class="customer-details">
            <h2>Customer order reference</h2>
        </div>
        <div class="customer-details__reset">
            <div class="customer-details__info customer-details__info--black-label">{{ order?.customerOrderReferenceNumber || 'TBC'}}</div>
        </div>
    </div>
</div>
