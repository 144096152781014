import * as dayjs from 'dayjs';

export interface Notification {
  _id: string;
  ageing?: NotificationAge;
  archivedAt: dayjs.Dayjs;
  centreCode: string;
  clearedAt: dayjs.Dayjs;
  clearedBy: string;
  createdAt: dayjs.Dayjs;
  message: NotificationMessage;
  order: string;
  quote: string;
  raisedBy: string;
  repairerName: string;
  status: string;
  type: string;
  viewedBy: string;
  viewedByAbbr?: string;
};

interface NotificationAge {
  weekOne: boolean;
  weekTwo: boolean;
  ancient: boolean;
}

interface NotificationMessage {
  title: string;
  text: string;
};

export enum NotificationTypes {
  ADDITIONAL_PARTS_RECEIVED = 'ADDITIONAL_PARTS_RECEIVED',
  NOTIFICATION_VIEWED = 'NOTIFICATION_VIEWED',
  NOTIFICATIONS_CLEARED = 'NOTIFICATIONS_CLEARED',
  NOTIFICATIONS_FETCHED = 'NOTIFICATIONS_FETCHED',
  ORDER_CANCELLED = 'ORDER_CANCELLED',
  ORDER_FULFILLED = 'ORDER_FULFILLED',
  ORDER_RECEIVED = 'ORDER_RECEIVED',
  ORDER_STATUS_UPDATED = 'ORDER_STATUS_UPDATED',
  QUOTE_RECEIVED = 'QUOTE_RECEIVED',
  SINGLE_NOTIFICATION_CLEARED = 'SINGLE_NOTIFICATION_CLEARED'
}
