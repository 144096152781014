
<div style="display: grid;"> 
  <lib-navigation 
    [username]="fullName()"
    [email]="userProfile?.email"
    (notificationClickEvent)="handleNotificationClickEvent($event)"
    (userProfileClickEvent)="handleUserProfileClickEvent($event)"
    [notificationCount]="notificationCount" 
    [paths]="navigationPaths"
    [savedUrlPath]="savedUrlPath"
  ></lib-navigation>
</div>

<lib-drawer (emitDrawState)="drawState($event)" [toggleDrawer]="openDrawer" [lockDrawer]="handleDrawLock()">
  <app-notifications-list></app-notifications-list>
  
  <app-management-sidebar 
    (contentType)="handleContentType()" 
    [hasNav]="hasNav"
    body 
    *ngIf="drawContent === 'USER_MANAGEMENT'" 
  >
  </app-management-sidebar>
  
  <app-group-management-sidebar 
    (contentType)="handleContentType()" 
    body 
    *ngIf="drawContent === 'GROUP_MANAGEMENT'"
    >
  </app-group-management-sidebar>
  
  <lib-user-profile-header 
    *ngIf="drawContent === 'USER_MANAGEMENT' 
      || drawContent === 'USER_PROFILE' 
      || drawContent === 'GROUP_MANAGEMENT'"
    header 
    [name]="currentUser" 
    [email]="userProfile?.email">
  </lib-user-profile-header>

  <lib-user-profile-menu  
    *ngIf="drawContent === 'USER_PROFILE'" 
    body
    [centreNames]="centreNames"
    [navigation]="navigation"
    [isAdminUser]="isAdminUser"
    ></lib-user-profile-menu>

  <app-notifications-filter-list-header 
    *ngIf="drawContent === 'NOTIFICATIONS'" 
    class="filter-list__header" 
    header
  >
  </app-notifications-filter-list-header>

  <app-notification-filter-list 
    *ngIf="drawContent === 'NOTIFICATIONS'" 
    body 
    [notifications]="notifications" 
    [notificationDays]="this.notificationDays"
  >
  </app-notification-filter-list>

  <app-sidebar   
    *ngIf="drawContent === 'ORDERS'"
     body
     [data]="data"
    >
  </app-sidebar>
</lib-drawer>

<!-- old code to be replaced...eventually -->
<mat-drawer-container class="layout" autosize>
  <mat-drawer-content>
    <router-outlet id="scrollToDiv"></router-outlet>
  </mat-drawer-content>
</mat-drawer-container>
<lib-footer [appVersion]="quoteService.getAppVersion()" [links]="footerLinks" [policies]="policies"></lib-footer>
