import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime'; // used for dayjs().fromNow()
import { Observable } from 'rxjs';

dayjs.extend(relativeTime);

@Pipe({
    name: 'dateFormatter'
})
export class DateFormatterPipe implements PipeTransform {
    transform(value: any): any {
        const dateObservable = new Observable<string>(observer => {
            setInterval(function updateRelativeTime() {
                const rt = dayjs(value).fromNow();
                observer.next(rt);
                return updateRelativeTime; // ensures that the function is called straight away the first time
            }(), 60000);

        });
        return dateObservable;
    }
}

