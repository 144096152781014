import { Injectable } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Store } from '@ngrx/store';
import { AppState } from '../../quotes/store/reducers';

@Injectable({
  providedIn: 'root'
})
export class AdminSidebarService {
  private adminSidebar: MatSidenav;
  constructor() { }

  setSidenav(adminSidebar: MatSidenav) {
    this.adminSidebar = adminSidebar;
  }

  open() {
    return this.adminSidebar.open();
  }

  close() {
    return this.adminSidebar.close();
  }

  toggle(): void {
    this.adminSidebar.toggle();
  }
}
