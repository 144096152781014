import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-custom-snackbar',
  templateUrl: './custom-snackbar.component.html',
  styleUrls: ['./custom-snackbar.component.scss']
})
export class CustomSnackbarComponent implements OnInit {
  /*
    to do:
      - should the snackbar be dismissed by default when clicking away?
      - if so possibly pass data to determine whether it can be dismissed this way
      - pass functions to be called when clicking the action 'button'.
      - test on mobile / multiple lines
  */

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {}

  /*
    switch statement to determine what ion-icon to use
  */
  get icon(): string {
    switch (this.data.snackType) {
      case 'success':
        return 'checkmark-circle';
      case 'error':
        return 'close-circle';
      case 'warn':
        return 'alert-circle';
      case 'info':
        return 'alert-circle';
    }
  }

  ngOnInit(): void {}

  /*
    function to be called when wanting to dismiss the snackbar
  */
  close(): void {
    this.data.snackbar.dismiss();
  }
}
