<div class="confirm-dialog__container">
    <div class="confirm-dialog__header-container">
        <div class="confirm-dialog__title">
            <h2 mat-dialog-title>Disable User</h2>
            <P>Disabling pauses an account until an Admin <span>enables user</span>.</P>
        </div>
    </div>
    <div mat-dialog-actions class="confirm-dialog__actions-container">
        <div class="confirm-dialog__two-buttons">
            <button mat-stroked-button class="mat-stroked-button__primary" (click)="onClickCancel()">Cancel</button>
            <button mat-flat-button  class="mat-mdc-unelevated-button__primary" color="primary" (click)="onClickConfirm()">Confirm</button>
        </div>
    </div>
</div>