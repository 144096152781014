import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @Output() emitter: EventEmitter<string> = new EventEmitter<string>();
  anio: number = new Date().getFullYear();
  public lastUrl = 'quotes/new';
  constructor() {
  }

  ngOnInit(): void {
  }
}
