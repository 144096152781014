// export enum EStatus {
//     NEW,
//     ADDITIONAL,
//     IN_PROGRESS,
//     SUBMITTED,
//     SAVED,
// }

// export enum EStatus2 {
//     NEW,
//     DELAYED,
//     CANCELLED,
//     SHIPPED,
//     AWAITING_FULFILLMENT,
//     FULFILLED,
// }

export enum EStatus {
    ADDITIONAL = 'ADDITIONAL',
    AWAITING_FULFILMENT = 'AWAITING FULFILMENT',
    CANCELLED = 'CANCELLED',
    CUSTOM = 'CUSTOM', // With custom text
    DELAYED = 'DELAYED',
    FULFILLED = 'FULFILLED',
    IN_PROGRESS = 'IN PROGRESS',
    NEW = 'NEW',
    SAVED = 'SAVED',
    SHIPPED = 'SHIPPED',
    SUBMITTED = 'SUBMITTED',
    REGISTRATION = 'REGISTRATION'
}
