import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GroupsState } from './groups.reducers';
import * as fromGroups from './groups.reducers';
export const selectGroupsState = createFeatureSelector<GroupsState>('groups');

export const selectAllGroups = createSelector(
  selectGroupsState,
  fromGroups.selectAll
);

export const selectedGroup = createSelector(
    selectGroupsState,
  state => state.selectedGroup
);

export const selectGroupById = (id: string) => createSelector(
    selectAllGroups,
  groups => groups.find(group => group._id === id)
);

export const selectIsCreateGroup = createSelector(
    selectGroupsState,
  state => state.isCreateGroup);

