import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBar, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-save-snackbar',
  templateUrl: './save-snackbar.component.html',
  styleUrls: ['./save-snackbar.component.scss']
})
export class SaveSnackbarComponent implements OnInit {
  snackMessage: string;
  snackInfo: string;
  snackButton: string;

  constructor(public snackBar: MatSnackBar, @Inject(MAT_SNACK_BAR_DATA) public data: any) { }

  ngOnInit(): void {
    // We wil have to add the user name to the switch case for the message to be specific
    switch (this.data) {
      case 'save':
      case 'userUpdate':
        this.snackMessage = 'Success!';
        this.snackInfo = 'Details updated';
        this.snackButton = 'Dismiss';
        break;
      case 'saveAdmin':
        this.snackMessage = 'Success!';
        this.snackInfo = '{{User}} will be activated once they login';
        this.snackButton = 'Dismiss';
        break;
      case 'reset':
        this.snackMessage = 'Success!';
        this.snackInfo = 'You have reset your password';
        this.snackButton = 'Dismiss';
        break;
      case 'resetAdmin':
        this.snackMessage = 'Success!';
        this.snackInfo = '{{User}} has reset their password';
        this.snackButton = 'reset';
        break;
      case 'changeUserStatus':
        this.snackMessage = 'Success!';
        this.snackInfo = 'User Status has been changed successfully';
        this.snackButton = 'Dismiss';
        break;
      case 'userCreate':
        this.snackMessage = 'Success!';
        this.snackInfo = 'User will be activated once they login';
        this.snackButton = 'Dismiss';
        break;
      case 'userCreateError':
        this.snackMessage = 'Error!';
        this.snackInfo = 'User creation failed.';
        this.snackButton = 'Dismiss';
        break;
      case 'virtualGroupCreate':
        this.snackMessage = 'Success!';
        this.snackInfo = 'Group Created successfully';
        this.snackButton = 'Dismiss';
        break;
      case 'virtualGroupCreateError':
        this.snackMessage = 'Error!';
        this.snackInfo = 'Group creation failed.';
        this.snackButton = 'Dismiss';
        break;
      default:
        this.snackMessage = 'Success!';
        this.snackInfo = '';
        this.snackButton = 'Dismiss';
    }
  }

  dismiss() {
    this.dismiss();
  }

}


