import { Role } from './models/role.model';

export const predicates = {
  isEnabledUser: (value: any) => value.enabled === true && value.awaiting === false,
  isDisabledUser: (value: any) => value.enabled === false,
  isAwaitingUser: (value: any) => value.awaiting === true && value.enabled === true,
  isAdminRole: (roles: Role[]) => roles.some((role) => ['group_admin', 'site_admin'].includes(role.name)),
  isStandardRole: (roles: Role[]) => roles.some((role) => ['site_user'].includes(role.name)) && !predicates.isGroupAdminRole(roles),
  isGroupAdminRole: (roles: Role[]) => roles.some((role) => ['group_admin'].includes(role.name)),
  isSiteAdmin: (roles: string[]) =>  roles.some((role) => ['site_admin'].includes(role)),
  isAdmin: (roles: string[]) =>  roles.some((role) => ['group_admin','site_admin'].includes(role)),
};
