import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { User } from '../models/user.model';
import * as UserActions from './users.actions';
//import { loadAllUsers, loadAllUsersSuccess, updateUserSuccess } from './users.actions';

export interface UsersState extends EntityState<User> {
  loading: false;
  selectedUser: User;
  isCreateUser: false;
};

export const adapter = createEntityAdapter<User>({
  selectId: ins => ins.id
});

export const initialUsersState = adapter.getInitialState({
  loading: false,
  selectedUser: null,
  isCreateUser: false
});

export const usersReducer = createReducer(
  initialUsersState,
  on(UserActions.loadAllUsers, (state: UsersState) => ({ ...state, loading: true })),
  on(UserActions.loadAllUsersSuccess,
    (state: UsersState, { users }) => ({
      ...adapter.addMany(users, state),
      loading: false
    })),
  on(UserActions.updateUser, (state: UsersState) => ({ ...state, loading: true })),
  on(UserActions.updateUserSuccess,
    (state: UsersState, { user }) =>
      adapter.updateOne({
        id: user.id,
        changes: user,
      }, state)),
  on(UserActions.selectedUser, (state: UsersState, { user }) =>
    ({ ...state, selectedUser: user })),
  on(UserActions.changeUserStatus, (state: UsersState) => ({ ...state, loading: true })),
  on(UserActions.changeUserStatusSuccess,
    (state: UsersState, { user }) =>
      adapter.updateOne({
        id: user.id,
        changes: user,
      }, state)),
  on(UserActions.resetUserPassword, (state: UsersState, { userId }) =>
    ({ ...state, loading: true })),
  on(UserActions.resetUserPasswordSuccess,
    (state: UsersState, { }) =>
      ({ ...state, loading: false })),
  on(UserActions.setIsCreateUser,
    (state: UsersState, { createUser}) =>
      ({ ...state, isCreateUser:createUser })),
  on(UserActions.createUser, (state: UsersState) => ({ ...state, loading: true })),
  on(UserActions.createUserSuccess,
    (state: UsersState, { user }) => ({
    ...adapter.addOne(user, {...state, isCreateUser: false}),
    loading: false
  })),
);

export const {
  selectAll,
} = adapter.getSelectors();
