import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as GroupActions from './groups.actions';
import { VirtualGroupDetail } from '../models/virtualGroup.model';

export interface GroupsState extends EntityState<VirtualGroupDetail> {
    loading: false;
    selectedGroup: VirtualGroupDetail;
    isCreateGroup: false;
};

export const adapter = createEntityAdapter<VirtualGroupDetail>({
    selectId: ins => ins._id
});

export const initialGroupsState = adapter.getInitialState({
    loading: false,
    selectedGroup: null,
    isCreateGroup: false
});

export const groupsReducer = createReducer(
    initialGroupsState,
    on(GroupActions.loadAllGroups, (state: GroupsState) => ({ ...state, loading: true })),
    on(GroupActions.loadAllGroupsSuccess,
       (state: GroupsState, { virtualGroups }) => ({
         ...adapter.addMany(virtualGroups, state),
         loading: false
    })),
    on(GroupActions.createVirtualGroup, (state: GroupsState) => ({ ...state, loading: true })),
    on(GroupActions.createVirtualGroupSuccess,
        (state: GroupsState, { virtualGroup }) => ({
            ...adapter.addOne(virtualGroup, { ...state, isCreateGroup: false }),
            loading: false
        })),
    on(GroupActions.setIsCreateGroup,
        (state: GroupsState, { createGroup }) =>
            ({ ...state, isCreateGroup: createGroup })),
    on(GroupActions.selectedGroup, (state: GroupsState, { virtualGroup }) =>
        ({ ...state, selectedGroup: virtualGroup })),
);

export const {
    selectAll,
} = adapter.getSelectors();
