import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import dayjs, { Dayjs } from 'dayjs';
import { Quote } from '../../../quotes/models/quote.model';
import { QuotesService } from '../../../quotes/services/quotes.service';
import { Order, OrderStatus } from '../../../orders/models/order.model';
import { PartsService } from '../../../parts/services/parts.service';
import { predicates } from '../../predicates';
import { PartsListChange } from '../../../parts/models/part.model';

@Component({
  selector: 'app-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.scss']
})
export class CustomerDetailsComponent implements OnInit {
  @Input() quote: Quote;
  @Input() order: Order;
  @Input() hideButtons: boolean;
  @Output() saveStarted: EventEmitter<null> = new EventEmitter<null>();

  btnSaveDisabled = true;
  orderedItemCount = 0;
  orderNumber: string;
  orderSurcharge = 0;
  orderTotal = 0;
  quoteCreationDate: Dayjs;
  quotedItemCount = 0;
  quoteSurcharge = 0;
  quoteTotal = 0;

  constructor(private quotesService: QuotesService, public dialog: MatDialog, private partsService: PartsService) {}

  ngOnInit(): void {
    this.quotesService.valuesChanged?.subscribe((change: PartsListChange) => {
      this.updateSaveButtonState(change);
    });

    if (this.quote) {
      this.quoteCreationDate = this.quote.recreatedAt || this.quote.createdAt;
    }

    if (this.quote?.parts) {
      const quoteCalculation = this.partsService?.calculateTotals(
        this.quote.parts.map((qp) => {
          // if possible use net / discount / unit from ordered part (for TLA)
          // this is due to the ordered item's net price using the cumulative
          // tla & standard discount
          if (this.quote.quoteScheme === 'TLA') {
            const orderedPart = this.order.parts.find((op) => op.uniquePartId === qp.uniquePartId);
            if (orderedPart) {
              qp.discountAmount = this.partsService.calculateDiscountAmount(orderedPart);
              qp.discount = orderedPart.discount;
              qp.net = orderedPart.net;
              qp.unit = orderedPart.unit;
            }
          }
          return qp;
        })
      );
      this.quotedItemCount = this.quote.parts
        .filter(predicates.notRemovedFromParts)
        .filter(predicates.notPartBreakdownHeader)
        .map((p) => p.quantity)
        .reduce((a, b) => a + b, 0);
      this.quoteTotal = quoteCalculation?.subtotal;
      this.quoteSurcharge = quoteCalculation.surcharge;
    }

    if (this.order?.parts) {
      const orderCalculation = this.partsService.calculateTotals(
        this.order.parts,
        this.order.status === OrderStatus.CANCELLED
      );
      this.orderedItemCount = orderCalculation?.totalItems;
      this.orderTotal = orderCalculation?.subtotal;
      this.orderSurcharge = orderCalculation.surcharge;
    }
  }

  formatDate(date: Dayjs): string {
    return dayjs(date).format('DD/MM/YYYY');
  }

  private updateSaveButtonState(change: PartsListChange): void {
    if (!change) {
      return;
    }

    if (this.quote?.locked || !(change.valid && change.valuesChanged)) {
      this.btnSaveDisabled = true;
    } else {
      this.btnSaveDisabled = false;
    }
  }
}
