import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManagementSidebarComponent } from './management-sidebar/management-sidebar.component';
import { ManagementDashboardComponent } from './management-dashboard/management-dashboard.component';
import { ManagementRoutingModule } from './management-routing.module';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDividerModule } from '@angular/material/divider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { UsersEffects } from './store/users.effects';
import { usersReducer } from './store/users.reducers';
import { MatMenuModule } from '@angular/material/menu';
import { ManagementGroupDashboardComponent } from './management-group-dashboard/management-group-dashboard.component';
import { UserFormComponent } from './user-form/user-form.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SaveSnackbarComponent } from './save-snackbar/save-snackbar.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SharedModule } from '../shared/shared.module';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { IonicModule } from '@ionic/angular';
import { GroupManagementSidebarComponent } from './group-management-sidebar/group-management-sidebar.component';
import { GroupFormComponent } from './group-form/group-form.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { groupsReducer } from './groupsStore/groups.reducers';
import { GroupsEffects } from './groupsStore/groups.effects';


@NgModule({
  declarations: [
    ManagementSidebarComponent,
    ManagementDashboardComponent,
    ManagementGroupDashboardComponent,
    UserFormComponent,
    SaveSnackbarComponent,
    ConfirmDialogComponent,
    GroupManagementSidebarComponent,
    GroupFormComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    IonicModule,
    ManagementRoutingModule,
    RouterModule,
    MatDividerModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDividerModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatMenuModule,
    MatRadioModule,
    MatSnackBarModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    StoreModule.forFeature('users', usersReducer),
    EffectsModule.forFeature([UsersEffects]),
    StoreModule.forFeature('groups', groupsReducer),
    EffectsModule.forFeature([GroupsEffects]),
    SharedModule
  ],
  exports: [
    ManagementSidebarComponent,
    GroupManagementSidebarComponent,
  ]
})
export class ManagementModule { }
