/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, map, ReplaySubject, takeUntil } from 'rxjs';
import { IStep } from '../models/stepper.model';
import { WebsocketsService } from '../../core/services/websockets.service';
import { UserService } from '../../user.service';

@Injectable({
  providedIn: 'root'
})
export class TlaService implements OnDestroy {
  protected _currentStep = 1; // 3 step system
  public readonly currentStep$: BehaviorSubject<number> = new BehaviorSubject(this._currentStep);

  // boolean observables that determine when to show TLA-related content
  private _tlaCalculated$ = new BehaviorSubject<boolean>(false);
  public tlaCalculated$ = this._tlaCalculated$.asObservable();
  public readonly submittedTLA$ = new BehaviorSubject(false);
  private minStep = 1;
  private maxStep = 4; // 4 = submitted
  public showTooltip = false;
  public readonly tlaDiscount$: BehaviorSubject<number> = new BehaviorSubject(0); // discount applied to all parts

  // TLA Data Influencers
  public readonly PAV$: BehaviorSubject<number> = new BehaviorSubject(0);

  // updated by other observables - repair cost BEFORE TLA support etc.
  public readonly repairCost$: BehaviorSubject<number> = new BehaviorSubject(0);
  public readonly partsCost$: BehaviorSubject<number> = new BehaviorSubject(3000); // net TLA Cost
  public readonly paintMaterials$: BehaviorSubject<number> = new BehaviorSubject(700);
  public readonly labour$: BehaviorSubject<number> = new BehaviorSubject(875);
  public readonly otherCosts$: BehaviorSubject<number> = new BehaviorSubject(800);

  public readonly wpPartsDiscount$: BehaviorSubject<number> = new BehaviorSubject(0);
  public readonly ICOP$: BehaviorSubject<number> = new BehaviorSubject(0); // updated by other observables
  public readonly ICOPafterTLASupport$: BehaviorSubject<number> = new BehaviorSubject(0); // updated by other observables
  // updated by other observables - also serves as the value displayed as ICOP
  public readonly repairCostWithSupport$: BehaviorSubject<number> = new BehaviorSubject(0);
  public readonly repairerTermsDiscount$: BehaviorSubject<number> = new BehaviorSubject(20);
  public readonly tlaSupportDiscount$: BehaviorSubject<number> = new BehaviorSubject(0);
  public readonly additionalDiscount$: BehaviorSubject<number> = new BehaviorSubject(0); // updated by other observables
  public readonly repairerPartsMargin$: BehaviorSubject<number> = new BehaviorSubject(0);
  public readonly insuranceCompanyPays$: BehaviorSubject<number> = new BehaviorSubject(0);

  // TLA Discount Split

  public readonly TLL$: BehaviorSubject<number> = new BehaviorSubject(0); // updated by other observables
  public readonly TLP$: BehaviorSubject<number> = new BehaviorSubject(70); // get from BE
  public readonly pavRVPercentage$: BehaviorSubject<number> = new BehaviorSubject(0); // updated by other observables
  public readonly subscribed$ = new BehaviorSubject<boolean>(false);
  private onDestroy$ = new ReplaySubject<boolean>(1);
  private subscribed = false;
  private subscribers = ['demo', 'dev', 'ford', 'prod', 'qa'];

  constructor(private userService: UserService, private websocketsService: WebsocketsService) {
    // TODO: Implementation of "waitUntilResolved" from ABP-1779
    this.websocketsService.connected$
      .pipe(distinctUntilChanged(), takeUntil(this.onDestroy$))
      .subscribe(() => this.setSubscribed());

    this.currentStep$
      .pipe(
        map((step) => step > 3),
        distinctUntilChanged(),
        takeUntil(this.onDestroy$)
      )
      .subscribe(this.submittedTLA$);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  get currentStep(): number {
    return this._currentStep;
  }

  get steps(): IStep[] {
    return [
      {
        stepNumber: 1,
        stepDesc: 'Validate parts list',
        percentage: this.currentStep === 1 ? 5 : this.currentStep > 1 ? 100 : 0
      },
      {
        stepNumber: 2,
        stepDesc: 'Apply TLA terms',
        percentage: this.currentStep === 2 ? 5 : this.currentStep > 2 ? 100 : 0
      },
      {
        stepNumber: 3,
        stepDesc: 'Review summary & submit',
        percentage: this.currentStep === 3 ? 5 : this.currentStep > 3 ? 100 : 0
      }
    ];
  }

  setSubscribed(): void {
    this.subscribed =
      this.subscribers.includes(this.websocketsService.getHubName().toLowerCase()) && this.userService.isSubscribed();
    this.subscribed$.next(this.subscribed);
  }

  isSubscribed(): boolean {
    return this.subscribed;
  }

  // TODO: Can the user select a sep i.e.: 3 => 1 ?

  nextStep(): void {
    this._currentStep = Math.min(this.currentStep + 1, this.maxStep);
    this.currentStep$.next(this._currentStep);
  }

  previousStep(): void {
    this._currentStep = Math.max(this.currentStep - 1, this.minStep);
    this.currentStep$.next(this._currentStep);
  }

  setTLAStep(step: number): void {
    this._currentStep = Math.max(Math.min(step, this.maxStep), this.minStep);
    this.currentStep$.next(this._currentStep);
  }

  setTLADiscount(value: number): void {
    this.tlaDiscount$.next(value);
  }

  tlaCalculated(value: boolean): void {
    this._tlaCalculated$.next(value);
  }

  updateLabourCost(value: number): void {
    this.labour$.next(value);
  }

  updateOtherCosts(value: number): void {
    this.otherCosts$.next(value);
  }

  updatePaintAndMaterialCost(value: number): void {
    this.paintMaterials$.next(value);
  }

  updatePartsCost(value: number): void {
    this.partsCost$.next(value);
  }

  updatePAV(value: number): void {
    this.PAV$.next(value); // TODO: Make this more secure, allow it to only be updated once, get from BE only etc.
  }

  updateRepairTermsDiscount(value: number): void {
    this.wpPartsDiscount$.next(value);
  }

  updateTLP(value: number): void {
    this.TLP$.next(value);
  }

  updateWPPartsDiscount(value: number): void {
    this.wpPartsDiscount$.next(value);
  }
}
