/* eslint-disable space-before-function-paren */
/* eslint-disable @typescript-eslint/no-shadow */
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OrdersState } from './orders.reducers';
import * as fromOrders from './orders.reducers';
import { OrderDataSource } from '../models/order-data.model';

export const selectOrdersState = createFeatureSelector<OrdersState>('orders');

export const selectAllOrders = createSelector(selectOrdersState, fromOrders.selectAll);

export const Loaded = createSelector(selectOrdersState, (state) => state.loading);

// ? SEE IF THESE ARE STILL BEING USED AND LOOK AT SOLUTIONS THAT USE NEW DATA SOURCE SERVICE
export const selectAll = createSelector(selectAllOrders, (orders) =>
  orders.reduce(function (filters, order) {
    if (filters) {
      filters.push(new OrderDataSource(order));
      return filters;
    }
  }, [])
);

export const ordersLoaded = createSelector(selectOrdersState, (state) => state.loading);

export const order = (id: string) =>
  createSelector(selectAllOrders, (orders) => orders.filter((orders) => orders._id === id)[0]);

export const selectedOrderId = createSelector(selectOrdersState, (state) => state.selectedOrder);

export const isCreatingPartSplit = createSelector(selectOrdersState, (state) => state.creatingPartSplit);
