<div class="parts__totals-container">
  <div class="parts__sub-totals">
    <div class="parts__totals-items">
      <span>VAT Rate (%)</span>
      <span>{{ vatPercent }}</span>
    </div>
    <div class="parts__totals-items">
      <span>Total Net Value (&pound;)</span>
      <span>&pound;{{ subtotal | number : '1.2-2'}}</span>
    </div>
    <div class="parts__totals-items">
      <span>VAT (&pound;)</span>
      <span>&pound;{{ vatCalculated | number : '1.2-2' }}</span>
    </div>
    <div class="parts__totals-items">
      <span>Total Net Surcharge (&pound;)</span>
      <span>&pound;{{ surcharge | number : '1.2-2' }}</span>
    </div>
  </div>
  <table class="parts__totals">
    <tr>
      <td>Net (&pound;)</td>
      <td>{{ subtotal | currency:'GBP' }}</td>
    </tr>
    <tr>
      <td>VAT</td>
      <td>{{ vatCalculated | currency:'GBP' }}</td>
    </tr>
    <tr>
      <td>Total</td>
      <td>{{ total | currency:'GBP' }}</td>
    </tr>
  </table>
</div>