import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { EDrawContent } from '../components/lay-out/lay-out.component';

export interface IDrawer {
  open: boolean;
  content: EDrawContent;
  hasNav?: boolean;
}

@Injectable({
  providedIn: 'root'
})


export class DrawerService {
  openDrawer: Subject<boolean> = new Subject();
  // content management
  openGroupManagement$: Subject<IDrawer> = new Subject();
  openUserManagement$: Subject<IDrawer> = new Subject();

  constructor() { }

  // toggle group management
  toggleGroup = (value: IDrawer): void => this.openGroupManagement$.next(value);

  // toggle user management
  toggleProfile = (value: IDrawer): void => this.openUserManagement$.next(value);

  toggle(value: boolean): void {
    this.openDrawer.next(value);
  }

}
