import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss']
})
export class SearchFilterComponent {
  @Input() placeholderText?: string;
  @Output() searchTermChanged = new EventEmitter<string>();
  @ViewChild('searchField', { static: false }) searchField: ElementRef;

  focused = false;
  searchTerm = '';

  constructor() {}

  activateSearch(): void {
    const searchFieldValue = this.getCurrentValue();
    if (!searchFieldValue || searchFieldValue !== this.searchTerm) {
      this.searchTermChanged.emit(searchFieldValue);
      this.setSearchTerm(searchFieldValue);
    }
  }

  clearSearch(): void {
    this.searchTerm = this.searchField.nativeElement.value = '';
    this.searchTermChanged.emit('');
    this.searchField.nativeElement.focus();
  }

  private getCurrentValue(): string {
    return this.searchField.nativeElement.value;
  }

  private setSearchTerm(value: string): void {
    this.searchTerm = value;
  }
}
