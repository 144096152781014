<div *ngIf="data?.part" class="sidebar">
    <h1>Split order</h1>
    <p>Select the amount of items you want to ship</p>
    <h3>The items you are splitting in the order</h3>
    <p>Part number &amp; description</p>

    <div class="sidebar__part">
        <div>
            <p><span>{{data.part.partNumber}}</span></p>
            <p>{{data.part.description}} </p>
        </div>

        <div class="sidebar__part count">
          <span>{{data.part.quantity}}</span>
          <span>items</span>
          <button
            class="icons minus"
            [ngClass]="[data.quantityShipped <= 0 ?  'minus' : '',data.quantityShipped > 0  ?  'minus-black' : '']"
            [disabled]="data.quantityShipped <= 0"
            (click)="adjustQuantity(1)"
          >
            <ion-icon name="remove-circle-outline"></ion-icon>
          </button>
          <input
            matInput
            class="sidebar__part numInput"
            maxlength="2"
            type="text"
            [(ngModel)]="data.quantityShipped"
            (input)="adjustQuantity()"
            (keydown)="isAllowedInput($event)"
          >
          <button
            class="icons plus"
            [disabled]="data.quantityRemaining <= 1"
            (click)="adjustQuantity(1, true)"
          >
            <ion-icon name="add-circle-outline"></ion-icon>
          </button>
        </div>
    </div>

    <div
        *ngIf="data.quantityRemaining > 0 && data.quantityRemaining < data.part.quantity"
        [ngClass]="{
            sidebar__remain: true,
            'delayed-status': data.part.status === 'Delayed'
        }"
    >
        <div>
            <p>
              <span>Remaining items</span>
            </p>
            <p *ngIf="data.part.status !== 'Delayed'">Do you want to set the remaining items as delayed</p>
        </div>

        <div class="items">
            <p>
              <span>{{ data.quantityRemaining }}</span>
              <span *ngIf="data.quantityRemaining !== 1">items</span>
              <span *ngIf="data.quantityRemaining === 1">item</span>
            </p>

            <div *ngIf="data.part.status !== 'Delayed'" class="buttons">
                <button
                    [ngClass]="{ selected: remainingQuantityDelayed }"
                    (click)="remainingQuantityDelay(true)"
                >
                    Yes
                </button>
                <button
                    [ngClass]="{ selected: !remainingQuantityDelayed }"
                    (click)="remainingQuantityDelay(false)"
                >
                    No
                </button>
            </div>
        </div>
    </div>
    <form [formGroup]="itemDelayForm" *ngIf="data.part.status !== 'Delayed' && data.quantityRemaining > 0 && data.quantityRemaining < data.part.quantity && remainingQuantityDelayed === true">
      <div class="sidebar__invoice__delay-container">
        <p><span>Item Delayed</span></p>
        <p class="sidebar__invoice__delay-container--eta">We need to capture an ETA date & reason</p>
        <div>
          <p class="sidebar__invoice__delay-container--etaDate">Please set an ETA date below</p>
        <div class="sidebar__invoice__datepicker-container">
          <span class="sidebar__invoice__delay-container--setItem">Set Item ETA<span class="sidebar__invoice__delay-container--req"> - (required)</span></span>
          <mat-form-field class="mat-mdc-form-field__datepicker mat-form-field__primary" appearance="outline">
              <input matInput [readonly]="true" [matDatepicker]="picker" formControlName="delayedUntil" [min]="minDate" placeholder="Please choose a date" required>
              <mat-datepicker-toggle class="datePicker-custom" matSuffix [for]="picker" ngDefaultControl>
                <ion-icon name="chevron-down-outline" matDatepickerToggleIcon></ion-icon>
              </mat-datepicker-toggle>
            <mat-datepicker #picker disabled="false" (closed)="toggledDatePicker(false)" (opened)="toggledDatePicker(true)"></mat-datepicker>
          </mat-form-field>
        </div>
        </div>
        <div class="sidebar__invoice__text">
          <span class="sidebar__invoice__delay-container--setItem">Reason for the delay<span class="sidebar__invoice__delay-container--req"> - (required)</span></span>
          <mat-form-field class="mat-form-field__textarea mat-form-field__primary" appearance="outline">
            <textarea matInput rows="5" class="sidebar__invoice__delay-container--textArea" formControlName="delayReason" placeholder="Type the reason here..."></textarea>
          </mat-form-field>
        </div>
      </div>
    </form>

    <div [ngClass]="{ sidebar__invoice: true, 'delayed-status': data.part.status === 'Delayed' }">
        <p>Invoice details</p>
        <p>Please provide invoice details</p>
        <div mat-dialog-content class="sidebar__invoice--content">
          <div class="sidebar__invoice--content-inputs">
            <div class="sidebar__invoice--content-input">
                <label>Invoice number<span> - (optional)</span></label>
                <mat-form-field class="mat-form-field__modern mat-form-field__standard mat-form-field__primary" appearance="outline">
                  <input matInput [formControl]="invoiceNumberCheck" (keyup)="validateInput()" #invoiceNumberCheckEnter>
                  <mat-error *ngIf="invoiceNumberCheck.invalid">{{validateInput()}}</mat-error>
                </mat-form-field>
            </div>
            <div class="sidebar__invoice--datepicker-container">
              <label>Date invoiced<span> - (optional)</span></label>
              <mat-form-field class="mat-mdc-form-field__datepicker mat-form-field__primary" appearance="outline">
                <input
                  #invoiceDateEnter
                  matInput
                  [min]="minDate"
                  [max]="maxDate"
                  [matDatepicker]="picker"
                  placeholder="Choose date"
                  [(ngModel)]="invoicedAt"
                >
                <mat-datepicker-toggle matSuffix [for]="picker">
                  <ion-icon name="chevron-down-outline" matDatepickerToggleIcon></ion-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #picker disabled="false" (closed)="toggledDatePicker(false)" (opened)="toggledDatePicker(true)"></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="sidebar__invoice--tip">
          <span>Tip:</span>
          <span>You can add these details later</span>
        </div>
    </div>

    <h2>Ready to split items to ship?</h2>
    <div>
        <p>Please confirm these items to be shipped</p>
    </div>
    <button mat-flat-button class="mat-mdc-unelevated-button__primary-small" 
    [disabled]="delayValid() || data.part.quantity <= this.data.quantityShipped || this.data.quantityShipped < 1 ||
      invoiceNumberCheck.invalid" (click)="confirmSplit()">Confirm to ship these items</button>
</div>
