import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Part } from '../../../parts/models/part.model';

@Component({
  selector: 'app-multi-copy',
  templateUrl: './multi-copy.component.html',
  styleUrls: ['./multi-copy.component.scss']
})
export class MultiCopyComponent implements OnInit, OnChanges {
  @ViewChild('container') container;
  @Input() parts: Part[];
  partsRange: any[] = [];
  partsSelections: any[] = [];
  copyMessage = 'Copy to clipboard';
  copied = -3;
  showSelections = false;
  dropdownTop = '0';
  partNumberList: any[] = [];
  partNumberAll: string;
  partNumberQuantityList: any[] = [];
  partNumberQuantityAll: string;
  partsNumberQuantitySelections: any[] = [];
  partsNumberQuantityRange: any[] = [];

  constructor() {
    document.addEventListener('click', this.offClickHandler.bind(this));
  }
  ngOnInit(): void {
    this.processPartChanges(this.parts);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.processPartChanges(changes.parts.currentValue);
  }

  processPartChanges(parts: Part[]) {
    this.partsRange = [];
    this.partsSelections = [];
    this.partNumberList = [];
    this.partNumberAll = '';
    this.partNumberQuantityList = [];
    this.partNumberQuantityAll = '';
    this.partsNumberQuantitySelections = [];
    this.partsNumberQuantityRange = [];

    parts?.forEach((part) => {
      if (!part.removed) {
        this.partNumberList.push(part.partNumber?.replace(/[^a-zA-Z0-9]/g, ''));
        this.partNumberQuantityList.push(part.partNumber?.replace(/[^a-zA-Z0-9]/g, '')+'\t'+part.quantity);
        this.partsRange.push(part.partNumber?.replace(/[^a-zA-Z0-9]/g, ''));
        this.partsNumberQuantityRange.push(part.partNumber?.replace(/[^a-zA-Z0-9]/g, '')+'\t'+part.quantity);
      }
    });

    for (const value of this.partsRange) {
      this.partsSelections.push(this.partsRange.splice(0, 20));
      this.partsNumberQuantitySelections.push(this.partsNumberQuantityRange.splice(0, 20));
    }
    if (this.partsRange.length > 0) {
      this.partsSelections.push(this.partsRange);
      this.partsNumberQuantitySelections.push(this.partsNumberQuantityRange.splice(0, 20));
    }

    const partNumberlist = this.partNumberList.toString();
    this.partNumberAll = partNumberlist.replaceAll(',', '\n');
    const partNumberQuantityList = this.partNumberQuantityList.toString();
    this.partNumberQuantityAll = partNumberQuantityList.replaceAll(',', '\n');
  }

  getPartNumber(partsSelection): string {
    const partNumberRange = partsSelection.toString();
    const partNumberRanger = partNumberRange.replaceAll(',', '\n');
    return partNumberRanger;
  }

  getPartNumberQuantity(partsSelection): string {
    const partNumberQuantityRange = partsSelection.toString();
    const partNumberQuantityRanger = partNumberQuantityRange.replaceAll(',', '\n');
    return partNumberQuantityRanger;
  }

  toggleSelected() {
    this.showSelections = !this.showSelections;
  }

  offClickHandler(event: any) {
    if (!event.target.matches('#multiCopyContainer, #multiCopyContainer *')) {
      this.showSelections = false;
    }
    this.processPartChanges(this.parts);
  }

  whichAmI(data): void {
    this.copied = data;
    switch (data) {
      case -2: {
        this.dropdownTop = '-3rem';
        break;
      }
      case -1: {
        this.dropdownTop = '-6rem';
        break;
      }
      case 0: {
        this.dropdownTop = '-9rem';
        break;
      }
      default: {
        this.dropdownTop = '-' + (9 + data * 3) + 'rem';
        break;
      }
    }
  }
}
