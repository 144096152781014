import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, fromEvent, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { FooterComponent } from '../footer/footer.component';
import { UrlService } from '../../../shared/services/url.service';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {
  @ViewChild(FooterComponent) child;
  @Input() lastUrl: string;
  public hasScrolled$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  previousUrl = '';
  previousUrlString = '';
  public description = '';
  public title = '';
  private onDestroy$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(private urlService: UrlService) { }

  ngOnInit(): void {
    const content = document.getElementsByClassName('mat-drawer-content')[0];

    fromEvent<any>(content, 'scroll').pipe(
      map(event => content.scrollTop > 0),
      distinctUntilChanged(),
      takeUntil(this.onDestroy$)
    ).subscribe(this.hasScrolled$);
    this.urlService?.previousUrl$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe((previousUrl: string) => {
      this.previousUrl = previousUrl;
      this.previousUrlString = this.previousUrl.replace(/\//g, ' / ');
    });
  }
}
