import { createAction, props } from '@ngrx/store';
import { Order } from '../../orders/models/order.model';

export const selectedOrderId = createAction('[Order Details] Selected Order', props<{ id: string }>());

export const loadOrder = createAction('[Order Details] Load An Order', props<{ id: string }>());

export const loadOrderSuccess = createAction('[Load An Order Effect] An Order Loaded', props<{ order: Order }>());

export const setPartStatus = createAction('[Set Part Status] Set part status', props<{ data: any }>());

export const setPartsDelay = createAction('[Set Parts Delay] Set parts delay status', props<{ data: any }>());

export const setPartsCancel = createAction('[Set Parts Cancel] Set parts cancel status', props<{ data: any }>());

export const createPartSplit = createAction('[App] Create Part Split', props<{ partSplitData: any }>());

export const createPartSplitSuccess = createAction('[App] Create Part Split Success');

export const resetOrderState = createAction('[App] Reset Order State');

export const updateOrderState = createAction('[App] Update Order State', props<{ order: Order }>());
