import { HttpClient, HttpXhrBackend } from '@angular/common/http';
import {
  EnvironmentConfig,
  EnvironmentConfigurationService
} from '../app/shared/services/environment-configuration.service';
import { UtilService } from '../app/shared/services/util.service';

export class Environment {
  private configurationService: EnvironmentConfigurationService;

  constructor(configurationService: EnvironmentConfigurationService) {
    this.configurationService = configurationService;
    this.configurationService.setConfig();
  }

  async getConfig(): Promise<EnvironmentConfig> {
    await this.configurationService.configExists();
    const apiUrl = this.configurationService.getConfig('apiUrl');
    const baseUrl = this.configurationService.getConfig('baseUrl');
    const keycloakClientId = this.configurationService.getConfig('keycloakClientId');
    const keycloakRealm = this.configurationService.getConfig('keycloakRealm');
    const keycloakUrl = this.configurationService.getConfig('keycloakUrl');
    const production = !!this.configurationService.getConfig('production');
    return { apiUrl, baseUrl, keycloakClientId, keycloakRealm, keycloakUrl, production };
  }
}

export function environment(): Promise<EnvironmentConfig> {
  let configurationService = null;
  if (!EnvironmentConfigurationService.getInstance()) {
    const httpClient = new HttpClient(new HttpXhrBackend({ build: () => new XMLHttpRequest() }));
    configurationService = new EnvironmentConfigurationService(httpClient, new UtilService());
  } else {
    configurationService = EnvironmentConfigurationService.getInstance();
  }

  return new Environment(configurationService).getConfig();
}
