import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { KeycloakService } from 'keycloak-angular';
import { ManagementService } from '../services/management.service';
import { Group } from '../models/group.model';
import { VirtualGroup, VirtualGroupDetail } from '../models/virtualGroup.model';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../quotes/store/reducers';
import { createVirtualGroup, selectedGroup, setIsCreateGroup } from '../groupsStore/groups.actions';
import { Observable, ReplaySubject, Subscription, filter, takeUntil } from 'rxjs';
import { selectSideNavOpen } from '../../core/store/core.selectors';
import { setLoggedInUser } from '../../core/store/core.actions';
import * as groupSelector from '../groupsStore/groups.selectors';
import * as coreSelector from '../../core/store/core.selectors';
import { Site } from '../models/site.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DrawerService } from '../../core/services/drawer.service';

@Component({
  selector: 'app-group-form',
  templateUrl: './group-form.component.html',
  styleUrls: ['./group-form.component.scss']
})
export class GroupFormComponent implements OnInit, OnDestroy {
  loggedInUser: any;
  groupObj: VirtualGroup;
  sites: any[] = [];
  sitesCopy: any[] = [];
  showSearch = false;
  groupForm: UntypedFormGroup;
  groupExists = false;
  disableSites = true;

  private onDestroy$: ReplaySubject<boolean> = new ReplaySubject(1);
  private managementService$: Subscription;

  constructor(
    private managementService: ManagementService,
    private formBuilder: UntypedFormBuilder,
    private readonly keycloakService: KeycloakService,
    private store: Store<AppState>,
    private drawerService: DrawerService,
    private snackbar: MatSnackBar
  ) {}

  get form() {
    return this.groupForm.controls;
  }

  get loggedInUserId(): string {
    return this.keycloakService.getKeycloakInstance().subject;
  }


  ngOnInit(): void {
    this.getLoggedInUser();
    this.groupObj = new VirtualGroup();

    //setting loggedin user to store
    this.managementService
      ?.getUser(this.loggedInUserId)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe({
        next: (loggedInUser) => {
          this.loggedInUser = loggedInUser;
          this.store.dispatch(setLoggedInUser({ loggedInUser }));
        },
        error: (err) => this.snackbar.open(err)
      });

    //getting loggedin user sites
    this.store.pipe(select(coreSelector.selectLoggedInUser)).subscribe((loggedInUser) => {
      this.sites = this.sitesCopy = [];
      if (loggedInUser && !this.sites.length) {
        loggedInUser.sites.map((site: Site) => {
          const { checked, path, ...availableSite } = site;
          this.sitesCopy.push(availableSite);
        });
        this.sites = this.sitesCopy;
      }
    });
    this.initializeForm();

    this.store
      .pipe(select(selectSideNavOpen))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((isSideNavOpen) => {
        if (isSideNavOpen === false) {
          this.store.dispatch(setIsCreateGroup({ createGroup: false }));
          this.store.dispatch(selectedGroup({ virtualGroup: null }));
          this.refreshData();
        }
      });

    this.store
      .pipe(
        select(groupSelector.selectedGroup),
        filter((data) => !!data)
      )
      .subscribe((virtualGroupDetail: VirtualGroupDetail) => {
        if (virtualGroupDetail) {
          if (!this.loggedInUser.virtualGroups.map((vg) => vg._id).includes(virtualGroupDetail._id)) {
            const { userCount, siteCount, ...virtualGroup } = virtualGroupDetail;
            this.loggedInUser.virtualGroups.push(virtualGroup);
            this.store.dispatch(setLoggedInUser({ loggedInUser: this.loggedInUser }));
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.managementService$?.unsubscribe();
    this.onDestroy$?.unsubscribe();
  }

  getLoggedInUser() {
    this.store.pipe(select(coreSelector.selectLoggedInUser)).subscribe((loggedInUser) => {
      if (loggedInUser) {
        this.loggedInUser = loggedInUser;
      }
    });
  }

  refreshData() {
    this.initializeForm();
    this.groupObj = new VirtualGroup();
    this.sites.forEach((site) => (site.keep = false));
    this.showSearch = false;
    this.disableSites = true;
  }

  initializeForm() {
    this.groupForm = this.formBuilder.group({
      groupName: new UntypedFormControl('', [
        Validators.pattern('[a-zA-Z][a-zA-Z_ ]+[a-zA-Z_]$'),
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(255)
      ]),
      groupEmail: new UntypedFormControl('', [Validators.required, Validators.email, Validators.maxLength(50)]),
      sites: new UntypedFormArray([]),
      enableGroupEmail: new UntypedFormControl(false, [Validators.required]),
      visible: false
    });
  }

  checkGroupNameExists() {
    if (this.groupForm) {
      if (this.groupForm?.controls.groupName.value === '') {
        return;
      }
      const group = { name: this.groupForm?.controls.groupName.value };
      this.managementService$ = this.managementService.checkGroupNameExists(group as Partial<Group>).subscribe({
        next: (response: boolean) => {
          if (response) {
            this.groupForm.controls.groupName.setErrors({ valid: false });
            this.groupExists = true;
            this.disableSites = true;
          } else {
            this.groupExists = false;
            this.disableSites = false;
          }
        },
        error: (e) => console.error(e)
      });
    }
  }

  openSitesDiv(event: MouseEvent) {
    event.stopPropagation();
    if (!this.disableSites) {
      this.showSearch = !this.showSearch;
    }
  }

  searchSiteTool(event: any) {
    this.resetSearch();
    if (event === '') {
      return;
    }
    const result = this.sites.filter(
      (site) =>
        site.centreCode.toLocaleLowerCase().includes(event.target.value) ||
        site.name.toLocaleLowerCase().includes(event.target.value)
    );
    this.sites = result;
  }

  resetSearch() {
    this.sites = this.sitesCopy;
  }
  siteSelectionChange(event, site, checked): void {
    event.stopPropagation();
    site.keep = !checked;
  }

  toggleEnableGroupEmail(event) {
    this.groupForm.patchValue({ enableGroupEmail: event.checked });
  }

  createGroup() {
    this.groupObj.name = this.groupForm.controls.groupName.value;
    this.groupObj.email = this.groupForm.controls.groupEmail.value;
    this.groupObj.enableGroupEmail = this.groupForm.controls.enableGroupEmail.value;
    this.groupObj.centreCodes = this.sites.filter((site) => site.keep).map((site) => site.centreCode);
    this.groupObj.visible = false;
    this.store.dispatch(createVirtualGroup(JSON.parse(JSON.stringify({ virtualGroupCreate: this.groupObj }))));
    this.drawerService.toggle(false);
  }
}
