<div #container class="multi-copy__section">
  <div id="multiCopyContainer" class="multi-copy__container" (click)="toggleSelected();" [class.multi-copy__container--selected] = showSelections>
    <div class="multi-copy__selections" [class.multi-copy__selections--selected] = showSelections [style.top]="showSelections?'0':dropdownTop">
      <div class="multi-copy__button multi-copy__button--default">
        <div class="multi-copy__selection">
          <ion-icon name="clipboard-outline"></ion-icon> Select multiple part rows to copy details <ion-icon
            name="chevron-down-outline" class="multi-copy__chevron-down"></ion-icon>
        </div>
      </div>
      <div class="multi-copy__button" 
      #tooltip ="matTooltip"
      [matTooltip]="copied === -2 ? 'Copied to clipboard':'Copy to clipboard'" 
      matTooltipPosition="right">
        <div class="multi-copy__selection" [cdkCopyToClipboard]="partNumberAll"   (click)="whichAmI(-2)">
          <ion-icon [class]="copied === -2?'multi-copy__checked':''" name="{{copied === -2?'checkmark-sharp':'clipboard-outline'}}"></ion-icon> {{copied === -2?'Copied':'Copy'}} all part numbers
          <ion-icon
            name="chevron-down-outline" class="multi-copy__chevron-down" *ngIf="copied && !showSelections"></ion-icon>
        </div>
      </div>
      <div class="multi-copy__button" 
      #tooltip ="matTooltip"
      [matTooltip]="copied === -1 ? 'Copied to clipboard':'Copy to clipboard'" 
      matTooltipPosition="right">
        <div class="multi-copy__selection" [cdkCopyToClipboard]="partNumberQuantityAll"   (click)="whichAmI(-1)">
          <ion-icon [class]="copied === -1?'multi-copy__checked':''" name="{{copied === -1?'checkmark-sharp':'clipboard-outline'}}"></ion-icon> {{copied === -1?'Copied':'Copy'}} all part numbers and quantities
          <ion-icon
            name="chevron-down-outline" class="multi-copy__chevron-down" *ngIf="copied && !showSelections"></ion-icon>
        </div>
      </div>
      <ng-container *ngIf="partsSelections.length > 1">
        <div  *ngIf="partsSelections.length > 1">
        <div class="multi-copy__button" *ngFor="let partsSelection of partsSelections, let i = index" 
        [cdkCopyToClipboard]="getPartNumber(partsSelection)" 
        (click)="whichAmI(i);"
        #tooltip ="matTooltip"
        [matTooltip]="copied === i ? 'Copied to clipboard':'Copy to clipboard'"
        matTooltipPosition="right">
          <div class="multi-copy__selection">
            <ion-icon [class]="copied === i?'multi-copy__checked':''" name="{{copied === i?'checkmark-sharp':'clipboard-outline'}}"></ion-icon> {{copied === i?'Copied':'Copy'}} part numbers
            <span *ngIf="i+1 !== partsSelections.length">&nbsp;{{((i+1)*20)-19}} to {{(i+1)*20}}</span> 
            <span *ngIf="i+1 === partsSelections.length">&nbsp;{{((i+1)*20)-19}} to {{parts.length}}</span> 
            <ion-icon
            name="chevron-down-outline" class="multi-copy__chevron-down" *ngIf="copied === i && !showSelections"></ion-icon>
          </div>
        </div>
      </div>
      <div *ngIf="partsNumberQuantitySelections.length > 1">
        <div class="multi-copy__button" *ngFor="let partsNumberQuantitySelection of partsNumberQuantitySelections, let i = index" 
        [cdkCopyToClipboard]="getPartNumberQuantity(partsNumberQuantitySelection)" 
        (click)="whichAmI(i+partsSelections.length);"
        #tooltip ="matTooltip"
        [matTooltip]="copied === i+partsSelections.length ? 'Copied to clipboard':'Copy to clipboard'" 
        matTooltipPosition="right">
          <div class="multi-copy__selection">
            <ion-icon [class]="copied === i+partsSelections.length?'multi-copy__checked':''" name="{{copied === i+partsSelections.length?'checkmark-sharp':'clipboard-outline'}}"></ion-icon> {{copied === i+partsSelections.length?'Copied':'Copy'}} part numbers and quantities
            <span *ngIf="i+1 !== partsNumberQuantitySelections.length">&nbsp;{{((i+1)*20)-19}} to {{(i+1)*20}}</span> 
            <span *ngIf="i+1 === partsNumberQuantitySelections.length">&nbsp;{{((i+1)*20)-19}} to {{parts.length}}</span> 
            <ion-icon
            name="chevron-down-outline" class="multi-copy__chevron-down" *ngIf="copied === i+partsSelections.length && !showSelections"></ion-icon>
          </div>
        </div>
      </div>
      </ng-container>
    </div>
  </div>
</div>