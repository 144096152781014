import { Order } from '../../../orders/models/order.model';
import { Quote, QuoteStatus } from '../../../quotes/models/quote.model';

export interface PagedResult {
  counts: any;
  lastPage: number;
  page: number;
  results: Order[] | Quote[];
  resultTotal: number;
}

export class QueryParams {
  filterBy: string;
  filterValue: string;
  maxPageResults: number;
  page: number;
  searchTerm: string;
  sortBy: string;
  sortDirection: string;

  constructor(data?: any, fetchingOrders = false) {
    const orderStatus = 'status';
    const quoteStatus = 'quoteStatus';
    data = data || {};
    this.filterBy = data.filterBy || (fetchingOrders ? orderStatus : quoteStatus);
    this.filterValue = data.filterValue || ([orderStatus, quoteStatus].includes(this.filterBy) ? QuoteStatus.NEW : '');
    this.maxPageResults = data.maxPageResults || 10;
    this.page = data.page || 1;
    this.searchTerm = data.searchTerm || '';
    this.sortBy = data.sortBy || 'createdAt';
    this.sortDirection = data.sortDirection || (this.filterValue === QuoteStatus.SUBMITTED ? 'desc' : 'asc');
  }
}
