import { Injectable } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrderSidebarService {

  openAppSideBar$ = new Subject();

  private orderSidebar: MatSidenav;
  private data: any;
  constructor() { }

  setSidenav(orderSidebar: MatSidenav) {
    this.orderSidebar = orderSidebar;
  }

  open(data: any) {
    this.data = data;
    this.openAppSideBar$.next(true);
  }

  close() {
   this.data=null;
   this.orderSidebar.close();
  }

  getValue() {
    return this.data;
  }

  }
