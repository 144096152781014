import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import { UrlService } from '../../core/services/url.service';
import { Role } from '../models/role.model';
import { Site } from '../models/site.model';
import { User } from '../models/user.model';
import { EmailType } from '../models/emailType.model';
import { Group } from '../models/group.model';
import { VirtualGroup, VirtualGroupDetail } from '../models/virtualGroup.model';

@Injectable({
  providedIn: 'root'
})
export class ManagementService {
  users: User[];
  emailTypes: EmailType[];
  sites: Site[];
  roles: Role[];
  virtualGroupsDetail: VirtualGroupDetail[];

  constructor(
    private readonly httpClient: HttpClient,
    private readonly urlService: UrlService
  ) {}

  getUsers(): Observable<User[]> {
    this.users = null;
    return this.httpClient.get(this.urlService.getUrl('USERS_LIST', {}))
      .pipe(
        map((response: any) => (this.users = response)),
      );
  }

  updateUser(user: Partial<User>): Observable<User> {
    return this.httpClient.put(this.urlService.getUrl('UPDATE_USER'), user)
      .pipe(
        map((response: User) => response),
      );
  }

  createUser(user: Partial<User>): Observable<any> {
    return this.httpClient.post(this.urlService.getUrl('CREATE_USER'), user)
      .pipe(
        map((response: any) => response)
        , catchError(err => of(console.log('Error occurred while creating user. Error : ', err)) as Observable<any>)
      );
  }

  getUser(id: string): Observable<User> {
    return this.httpClient.get(this.urlService.getUrl('USER_BY_ID', { ':id': id }), {})
      .pipe(
        map((response: User) => response)
      );
  }

  getSites(id: string): Observable<Site[]> {
    this.sites = null;
    return this.httpClient.get(this.urlService.getUrl('GET_SITES', { ':id': id }), {})
      .pipe(
        map((response: any) => (this.sites = response)),
      );
  }

  resetUserPassword(id: string): Observable<User> {
    return this.httpClient.post(this.urlService.getUrl('RESET_PASSWORD', { ':id': id }), {})
      .pipe(
        map((response: User) => response)
      );
  }

  changeUserStatus(id: string): Observable<User> {
    return this.httpClient.post(this.urlService.getUrl('CHANGE_USER_STATUS', { ':id': id }), {})
      .pipe(
        map((response: User) => response)
      );
  }

  checkEmailAvailability(user: Partial<User>): Observable<boolean> {
    return this.httpClient.post(this.urlService.getUrl('CHECK_EMAIL_AVAILABILITY'), user)
      .pipe(
        map((response: boolean) => response)
      );
  }

  checkGroupNameExists(group: Partial<Group>): Observable<boolean> {
    return this.httpClient.post(this.urlService.getUrl('CHECK_GROUPNAME_AVAIL'), group)
      .pipe(
        map((response: boolean) => response)
      );
  }

  createVirtualGroup(virtualGroup: VirtualGroup): Observable<VirtualGroup> {
    return this.httpClient.post(this.urlService.getUrl('CREATE_VIRTUALGROUP'), virtualGroup)
      .pipe(
        map((response: VirtualGroup) => response)
      );
  }

  getVirtualGroups(): Observable<VirtualGroupDetail[]> {
    this.virtualGroupsDetail = null;
    return this.httpClient.get(this.urlService.getUrl('VIRTUALGROUP_LIST', {}))
      .pipe(
        map((response: any) => (this.virtualGroupsDetail = response)),
      );
  }
}
