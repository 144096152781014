import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { Order } from '../../orders/models/order.model';
import { OrderActions } from './action-types';

export interface OrdersState extends EntityState<Order> {
  loading: false;
  selectedOrder: string;
  creatingPartSplit: boolean;
}

export const adapter = createEntityAdapter<Order>({
  selectId: (ins) => ins._id
});

export const initialOrdersState = adapter.getInitialState({
  loading: false,
  selectedOrder: null,
  creatingPartSplit: false
});

export const ordersReducer = createReducer(
  initialOrdersState,
  on(OrderActions.selectedOrderId, (state: OrdersState, { id }) => ({ ...state, selectedOrder: id })),
  on(OrderActions.loadOrder, (state: OrdersState) => ({ ...state, loading: true })),
  on(OrderActions.loadOrderSuccess, (state: OrdersState, { order }) => ({
    ...adapter.upsertOne({ ...order, loading: false } as Order, { ...state, loading: false })
  })),
  on(OrderActions.createPartSplit, (state: OrdersState) => ({ ...state, creatingPartSplit: true })),
  on(OrderActions.createPartSplitSuccess, (state: OrdersState) => ({ ...state, creatingPartSplit: false })),
  on(OrderActions.resetOrderState, () => initialOrdersState)
);

export const { selectAll } = adapter.getSelectors();
