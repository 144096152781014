import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Part } from '../../../parts/models/part.model';
import { PartsService } from '../../../parts/services/parts.service';
import { OrderStatus } from '../../../orders/models/order.model';

@Component({
  selector: 'app-parts-total',
  templateUrl: './total.component.html',
  styleUrls: ['./total.component.scss']
})
export class TotalComponent implements OnChanges {
  @Input() parts: Part[];
  @Input() orderStatus: string;
  @Input() recalculate: number;

  subtotal = 0;
  surcharge = 0;
  total = 0;
  vatCalculated = 0;
  vatPercent = '20%';
  vatRate = 0.2;

  constructor(private partsService: PartsService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.recalculate && changes.recalculate.currentValue) || (changes.parts && changes.parts.currentValue)) {
      this.calculateValue();
    }
  }

  private calculateValue(): void {
    const calculation = this.partsService.calculateTotals(this.parts, this.orderStatus === OrderStatus.CANCELLED);
    this.subtotal = calculation.subtotal;
    this.surcharge = calculation.surcharge;
    this.total = calculation.total;
    this.vatCalculated = calculation.vatCalculated;
  }
}
